const parseYoutubeUrl = (url) => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)(?<id>[^#&?]*)(((\?|&)t=)(?<time>[0-9]+))?/gm
  const match = regExp.exec(url)

  const emptyResult = {
    id: null,
    time: null,
  }

  if (!match) {
    return emptyResult
  }

  const {
    id,
    time,
  } = match.groups

  return id.length === 11 ? {
    id,
    time: Number(time ?? 0),
  } : emptyResult
}

export const state = {
  video: null,

  videoId: null,
  // videoTitle: null,
  // videoThumbnail: null,
  // videoDuration: 0,

  videoStartTime: 0,

  videoRequirements: {
    minimalViewsAmount: 0,
  },

  widgetMediaEnabled: false,
  widgetMediaPrices: {},

  videoError: null,
}

export const getters = {
  videoSelected: state => !!state.video,
  videoDuration: state => {
    if (!state.video) {
      return 0
    }

    const {
      h,
      m,
      s,
    } = state.video.duration

    return h * 60 * 60 + m * 60 + s
  },

  mediaCost: (state, getters, rootState) => state.widgetMediaPrices[rootState['currencies'].currentCurrency],

  widgetMediaEnabled: state => state.widgetMediaEnabled,
}

export const mutations = {
  SET_VIDEO_DATA(state, data) {
    state.video = data
    state.videoId = data ? data.id : null
  },

  SET_VIDEO_START_TIME(state, time) {
    state.videoStartTime = time
  },

  SET_WIDGET_SETTINGS(state, {
    widgetMediaEnabled,
    widgetMediaPrices,
    minimalViewsAmount,
  }) {
    state.videoRequirements = {
      minimalViewsAmount,
    }
    state.widgetMediaEnabled = widgetMediaEnabled
    state.widgetMediaPrices = widgetMediaPrices
  },

  SET_VIDEO_ERROR(state, error) {
    state.videoError = error
  },
}

export const actions = {
  setup({ commit }, { media }) {
    const {
      video_min_likes_perc = 0,
      video_min_views = 0,
    } = media.additional_data ?? {}

    commit('SET_WIDGET_SETTINGS', {
      widgetMediaEnabled: !!media.is_enabled,
      widgetMediaPrices: media.min_amounts,
      minimalViewsAmount: video_min_views,
    })
  },

  removeVideo({ commit }) {
    return commit('SET_VIDEO_DATA', null)
  },

  fetchVideoData({
    commit,
    getters,
  }, url) {
    commit('SET_VIDEO_DATA', null)
    commit('SET_VIDEO_ERROR', null)

    const errors = {
      default: 'При обработке видео возникла ошибка',
      wrongUrl: 'Указана некорректная ссылка',
      notEmbeddable: 'У этого видео отключено встраивание',
      wrongStats: 'Статистика этого видео не прошла проверку',
    }

    const {
      id,
      time,
    } = parseYoutubeUrl(url)

    if (!id) {
      return commit('SET_VIDEO_ERROR', errors.wrongUrl)
    }

    return api.get(`/services/youtube/getvideoinfo/${id}`)
      .then(({ data }) => {
        if (data.id) {
          const {
            id,
            status,
            statistics,
            snippet,
            contentDetails,
          } = data

          if (!status || !status.embeddable) {
            return commit('SET_VIDEO_ERROR', errors.notEmbeddable)
          }

          const {
            minimalViewsAmount,
          } = state.videoRequirements

          if (minimalViewsAmount) {
            if (!statistics) {
              return commit('SET_VIDEO_ERROR', errors.wrongStats)
            } else {
              const {
                viewCount,
              } = {
                viewCount: Number(statistics.viewCount),
              }

              if (minimalViewsAmount && viewCount < minimalViewsAmount) {
                return commit('SET_VIDEO_ERROR', errors.wrongStats)
              }
            }
          }

          const durationRegExp = /PT((?<h>[0-9]{1,2})H)?((?<m>[0-9]{1,2})M)?((?<s>[0-9]{1,2})S)?/gi
          const durationMatch = durationRegExp.exec(contentDetails.duration)

          const duration = {
            h: Number(durationMatch.groups.h ?? 0),
            m: Number(durationMatch.groups.m ?? 0),
            s: Number(durationMatch.groups.s ?? 0),
          }

          commit('SET_VIDEO_DATA', {
            id,
            title: snippet.title,
            thumbnail: snippet.thumbnails.url,
            duration,
          })

          commit('SET_VIDEO_START_TIME', time >= getters.videoDuration ? getters.videoDuration - 1 : time)
        } else {
          return commit('SET_VIDEO_ERROR', errors.default)
        }
      })
      .catch(e => {
        return commit('SET_VIDEO_ERROR', errors.default)
      })
  },
}
