export const declOfNum = (n, titles) => titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]

export const discharge = (amount) => amount.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')

export const openWindow = ({ url, name, params }) => {
  const prepare = (value) => value === true ? 'yes' : (value === false ? 'no' : value)
  window.open(url, name, Object.keys(params).reduce((acc, key) => {
    acc.push([key, prepare(params[key])].join('='))
    return acc
  }, []).join(','))
}

export const getStorageItem = key => JSON.parse(window.localStorage.getItem(key))
export const setStorageItem = (key, value) => window.localStorage.setItem(key, JSON.stringify(value))

export const leadingZero = num => num > 9 ? num : `0${num > 0 ? num : 0}`

export const getInRange = (value, range) => {
  return value < range[0] ? range[0] : (value > range[1] ? range[1] : value)
}

export const roundAmount = amount => {
  const finalAmount = _.round(amount, 2)

  return finalAmount % 1 ? finalAmount.toFixed(2) : finalAmount
}
