<style scoped lang="scss">
  .payment-region-select {
    display: flex;
    align-items: center;

    .select-icon {
      font-size: 15px;
    }

    .select-label {
      margin-left: 10px;
      color: $placeholder-color;
      pointer-events: none;
    }

    .dropdown-select {
      margin-left: 5px;
    }
  }
</style>

<template>
  <div class="payment-region-select form-control">
    <icon name="globe" class="select-icon" theme gradient/>
    <div class="select-label">{{ $t('payment.form.region') }}</div>
    <dropdown-select
      v-model="paymentRegion"
      :options="paymentRegionOptions"/>
  </div>
</template>

<script>

export default {
  name: 'PaymentRegionSelect',
  computed: {
    ...mapFields('payment', ['paymentRegion']),
    ...mapGetters('payment', ['paymentRegionsList']),

    paymentRegionOptions() {
      return this.paymentRegionsList.map(region => ({
        label: region.name,
        value: region.code,
      }))
    },
  },
}
</script>
