<style scoped lang="scss">
  .stickers-editor {
    display: flex;
    user-select: none;

    .editor-content {
      width: 100%;
      flex: 0 1 auto;
      padding: 20px;

      .editor-canvas {
        width: 720px;
        height: 405px;
        background-image: appAsset('/images/stickers-editor-bg.jpeg');
        background-size: contain;
        position: relative;
        overflow: hidden;

        .canvas-sticker {
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 1px;

          img {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            transform-origin: left top;
            object-fit: contain;
          }
        }

        .sticker-controls {
          position: absolute;
          z-index: 1000;
          border: 1px dotted rgba(white, .5);
          cursor: move;
          transform-origin: left top;

          .sticker-remove-button {
            display: flex;
            @include position(absolute, 6px, 6px);
            color: rgba(white, .7);
            font-size: 25px;
            line-height: 25px;
            width: 25px;
            height: 25px;
            cursor: pointer;
          }

          .rotation-control {
            @include position(absolute, -7px, auto, auto, 50%);
            margin-left: -7px;
            width: 14px;
            height: 14px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            cursor: crosshair;

            &:after {
              content: '';
              width: 4px;
              height: 4px;
              background-color: #c5c5c5;
              border-radius: 100%;
            }
          }

          .size-control {
            position: absolute;
            border-radius: 2px;
            background-color: white;
            width: 8px;
            height: 8px;

            &.bottom.left, &.top.right {
              cursor: nesw-resize;
            }

            &.bottom.right, &.top.left {
              cursor: nwse-resize;
            }

            &.bottom {
              bottom: -4px;
            }

            &.top {
              top: -4px;
            }

            &.left {
              left: -4px;
            }

            &.right {
              right: -4px;
            }
          }
        }

        .sticker-trash-zone {
          position: absolute;
          z-index: 1100;
          bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background: rgba(white, .5);
          border-radius: 8px;
          border: 1px solid rgba(white, .5);
          left: 50%;
          margin-left: -20px;
          color: rgba(white, .8);
          font-size: 20px;
          opacity: .2;
          transition: .3s;

          &:hover {
            transform: scale(1.5);
            opacity: 1;
            background: rgba(red, .8);
          }

          &.disabled {
            pointer-events: none;
            opacity: 0;
          }
        }
      }

      .editor-controls {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .button + .button {
          margin-left: 10px;
        }

        .base-switch {
          margin-left: auto;
        }
      }
    }

    .stickers-list {
      width: 100%;
      flex: 0 1 auto;
      background-color: #f2f2f2;
      border-radius: 5px;
    }
  }
</style>

<template>
  <div class="stickers-editor">
    <div class="editor-content">
      <div class="editor-canvas" ref="canvas" @mousedown.self="blurControls">
        <div
          v-for="sticker in canvasStickers"
          :key="sticker.id"
          class="canvas-sticker"
          :class="sticker.classes"
          :style="sticker.styles"
          @mouseenter="onStickerMouseEntered(sticker.id)"
          ref="sticker">
          <img :src="sticker.image" :style="sticker.imgStyles"/>
        </div>
        <div
          v-if="!!focusedStickerId"
          class="sticker-controls"
          :style="stickerControls.styles"
          @mousedown.self="onStickerDragStart">
          <div class="rotation-control" @mousedown.stop="onRotatorDragStart"></div>
          <div
            v-for="(control, index) in sizeControls"
            :key="index"
            class="size-control"
            :class="control"
            @mousedown.stop="onResizerDragStart"></div>
        </div>
        <div
          class="sticker-trash-zone"
          :class="{disabled: !mouseBusy}"
          @mouseenter="trashZoneHovered = true"
          @mouseleave="trashZoneHovered = false">
          <icon name="trash"/>
        </div>
      </div>
      <div class="editor-controls">
        <btn variant="primary" @click="save">{{ $t('global.common.save') }}</btn>
        <btn @click="close" class="action">{{ $t('global.common.cancel') }}</btn>
      </div>
    </div>
    <sticker-list @add="addSticker"/>
  </div>
</template>

<script>

import StickerList from './StickersList'

const getInRange = (value, range) => {
  return value < range[0] ? range[0] : (value > range[1] ? range[1] : value)
}

export default {
  name: 'StickerEditor',
  components: { StickerList },
  data() {
    return {
      stickerIdIncrement: 0,
      overlayStickers: {},
      focusedStickerId: null,

      mouseBusy: false,
      sizeControls: [
        {
          bottom: true,
          right: true,
        },
        {
          bottom: true,
          left: true,
        },
        {
          top: true,
          right: true,
        },
        {
          top: true,
          left: true,
        },
      ],

      canvasSize: {
        width: 0,
        height: 0,
      },

      trashZoneHovered: false,
    }
  },
  created() {
    this.overlayStickers = this.addedStickersList
      .slice()
      .reduce((acc, sticker, index) => {
        acc[index + 1] = {
          ...sticker,
          id: index + 1,
        }
        return acc
      }, {})

    this.stickerIdIncrement = this.addedStickersList.length + 1
  },
  mounted() {
    const canvasRect = this.$refs.canvas.getBoundingClientRect()

    this.canvasSize = {
      width: canvasRect.width,
      height: canvasRect.height,
    }
  },
  computed: {
    ...mapGetters('widgets/stickers', [
      'getSticker',
      'addedStickersList',
    ]),

    canvasStickers() {
      const canvasWidth = this.canvasSize.width

      return Object.values(this.overlayStickers)
        .map(sticker => {
          const focused = this.focusedStickerId === sticker.id

          return {
            ...sticker,
            focused,
            classes: {
              focused,
              blurred: !focused && !!this.focusedStickerId,
            },
            styles: {
              // transform: `translate(${sticker.left}px, ${sticker.top}px)`,
              top: `${sticker.top}%`,
              left: `${sticker.left}%`,
              zIndex: sticker.id,
            },
            imgStyles: {
              transform: `rotate(${sticker.angle}deg) translate(-50%, -50%)`,
              width: `${sticker.size * canvasWidth}px`,
              height: `${sticker.size * canvasWidth}px`,
            },
          }
        })
    },

    stickerControls() {
      const sticker = this.overlayStickers[this.focusedStickerId]

      const canvasWidth = this.canvasSize.width

      return {
        classes: {},
        styles: {
          transform: `rotate(${sticker.angle}deg) translate(-50%, -50%)`,
          width: `${sticker.size * canvasWidth}px`,
          height: `${sticker.size * canvasWidth}px`,
          top: `${sticker.top}%`,
          left: `${sticker.left}%`,
        },
      }
    },
  },
  methods: {
    ...mapMutations('widgets/stickers', {
      setAddedStickers: 'SET_ADDED_STICKERS',
    }),

    close() {
      this.$emit('close')
    },

    save() {
      this.setAddedStickers(Object.values(this.overlayStickers))
      this.close()
    },

    onStickerMouseEntered(id) {
      if (!this.mouseBusy) {
        this.focusSticker(id)
      }
    },

    focusSticker(id) {
      this.focusedStickerId = id
    },

    blurControls() {
      this.focusSticker(null)
    },

    onDrag(dragCallback, dropCallback = null) {
      this.mouseBusy = true

      document.onmouseup = () => {
        if (dropCallback) {
          dropCallback()
        }

        document.onmouseup = null
        document.onmousemove = null
        this.mouseBusy = false
      }

      if (dragCallback) {
        document.onmousemove = dragCallback
      }
    },

    addSticker(stickerId) {
      const id = ++this.stickerIdIncrement
      const { image } = this.getSticker(stickerId)

      this.$set(this.overlayStickers, id, {
        id,
        stickerId,
        image,
        top: _.random(0, 100),
        left: _.random(0, 100),
        size: _.random(5, 15) / 100,
        angle: _.random(-45, 45),
      })

      this.focusSticker(id)
    },

    removeFocusedSticker() {
      this.$delete(this.overlayStickers, this.focusedStickerId)
      this.blurControls()
    },

    onStickerDragStart(event) {
      const focusedSticker = this.getFocusedSticker()
      const canvasRect = this.$refs.canvas.getBoundingClientRect()
      const stickerRect = this.getFocusedStickerRect()
      const mouseOffset = {
        x: stickerRect.left - event.x,
        y: stickerRect.top - event.y,
      }

      this.onDrag(({
        x,
        y,
      }) => {
        const deltaY = y - canvasRect.top + mouseOffset.y
        const top = getInRange(deltaY / this.canvasSize.height * 100, [0, 100])
        const deltaX = x - canvasRect.left + mouseOffset.x
        const left = getInRange(deltaX / this.canvasSize.width * 100, [0, 100])

        this.$set(focusedSticker, 'top', top)
        this.$set(focusedSticker, 'left', left)
      }, () => {
        if (this.trashZoneHovered) {
          this.removeFocusedSticker()
        }
      })
    },

    onRotatorDragStart(event) {
      const focusedSticker = this.getFocusedSticker()
      const startAngle = focusedSticker.angle
      const mouseOffsetX = event.x

      this.onDrag(({ x }) => {
        const angle = -(mouseOffsetX - x) % 360 + startAngle

        this.$set(focusedSticker, 'angle', angle)
      })
    },

    onResizerDragStart(event) {
      const stickerRect = this.getFocusedStickerRect()
      const focusedSticker = this.getFocusedSticker()
      const startSizePercent = focusedSticker.size

      const [startA, startB] = [stickerRect.top - event.y, stickerRect.left - event.x]
      const startDistance = Math.sqrt(startA * startA + startB * startB)

      this.onDrag(({
        x,
        y,
      }) => {
        const [a, b] = [stickerRect.top - y, stickerRect.left - x]
        const distance = Math.sqrt(a * a + b * b)

        this.$set(focusedSticker, 'size', getInRange(startSizePercent * (distance / startDistance), [
          .05,
          .15,
        ]))
      })
    },

    getFocusedSticker() {
      return this.overlayStickers[this.focusedStickerId]
    },

    getFocusedStickerRect() {
      const refId = this.canvasStickers.findIndex(sticker => sticker.id === this.focusedStickerId)
      return this.$refs.sticker[refId] ? this.$refs.sticker[refId].getBoundingClientRect() : {}
    },
  },
}
</script>
