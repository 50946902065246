<style scoped lang="scss">
  .service-page {
  }
</style>

<template>
  <sub-page-layout class="service-page">
    <service-tabs v-if="serviceTabsVisible"/>
    <router-view class="sub-page" :key="$route.path"/>
  </sub-page-layout>
</template>

<script>

import ServiceTabs from '@components/CreatorPage/ServiceTabs.vue'
import SubPageLayout from '@layouts/SubPageLayout.vue'

export default {
  name: 'ServicePage',
  components: {
    SubPageLayout,
    ServiceTabs,
  },
  computed: {
    ...mapGetters('partners', ['getPartnersList']),

    serviceTabsVisible() {
      return !!this.getPartnersList.length
    },
  },
}
</script>
