<style scoped lang="scss">
  .widgets {
    .widgets-wrapper {
      margin-top: -20px;
      overflow: hidden;
      @include margin-x(-20px);

      .widgets-list {
        display: flex;
        overflow: auto;
        padding-top: 20px;
        padding-bottom: 40px;
        margin-bottom: -20px;

        .widget-plate {
          flex: 0 0 auto;
          width: 160px;
        }

        .widget-plate + .widget-plate {
          margin-left: 10px;
        }

        &:before, &:after {
          content: '';
          flex: 0 0 auto;
          width: 20px;
        }
      }
    }

    .widgets-list {
      .widget-plate {
        height: 120px;
        padding: 15px;
        border-radius: var(--control-border-radius);
        box-shadow: 0 5px 20px 0 rgba(black, 0.1);
        border: solid 1px $line-color;
        background-color: white;
        cursor: pointer;
        user-select: none;
        transition: $control-transition;

        &:hover {
          border-color: $line-hover-color;
        }

        &.active {
          box-shadow: none;
          border-color: var(--theme-color);
          background-color: rgba(var(--theme-color-rgb), .05);
        }

        .plate-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 22px;

          .widget-icon {
            font-size: 20px;
          }

          .selected-tag {
            display: flex;
            align-items: center;
            padding: 2px 8px;
            font-size: 12px;
            line-height: 1.33;
            font-weight: bold;
            text-transform: uppercase;
            color: white;
            @include theme-gradient;
            border-radius: calc(var(--control-border-radius) - 2px);

            .icon {
              margin-right: 8px;
            }
          }
        }

        .plate-name {
          margin-top: 15px;
          font-size: 14px;
          font-weight: 600;
          color: $black;
          line-height: 1;
          text-transform: uppercase;
        }

        .plate-description {
          margin-top: 8px;
          font-size: 12px;
          line-height: 1.25;
          color: $warm-grey-two;
        }
      }
    }

    .widgets-content {
      margin-top: 10px;
    }
  }
</style>

<template>
  <div class="widgets" ref="widgets">
    <div class="widgets-wrapper">
      <div class="widgets-list hiddenscroll">
        <div
          v-if="stickersWidget.isAvailable"
          class="widget-plate stickers"
          :class="stickersWidget.classes"
          @click="setActiveWidget('stickers')">
          <div class="plate-header">
            <icon class="widget-icon" name="sticker" theme gradient/>
            <div v-if="stickersWidget.selected" class="selected-tag">
              {{ stickersWidget.text }}
            </div>
          </div>
          <div class="plate-name">{{ $t('widgets.stickers.title') }}</div>
          <div class="plate-description">
            {{ stickersWidget.description }}
            <div v-if="stickersWidget.amountText" class="text-black fw-600">
              {{ stickersWidget.amountText }}
            </div>
          </div>
        </div>

        <div
          v-if="mediaWidget.isAvailable"
          class="widget-plate media"
          :class="mediaWidget.classes"
          @click="setActiveWidget('media')">
          <div class="plate-header">
            <icon class="widget-icon" name="play" theme gradient/>
            <div v-if="mediaWidget.selected" class="selected-tag">
              <icon name="check"/>
              {{ $t('widgets.selected') }}
            </div>
          </div>
          <div class="plate-name">{{ $t('widgets.media.title') }}</div>
          <div class="plate-description">{{ $t('widgets.media.description') }}</div>
        </div>

        <div
          v-if="pollWidget.isAvailable"
          class="widget-plate poll"
          :class="pollWidget.classes"
          @click="setActiveWidget('poll')">
          <div class="plate-header">
            <icon class="widget-icon" name="poll" theme gradient/>
            <div v-if="pollWidget.selected" class="selected-tag">
              <icon name="check"/>
              {{ $t('widgets.selected') }}
            </div>
          </div>
          <div class="plate-name">{{ $t('widgets.poll.title') }}</div>
          <div class="plate-description">{{ $t('widgets.poll.vote') }}</div>
        </div>

        <div
          v-if="goalWidget.isAvailable"
          class="widget-plate goal"
          :class="goalWidget.classes"
          @click="setActiveWidget('goal')">
          <div class="plate-header">
            <icon class="widget-icon" name="money-bag" theme gradient/>
            <div v-if="goalWidget.selected" class="selected-tag">
              <icon name="check"/>
              {{ $t('widgets.selected') }}
            </div>
          </div>
          <div class="plate-name">{{ $t('widgets.goals.title') }}</div>
          <div class="plate-description">{{ $t('widgets.goals.choose') }}</div>
        </div>

        <div
          v-if="partyWidget.isAvailable"
          class="widget-plate party"
          :class="partyWidget.classes"
          @click="setActiveWidget('party')">
          <div class="plate-header">
            <icon class="widget-icon" name="party" theme gradient/>
            <div v-if="partyWidget.selected" class="selected-tag">
              {{ partyWidget.text }}
            </div>
          </div>
          <div class="plate-name">{{ partyWidget.name }}</div>
          <div class="plate-description">
            {{ partyWidget.description }}
            <div v-if="partyWidget.amountText" class="text-black fw-600">
              {{ partyWidget.amountText }}
            </div>
          </div>
        </div>

        <div
          v-if="bftWidget.isAvailable"
          class="widget-plate"
          :class="bftWidget.classes"
          @click="setActiveWidget('bft')">
          <div class="plate-header">
            <icon class="widget-icon" name="joystick" theme gradient/>
            <div v-if="bftWidget.selected" class="selected-tag">
              <icon name="check"/>
              {{ $t('widgets.selected') }}
            </div>
          </div>
          <div class="plate-name">{{ $t('widgets.bft.title') }}</div>
          <div class="plate-description">{{ $t('widgets.bft.manageGameProcess') }}</div>
        </div>
      </div>
    </div>

    <div class="widgets-content">
      <transition name="fade-up">
        <widget-goal v-show="goalWidget.isActive"/>
      </transition>
      <transition name="fade-up">
        <widget-poll v-show="pollWidget.isActive"/>
      </transition>
      <transition name="fade-up">
        <widget-media v-show="mediaWidget.isActive"/>
      </transition>
      <transition name="fade-up">
        <widget-stickers v-show="stickersWidget.isActive"/>
      </transition>
      <transition name="fade-up">
        <widget-party v-show="partyWidget.isActive"/>
      </transition>
      <transition name="fade-up">
        <widget-bft v-show="bftWidget.isActive"/>
      </transition>
    </div>
  </div>
</template>

<script>

import WidgetGoal from './Widgets/WidgetGoal'
import WidgetPoll from './Widgets/WidgetPoll'
import WidgetMedia from './Widgets/WidgetMedia'
import WidgetStickers from './Widgets/WidgetStickers'
import WidgetParty from './Widgets/WidgetParty'
import WidgetBft from './Widgets/WidgetBft'

export default {
  name: 'Widgets',
  components: {
    WidgetStickers,
    WidgetMedia,
    WidgetPoll,
    WidgetGoal,
    WidgetParty,
    WidgetBft,
  },
  data() {
    return {
      activeWidget: null,
    }
  },
  computed: {
    ...mapState('widgets/widgets', ['widgetsAvailable']),

    ...mapState('widgets/bft', {
      bftGame: 'game',
      bftIsActive: 'bftIsActive',
    }),
    ...mapGetters('creator', ['getCreatorName']),

    ...mapGetters('widgets/poll', ['pollOptionSelected']),
    ...mapGetters('widgets/goals', ['goalSelected']),
    ...mapGetters('widgets/stickers', ['addedStickersList']),
    ...mapGetters('widgets/media', ['videoSelected']),
    ...mapGetters('widgets/bft', {
      bftActionsList: 'getSelectedActionsList',
    }),
    ...mapState('widgets/party', ['partyProviderName']),
    ...mapGetters('widgets/party', ['getSelectedActionsList']),

    ...mapGetters('donation', [
      'minimalAmounts',
    ]),
    ...mapGetters('currencies', [
      'getAmountWithCurrency',
    ]),

    stickersWidget() {
      const isActive = this.activeWidget === 'stickers'
      const stickersCount = this.addedStickersList.length

      return {
        isAvailable: this.widgetsAvailable.stickers && this.$appIsLG,
        isActive,
        selected: !!stickersCount,
        text: this.$tc('widgets.stickers.stickersCount_plural', stickersCount, { count: stickersCount }),
        description: stickersCount
          ? this.$t('widgets.stickers.stickersTotal')
          : this.$t('widgets.stickers.addStickersToStream'),
        amountText: stickersCount ? this.getAmountWithCurrency(this.minimalAmounts.stickers) : null,
        classes: {
          active: isActive,
        },
      }
    },

    mediaWidget() {
      const isActive = this.activeWidget === 'media'

      return {
        isAvailable: this.widgetsAvailable.media,
        isActive,
        selected: this.videoSelected,
        classes: {
          active: isActive,
        },
      }
    },

    pollWidget() {
      const isActive = this.activeWidget === 'poll'

      return {
        isAvailable: this.widgetsAvailable.poll,
        isActive,
        selected: this.pollOptionSelected,
        classes: {
          active: isActive,
        },
      }
    },

    goalWidget() {
      const isActive = this.activeWidget === 'goal'

      return {
        isAvailable: this.widgetsAvailable.goals,
        isActive,
        selected: this.goalSelected,
        classes: {
          active: isActive,
        },
      }
    },

    bftWidget() {
      const isActive = this.activeWidget === 'bft'

      return {
        isAvailable: this.widgetsAvailable.bft && this.bftIsActive,
        isActive,
        selected: !!this.bftActionsList.length,
        classes: {
          active: isActive,
        },
      }
    },

    partyWidget() {
      const isActive = this.activeWidget === 'party'
      const partyCount = this.getSelectedActionsList.length

      return {
        isAvailable: this.widgetsAvailable.party,
        isActive,
        selected: !!partyCount,
        name: this.partyProviderName,
        text: this.$tc('widgets.party.actionCount_plural', partyCount, { count: partyCount }),
        description: partyCount
          ? this.$t('widgets.party.partyTotal')
          : this.$t('widgets.party.addActions'),
        amountText: partyCount ? this.getAmountWithCurrency(this.minimalAmounts.party) : null,
        classes: {
          active: isActive,
        },
      }
    },
  },
  methods: {
    setActiveWidget(widget, scrolling = false) {
      if (widget === 'bft') {
        this.$tmr.goal('chaosTricks_opened', {
          username: this.getCreatorName,
          game_name: this.bftGame ? this.bftGame.title : null,
        })
      }

      this.activeWidget = this.activeWidget === widget ? null : widget

      if (scrolling && this.$refs.widgets) {
        setTimeout(() => {
          this.$refs.widgets.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      }
    },
  },
  watch: {
    activeWidget(widget) {
      this.$tmr.goal('input_addinsTab_changed', {
        tab: {
          media: 'video',
          stickers: 'sticker',
          goal: 'goal',
          bft: 'bft',
        }[widget],
      })
    },
  },

  mounted() {
    const activeWidget = this.$route.hash.slice(1)

    if (activeWidget) {
      this.setActiveWidget(activeWidget, true)
    }
  },
}
</script>
