<style scoped lang="scss">
  .block-reason {
    @include padding-y(40px);
    @include padding-x(20px);
    display: flex;
    flex-direction: column;
    align-items: center;

    .reason-image {
      img {
        max-width: 200px;
        max-height: 200px;
      }
    }

    .reason-text {
      margin-top: 30px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
    }
  }
</style>

<template>
  <div class="block-reason">
    <div class="reason-image">
      <img :src="$asAppAsset('/images/da-wtf.png')"/>
    </div>
    <div class="reason-text">
      {{ $t(`creator.blockReason.${blockReason}`) }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'BlockReason',
  computed: {
    ...mapState('creator', ['blockReason']),
  },
}
</script>
