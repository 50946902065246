<style scoped lang="scss">
  .action {
    display: flex;

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 14px;
      height: 46px;
      border: 1px solid #E2E2E2;
      border-radius: 8px 0 0 8px;
      width: 100%;
      font-size: 13px;
      cursor: pointer;
    }

    &-name {
      color: #191919;
      font-weight: 400;
      line-height: 16px;
    }

    &-cost {
      color: #151515;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
    }

    &-count {
      &-select {
        border: 1px solid #E2E2E2;
        border-left: none;
        border-radius: 0 8px 8px 0;
        height: 46px;
        width: 64px;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
      }
    }

    &.active {
      .action-info,
      .action-count-select {
        border-color: var(--theme-color);
        background: rgba(var(--theme-color-rgb), .05);
      }

      // .action-count-select {
      //   color: #F57D07;
      // }
    }
  }
</style>

<template>
  <div v-if="action" class="action" :class="{ active: selectedCount > 0 }">
    <div class="action-info" @click="increaseCount">
      <span class="action-name">{{ action.title }}</span>
      <span class="action-cost">{{ actionAmountText }}</span>
    </div>
    <div class="action-count">
      <dropdown-select
        v-model="selectedCount"
        :options="options"
        class="action-count-select"
        @click.native="onDropdownClick"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ActionItem',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('creator', ['getCreatorName']),

    ...mapState('widgets/bft', ['game', 'selectedActions']),
    ...mapGetters('widgets/bft', ['getAction', 'getCategory']),

    ...mapGetters('currencies', ['getAmountWithCurrency']),

    options() {
      return [...Array(11).keys()].map(i => ({
        label: `${i}`,
        value: i,
      }))
    },

    action() {
      return this.getAction(this.id)
    },

    category() {
      return this.getCategory(this.action.categoryId)
    },

    selectedCount: {
      get() {
        return this.selectedActions[this.id] ?? 0
      },
      set(count) {
        this.selectActionCount(({ id: this.id, count }))
      },
    },

    actionAmountText() {
      return this.getAmountWithCurrency(this.action.amount)
    },
  },
  methods: {
    ...mapMutations('widgets/bft', {
      selectActionCount: 'SET_SELECTED_ACTION_COUNT',
    }),

    increaseCount() {
      this.selectedCount = this.selectedCount >= 10 ? 10 : this.selectedCount + 1

      this.$tmr.goal('chaosTricks_action_clicked', {
        username: this.getCreatorName,
        game_name: this.game ? this.game.title : null,
      })
    },

    onDropdownClick() {
      this.$tmr.goal('chaosTricks_action_selector_clicked', {
        username: this.getCreatorName,
        game_name: this.game.title,
        action_category_name: this.category.title,
        action_name: this.action.title,
      })
    },
  },
}
</script>
