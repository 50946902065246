<style scoped lang="scss">
  .sub-page-layout {
    .viewer-box {
      padding: 15px 30px;
      background: rgba(238, 238, 238, 0.40);

      @include media(max, sm) {
        padding: 15px;
      }
    }
  }
</style>

<template>
  <div class="sub-page-layout">
    <div class="content-block">
      <viewer-box v-if="viewer"/>
      <div class="content-block-body">
        <slot/>
      </div>
      <content-block-footer/>
    </div>
  </div>
</template>

<script>

import ContentBlockFooter from '@components/CreatorPage/ContentBlockFooter'
import ViewerBox from '@components/CreatorPage/ViewerBox/ViewerBox'

export default {
  name: 'SubPageLayout',
  components: {
    ViewerBox,
    ContentBlockFooter,
  },
  props: {
    viewer: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
