<style scoped lang="scss">
  .creator-info {
    margin-top: 66px;
    border-radius: var(--block-border-radius);
    padding-left: 15px;
    padding-right: 12px;
    background-color: white;

    &.bg {
      margin-top: 0;
      border-radius: 0 0 var(--block-border-radius) var(--block-border-radius);
      background-color: white;
    }

    @include media(max, sm) {
      border-radius: 0;
      margin-top: 46px;

      &.bg {
        border-radius: 0;
      }
    }

    .channel-title {
      display: flex;
      align-items: baseline;

      @include media(max, sm) {
        display: block;
      }
    }

    .current-game-label {
      color: #838383;
      font-weight: 500;
      font-size: 13px;
    }


    .creator-channel {
      position: relative;

      .channel-avatar {
        position: absolute;
        top: -66px;
        left: 0;
        padding: 5px;
        border-radius: var(--block-border-radius);
        background-color: white;

        img {
          width: 100px;
          height: 100px;
          border-radius: 5px;
        }

        @include media(max, sm) {
          top: -36px;

          img {
            width: 60px;
            height: 60px;
          }
        }
      }

      .channel-status {
        position: absolute;
        top: -36px;
        left: 120px;
        @include padding-x(7px);
        @include padding-y(4px);
        border-radius: 5px;
        background-color: white;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.33;
        text-transform: uppercase;

        @include media(max, sm) {
          //top: -30px;
          left: 82px;
        }

        &:before {
          content: '';
          display: inline-block;
          margin-right: 5px;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: $gray;
        }

        &.online:before {
          background-color: $pumpkin-orange;
          animation: blink;
          animation-iteration-count: infinite;
          animation-duration: 1s;
        }
      }

      .channel-info {
        display: flex;
        flex-wrap: wrap;
        @include padding-y(8px);

        @include media(max, sm) {
          padding-top: 0;
        }

        .channel-name {
          height: 44px;
          margin-left: 120px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: 500;

          @include media(max, sm) {
            height: 40px;
          }

          @include media(max, sm) {
            font-size: 20px;
            margin-left: 80px;
          }
        }

        .channel-badges {
          margin-right: 10px;
          display: flex;
          align-items: center;
        }

        .creator-badges {
          margin-right: auto;
          padding-right: 10px;
          @include padding-y(4px);
        }

        .creator-links {
          height: 44px;

          @include media(max, sm) {
            height: 40px;
          }
        }
      }
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>

<template>
  <div class="creator-info" :class="infoClasses">
    <div class="creator-channel">
      <div v-if="avatar" class="channel-avatar">
        <img v-if="displayAvatar" :src="avatar" ref="avatar"/>
        <img v-else src="/img/index/streams/avatar_dummy.jpg" />
      </div>
      <div class="channel-status" :class="channelStatus.classes">
        {{ channelStatus.statusText }}
      </div>
      <div class="channel-info">
        <!-- <div class="channel-name">{{ getCreatorName }}</div> -->
        <div class="channel-title">
          <div class="channel-name">{{ getCreatorName }}</div>
          <div class="current-game-label" v-if="bftGame !== null && !!bftGame.title">Играет в {{ bftGame.title }}</div>
        </div>

        <div class="channel-badges">
          <creator-badge
            v-if="creatorInfo.verified"
            type="verified"
            :text="$t('creator.verifiedPage')"/>
        </div>
        <creator-badges/>
        <creator-links/>
      </div>
    </div>
  </div>
</template>

<script>

import CreatorBadges from './CreatorBadges'
import CreatorBadge from './CreatorBadge'
import CreatorLinks from './CreatorLinks'

export default {
  name: 'CreatorInfo',
  components: {
    CreatorLinks,
    CreatorBadge,
    CreatorBadges,
  },
  computed: {
    ...mapState('creator', ['creatorInfo', 'awards', 'creatorPageSettings']),
    ...mapGetters('creator', ['getCreatorName']),
    ...mapState('widgets/bft', {
      bftGame: 'game',
      bftIsActive: 'bftIsActive',
    }),

    isOnline() {
      return this.creatorInfo.online
    },

    channelStatus() {
      return {
        statusText: this.isOnline ? this.$t('creator.status.onAir') : this.$t('creator.status.offline'),
        classes: {
          online: this.isOnline,
        },
      }
    },

    avatar() {
      return this.creatorInfo.avatar
    },

    displayBackground() {
      return !!this.creatorPageSettings.headerBackgroundImage
    },

    displayAvatar() {
      return !!this.creatorPageSettings.displayAvatar
    },

    infoClasses() {
      return {
        bg: this.displayBackground,
        avatar: !!this.avatar,
      }
    },
  },
}
</script>
