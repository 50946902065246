import storage from '@utils/storage'
import { roundAmount } from '@utils/utils'

const STORAGE_DONATION_AMOUNTS = 'da.donation.donationAmounts'

export const state = {
  fieldsValidated: false,

  validationModalOpened: false,

  donationAmount: 0,
  donationIsAnonymous: false,
  donationMessageType: 'text',
  donationMessageText: '',

  commissionCovered: false,

  donationSettings: {
    minimalAmount: {},
    fastAmounts: {},
  },
}

export const getters = {
  getField,

  getDonationSettings: (state, getters, rootState) => {
    const currency = rootState['currencies'].currentCurrency

    return {
      minimalAmount: state.donationSettings.minimalAmount[currency],
      fastAmounts: state.donationSettings.fastAmounts[currency],
    }
  },

  minimalAmounts: (state, getters, rootState, rootGetters) => {
    const currencyCode = rootState['currencies'].currentCurrency

    const minimalDonationAmount = state.donationSettings.minimalAmount[currencyCode]

    const amounts = {
      donation: minimalDonationAmount,
      // Message
      speechSynthesis: rootGetters['message/message/speechSynthesisCost'],
      audioMessage: rootGetters['message/message/audioMessageCost'],
      // Widgets
      media: roundAmount(rootGetters['widgets/media/mediaCost']),
      stickers: roundAmount(rootGetters['widgets/stickers/stickersCost']),
      party: roundAmount(rootGetters['widgets/party/selectedActionsCost']),
      bft: roundAmount(rootGetters['widgets/bft/selectedActionsCost'] + minimalDonationAmount),
    }

    return Object.keys(amounts)
      .reduce((acc, key) => {
        acc[key] = Number(amounts[key] ?? 0)

        return acc
      }, {})
  },

  missingAmounts: (state, getters) => {
    return Object.keys(getters['minimalAmounts'])
      .reduce((acc, key) => {
        const minAmount = getters['minimalAmounts'][key]

        let missingAmount = roundAmount(minAmount - state.donationAmount)

        acc[key] = missingAmount > 0 ? missingAmount : 0

        return acc
      }, {})
  },

  missingAmountsActual: (state, getters, rootState, rootGetters) => {
    const amounts = [
      'donation',
    ]

    if (rootState['message'].message.donationMessageType === 'audio' && rootGetters['message/recorder/recordDuration']) {
      amounts.push('audioMessage')
    }

    if (rootGetters['widgets/stickers/addedStickersList'].length) {
      amounts.push('stickers')
    }

    if (rootGetters['widgets/party/getSelectedActionsList'].length) {
      amounts.push('party')
    }

    if (rootState['widgets'].media.video) {
      amounts.push('media')
    }

    if (rootGetters['widgets/bft/getSelectedActionsList'].length) {
      amounts.push('bft')
    }

    return amounts.reduce((acc, key) => {
      acc[key] = getters['missingAmounts'][key]

      return acc
    }, {})
  },
}

export const mutations = {
  updateField,

  SET_FIELDS_VALIDATED(state, fieldsValidated) {
    state.fieldsValidated = fieldsValidated
  },

  ADD_DONATION_AMOUNT(state, amount) {
    state.donationAmount = roundAmount(state.donationAmount + amount)
  },

  SET_DONATION_AMOUNT(state, amount) {
    state.donationAmount = amount
  },

  SET_DONATION_SETTINGS(state, { minimalAmount }) {
    state.donationSettings = {
      minimalAmount,
      fastAmounts: Object.keys(minimalAmount).reduce((acc, currency) => {
        acc[currency] = [
          minimalAmount[currency],
          ...Array.from([2, 5, 10]).map(k => roundAmount(minimalAmount[currency] * k)),
        ]

        return acc
      }, {}),
    }
  },
}

export const actions = {
  initDonationAmount({ commit, getters, rootGetters }) {
    const creatorId = rootGetters['creator/getCreatorId']
    const donationAmounts = storage.getItem(STORAGE_DONATION_AMOUNTS) ?? {}
    const currentCreatorAmount = donationAmounts[creatorId] ?? 0
    const minDonationAmount = getters.minimalAmounts.donation

    // const smartDonationAmount = _.round(minDonationAmount + (500 - minDonationAmount) * .1)
    // DA-2862
    // const finalDonationAmount =  _.max([currentCreatorAmount, smartDonationAmount, minDonationAmount])
    const finalDonationAmount = _.max([currentCreatorAmount, minDonationAmount])

    commit('SET_DONATION_AMOUNT', _.round(Number(finalDonationAmount), 2))
  },

  saveDonationAmount({ state, rootGetters }) {
    const creatorId = rootGetters['creator/getCreatorId']
    const donationAmounts = storage.getItem(STORAGE_DONATION_AMOUNTS) ?? {}

    donationAmounts[creatorId] = state.donationAmount

    storage.setItem(STORAGE_DONATION_AMOUNTS, donationAmounts)
  },
}
