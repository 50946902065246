<style scoped lang="scss">
  .creator-links {
    display: flex;
    align-items: center;

    .social-link + .social-link {
      margin-left: 10px;
    }

    .social-link {
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: var(--control-border-radius);
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        color: white;
        font-size: 20px;
      }

      @include media(max, sm) {
        width: 30px;
        height: 30px;
        border-radius: 6px;

        .icon {
          color: white;
          font-size: 16px;
        }
      }
    }
  }
</style>

<template>
  <div class="creator-links">
    <a
      v-for="(link, index) in socialLinks"
      :key="index"
      :href="link.url"
      target="_blank"
      class="social-link"
      :class="link.classes">
      <icon :name="link.icon"/>
    </a>
  </div>
</template>

<script>

export default {
  name: 'CreatorLinks',
  computed: {
    ...mapState('creator', ['creatorInfo', 'creatorAwards', 'creatorLinks']),

    socialLinks() {
      return this.creatorLinks.map(link => ({
        ...link,
        url: link.profile_url,
        icon: `social-${link.platform}`,
        classes: [`social-${link.platform}`],
      }))
    },
  },
}
</script>
