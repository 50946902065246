<style lang="scss" scoped>
  .voice-recorder {
    display: flex;

    .recorder-control {
      flex: 0 0 auto;
    }

    .recorder-content {
      width: 100%;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .recorder-controls {
        .recording-progress {
          display: flex;
          align-items: center;
        }

        .recorder-player {
          border-radius: 24px;
          border: 1px solid $white-three;
          display: flex;
          align-items: center;
          padding: 4px 4px 4px 20px;

          audio {
            display: none;
          }

          .audio-track {
            flex: 0 1 auto;
            width: 100%;
          }

          .audio-time {
            width: 90px;
            flex: 0 0 auto;
            margin-left: 10px;
            font-size: 13px;
            line-height: 1.15;
            color: $black-five;
            text-align: center;
          }

          .audio-delete-button {
            flex: 0 0 auto;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            @include theme-gradient;
            cursor: pointer;
            color: var(--theme-text-color);
            font-size: 18px;
          }

          audio {
            //display: none;
          }
        }
      }

      .recorder-message {
        width: 380px;
        color: $black-four;
        line-height: 1.43;

        p + p {
          margin-top: 5px;
        }
      }
    }
  }
</style>

<template>
  <div class="voice-recorder">
    <div class="recorder-control">
      <recorder-button
        :playing-audio="playingAudio"
        @player-play="playerPlay"
        @player-pause="playerPause"/>
    </div>
    <div class="recorder-content">
      <div v-if="permission.granted" class="recorder-controls">
        <record-progress v-if="recorderActive"/>
        <div v-else-if="playerActive" class="recorder-player">
          <base-range
            class="audio-track"
            :value="audioTime"
            @input="onTimeChange"
            :max="audioLength"
            :step="1"/>
          <div class="audio-time">{{ audioTimeText }}</div>
          <div class="audio-delete-button" @click="resetAudio">
            <icon name="trash"/>
          </div>
          <audio
            ref="audioPlayer"
            @play="onPlayerPlay"
            @pause="onPlayerPause"
            @timeupdate="onPlayerTimeUpdate"
            controls>
            <source :src="audioUrl" type="audio/wav">
          </audio>
        </div>
      </div>
      <div v-else class="recorder-message">
        <template v-if="error.noBrowserSupport">
          <p>
            {{ $t('message.recorder.browserUnsupported') }}
          </p>
        </template>
        <template v-else-if="error.deviceNotFound">
          <p>
            {{ $t('message.recorder.deviceNotFound') }}
            <br/>{{ $t('message.recorder.checkMic') }}
          </p>
          <p><span
            class="text-link dashed"
            @click="renewPermission">{{ $t('message.recorder.doneRefresh') }}</span>
          </p>
        </template>
        <template v-else>
          <p>
            {{ $t('message.recorder.permission') }}
            <span
              v-if="!permission.requesting"
              class="text-link dashed"
              @click="renewPermission">{{ $t('message.recorder.permissionRefresh') }}</span>
          </p>
<!--          <p><span class="text-link dashed">{{ $t('message.recorder.permissionHowTo') }}</span></p>-->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RecordProgress from './RecordProgress'
import RecorderButton from './RecorderButton'
import { leadingZero } from '@utils/utils'

export default {
  name: 'VoiceRecorder',
  components: {
    RecorderButton,
    RecordProgress,
  },
  data() {
    return {
      playingAudio: false,
      audioTime: 0,
      audioLength: 0,
    }
  },
  watch: {
    playerActive: {
      handler(value) {
        setTimeout(() => {
          if (value && this.$refs.audioPlayer) {
            this.audioLength = Math.round(this.$refs.audioPlayer.duration)
          }
        }, 100)
      },
      immediate: true,
    },

    donationMessageType(value) {
      if (value && !this.permission.granted) {
        this.requestPermission()
      }
    },

    permission(permission) {
      if (permission.granted) {
        this.initRecorder()
      }
    },
  },
  computed: {
    ...mapGetters('message/recorder', [
      'audioUrl',
      'recorderActive',
      'playerActive',
      'loading',
      'locked',
    ]),
    ...mapState('message/recorder', [
      'permission',
      'error',
      'recorderState',
    ]),
    ...mapState('donation', [
      'donationMessageType',
    ]),

    audioTimeText() {
      const minutesLeft = Math.floor(this.audioTime / 60)
      const secondsLeft = this.audioTime - (minutesLeft * 60)
      const minutesMax = Math.floor(this.audioLength / 60)
      const secondsMax = this.audioLength - (minutesMax * 60)

      return [
        [minutesLeft, secondsLeft],
        [minutesMax, secondsMax],
      ].map(row => row.map(leadingZero).join(':')).join(' / ')
    },
  },
  methods: {
    ...mapMutations('message/recorder', {
      resetAudio: 'RESET_AUDIO_DATA',
    }),
    ...mapActions('message/recorder', [
      'recorderStart',
      'renewPermission',
      'requestPermission',
      'checkPermission',
      'initRecorder',
    ]),

    onTimeChange(time) {
      if (this.audioTime !== time) {
        this.$refs.audioPlayer.currentTime = time
      }
    },
    onPlayerTimeUpdate() {
      this.audioTime = Math.round(this.$refs.audioPlayer.currentTime)
    },
    onPlayerPlay() {
      this.playingAudio = true
      console.info('[Player] Playing recorded voice...')
    },
    onPlayerPause() {
      this.playingAudio = false
      console.info('[Player] Player paused')
    },
    playerPlay() {
      this.$refs.audioPlayer.play()
    },
    playerPause() {
      this.$refs.audioPlayer.pause()
    },
  },
  created() {
    this.checkPermission()
  },
}
</script>
