<style scoped lang="scss">
  .content-block-footer {
    display: flex;
    align-items: center;
    @include padding-x(30px);
    @include padding-y(12px);

    .info-box ::v-deep {
      .icon {
        font-size: 22px;
      }
    }

    .locale-select {
      display: flex;
      align-items: center;

      .icon {
        color: $gray;
      }

      .language-picker {
        margin-left: 5px;
      }
    }

    @include media(max, sm) {
      @include padding-x(15px);
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>

<template>
  <div class="content-block-footer">
    <div v-if="localeSelect" class="locale-select">
      <icon name="globe"/>
      <dropdown-select
        v-model="localeModel"
        :options="localeOptions"
        class="language-picker"/>
    </div>
  </div>
</template>

<script>

import { i18n, saveLocale, localesDataList } from '@src/localization/config'

export default {
  name: 'ContentBlockFooter',
  props: {
    localeSelect: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    localeOptions() {
      return localesDataList
        .map(locale => ({
          value: locale.code,
          label: locale.name,
        }))
    },

    localeModel: {
      get() {
        return i18n.locale
      },
      set(value) {
        this.$tmr.goal('language_changed')

        saveLocale(value)
        window.location.reload()
      },
    },
  },
}
</script>
