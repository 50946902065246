<style scoped lang="scss">
  .content-block-controls {
    padding: 20px;
  }
</style>

<template>
  <div class="content-block-controls">
    <btn
      icon-left="angle-left"
      size="sm"
      @click="goToCreatorPage">
      {{ $t('global.app.toMainPage') }}
    </btn>
  </div>
</template>

<script>

export default {
  name: 'ContentBlockControls',
  methods: {
    goToCreatorPage() {
      this.$emit('onReturn')

      this.$router.replace({
        name: 'creator-page',
      })
    },
  },
}
</script>
