import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import getDay from 'date-fns/getDay'
import getDaysInMonth from 'date-fns/getDaysInMonth'

import ru from 'date-fns/locale/ru'

const parseDate = (_date) => ({
  day: _date.getDate(),
  month: _date.getMonth(),
  year: _date.getFullYear(),

  hour: _date.getHours(),
  minute: _date.getMinutes(),
})

const parseTs = (_ts) => parseDate(fromUnixTime(_ts))

export default {
  getDaysInMonth,
  getWeekDay: (_date) => getDay(_date),
  timestamp: (_date = null) => +(_date ? new Date(_date) : Date.now()) / 1000,
  parseTs,
  getTimeLeft: (tsStart, tsEnd) => {
    const diff = {
      sec: Math.floor(tsEnd - tsStart),
    }
    const seconds = diff.sec % 60

    diff.min = (diff.sec - seconds) / 60
    const minutes = diff.min % 60

    diff.hours = (diff.min - minutes) / 60
    const hours = diff.hours % 24

    const days = (diff.hours - hours) / 24

    return {
      seconds,
      minutes,
      hours,
      days,
    }
  },
  // Formatting
  format: (_date, _format) => format(_date, _format, { locale: ru }),
  formatTs: (_ts, _format) => format(fromUnixTime(_ts), _format, { locale: ru }),
}
