<style scoped lang="scss">
  .page-description {
    @include padding-x(20px);
    @include padding-y(15px);
    position: relative;

    @include media(max, sm) {
      border-radius: 0;
      @include padding-y(10px);
      @include padding-x(15px);
    }

    .spoiler-button-wrapper {
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      bottom: 15px;
    }

    .description-wrapper {
      overflow: hidden;
      position: relative;
      transition: height .5s;

      &.closed {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 40px;
          background-image: linear-gradient(to bottom, rgba(white, 0), rgba(white, .8), rgba(white, 1));
          pointer-events: none;
        }
      }
    }

    .description {
      ::v-deep {
        * + p, * + pre, * + table {
          margin: 1em 0 .5em;
        }

        p {
          white-space: pre-line;
        }

        * + h1, * + h2, * + h3, * + h4, * + h5, * + h6 {
          margin: 1em 0 .5em;
        }

        h1, h2, h3, h4, h5, h6 {
          font-weight: bold;
        }

        h1 {
          font-size: 28px;
        }

        h2 {
          font-size: 22px;
        }

        h3 {
          font-size: 18px;
        }

        h4 {
          font-size: 16px;
        }

        h5 {
          font-size: 14px;
        }

        h6 {
          font-size: 12px;
        }

        blockquote {
          padding-left: 10px;
          border-left: 3px solid $line-color;
          font-style: italic;
          color: rgba(black, .8);
        }

        img {
          max-width: 100%;
        }

        pre {
          padding: 12px;
          background: $background-color;
          border-radius: 3px;
        }

        ul, ol {
          padding: 0;
          margin-left: 1.75em;
        }

        ul {
          li::marker {
            color: $line-color;
          }
        }

        table {
          display: block;
          width: max-content;
          max-width: 100%;
          border-spacing: 0;
          border-collapse: collapse;

          tr {
            border-top: 1px solid $line-color;
          }

          td, th {
            padding: 6px 13px;
            border: 1px solid $line-color;
          }
        }
      }

    }
  }
</style>

<template>
  <div v-if="displayDescription" class="page-description content-block">
    <div
      ref="description-wrapper"
      class="description-wrapper"
      :class="descriptionClasses"
      :style="descriptionWrapperStyles">
      <div ref="description" class="description" v-html="renderedDescription"/>
    </div>
    <div v-if="descriptionSpoiler" class="spoiler-button-wrapper">
      <btn
        :icon-left="spoilerButtonIcon"
        size="sm"
        @click="toggleFullDescription">
        <template v-if="this.descriptionOpened">{{$t('global.app.hideDescription')}}</template>
        <template v-else>{{$t('global.app.showDescription')}}</template>
      </btn>
    </div>
  </div>
</template>

<script>

import Markdown from 'markdown-it'

const md = new Markdown

export default {
  name: 'PageDescription',
  data() {
    return {
      descriptionHeight: 0,
      descriptionMaxHeight: 250,
      descriptionOpened: false,
    }
  },
  computed: {
    ...mapState('creator', ['creatorDescription']),

    displayDescription() {
      return !!this.creatorDescription && !!this.creatorDescription.length
    },

    renderedDescription() {
      return md.render(this.creatorDescription)
    },

    descriptionSpoiler() {
      return this.descriptionHeight > this.descriptionMaxHeight
    },

    descriptionClasses() {
      return {
        closed: this.descriptionSpoiler && !this.descriptionOpened,
      }
    },

    descriptionWrapperStyles() {
      const styles = {}

      if (this.descriptionSpoiler) {
        styles.height = this.descriptionOpened ? `${this.descriptionHeight + 50}px` : '150px'
      }

      return styles
    },

    spoilerButtonIcon() {
      return this.descriptionOpened ? 'angle-up' : 'angle-down'
    },
  },
  methods: {
    toggleFullDescription() {
      this.descriptionOpened = !this.descriptionOpened
    },

    checkDescriptionHeight() {
      if (this.$refs.description) {
        const { height } = this.$refs.description.getBoundingClientRect()

        this.descriptionHeight = height
      }
    },
  },
  mounted() {
    this.checkDescriptionHeight()

    setInterval(this.checkDescriptionHeight, 1000)
  },
}
</script>
