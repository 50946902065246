<style lang="scss">
  @import '~@assets/scss/donate';

  #donation-app {
    height: 100%;

    .system-svg {
      height: 0;
      width: 0;
      position: fixed;
    }

    .cookies-warning {
      @include position(fixed, auto, 0, 0, 0);
      z-index: 1000;
      min-height: 50px;
      border-top: 1px solid $line-color;
      background-color: rgba(white, .8);
      backdrop-filter: blur(20px);
      display: flex;
      justify-content: center;
      align-items: center;

      .cookies-warning-content {
        flex-grow: 1;
        max-width: 1030px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        @include media(max, sm) {
          flex-direction: column;
          align-items: flex-start;
        }

        .cookies-warning-text {
          margin-right: 20px;
        }

        .cookies-warning-controls {
          margin-left: auto;

          @include media(max, sm) {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }
    }
  }
</style>

<template>
  <div id="donation-app">
    <router-view/>
    <svg class="system-svg">
      <defs>
        <linearGradient id="_system_gradient_1" x1="0" x2="0.9055797876726389" y1="0" y2="1.079227965339569">
          <stop offset="0%" stop-color="#F57507"/>
          <stop offset="100%" stop-color="#F59C07"/>
        </linearGradient>
        <linearGradient id="_theme_gradient" x1="0" x2="0.9055797876726389" y1="0" y2="1.079227965339569">
          <stop offset="0%" :stop-color="gradient.from"/>
          <stop offset="100%" :stop-color="gradient.to"/>
        </linearGradient>
      </defs>
      <rect fill="url(#_system_gradient_a)" width="100%" height="100%"/>
    </svg>
    <div v-if="cookiesAcceptable" class="cookies-warning">
      <div class="cookies-warning-content">
        <div class="cookies-warning-text">
          {{ $t('global.app.acceptCookiesText') }}
          <a href="/cookie-policy" target="_blank">{{
              $t('global.app.acceptCookiesLink')
            }}</a>
        </div>
        <div class="cookies-warning-controls">
          <btn
            variant="primary"
            size="sm"
            @click="acceptCookies">{{ $t('global.app.acceptCookiesButton') }}
          </btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  page() {
    return {
      title: this.getCreatorName ? `${this.getCreatorName} - DonationAlerts` : 'DonationAlerts',
    }
  },
  computed: {
    ...mapState('application', ['themeColors', 'cookiesAcceptable']),
    ...mapGetters('creator', ['getCreatorName']),

    gradient() {
      return {
        from: this.themeColors['--theme-gradient-color-from'],
        to: this.themeColors['--theme-gradient-color-to'],
      }
    },
  },
  methods: {
    ...mapActions('application', ['acceptCookies']),
  },
}
</script>
