<style lang="scss" scoped>
  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    letter-spacing: -0.5px;
    font-size: $control-font-size;
    line-height: $control-line-height;
    @include padding-x($control-padding-x);
    @include padding-y($control-padding-y);
    border: none;
    box-shadow: none;
    min-width: 0;
    transition: $control-transition;
    border-radius: var(--control-border-radius);

    .button-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      flex-shrink: 0;

      &-left.mg {
        margin-right: 12px;
      }

      &-right.mg {
        margin-left: 12px;
      }
    }

    &, &:hover, &:active, &:focus {
      text-decoration: none;
      outline: 0 none;
    }

    &-default {
      background-color: $control-background-color;
      font-weight: 500;
      border: $control-border-width solid $control-border-color;
      color: $control-color;

      &:hover {
        border-color: $control-hover-border-color;
      }

      .icon {
        fill: url(#_theme_gradient);
      }

      &.action {
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    &-primary {
      @include theme-gradient;
      color: var(--theme-text-color);
      font-weight: bold;
      text-transform: uppercase;

      .icon {
        font-size: 20px;
      }
    }

    &-apple-pay {
      background-color: black;
      color: white;
      font-weight: bold;
      text-transform: none;

      .icon {
        font-size: 21px;
        margin-right: 5px;
      }
    }

    &-secondary {

    }

    &-primary-action {
      @include padding-x(20px);
      @include padding-y($control-padding-y + $control-border-width);
      background-image: linear-gradient(255deg, rgba($tangerine, 1), rgba($orange, 1));
      color: white;
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
        background-image: none;
        background-color: white;
        color: $tangerine;
      }

      &:disabled {
        background: $army-green;
        color: #5c4426;
      }
    }

    &-secondary-action {
      @include padding-x(20px);
      @include padding-y($control-padding-y + $control-border-width);
      background-color: $black-three;
      color: white;
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
        background-color: $greyish-brown;
      }

      &:disabled {
        color: $black;
      }
    }

    &-link {
      color: var(--theme-color);
      background-color: transparent;
      transition: none;
      padding: 0;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        border-color: transparent;
        box-shadow: none;
      }

      .icon {
        color: rgba(white, 0.6);
      }

      &:hover {
        color: var(--theme-color);
      }
    }

    &:disabled {
      opacity: .7;

      &, &:hover, &:focus {
        cursor: not-allowed;
      }
    }

    &.button-sm {
      height: 31px;
      font-size: 14px;
      line-height: 1;
      @include padding-x(12px);

      .icon {
        &-left.mg {
          margin-right: 6px;
        }

        &-right.mg {
          margin-left: 6px;
        }
      }
    }

    &.button-md {
      height: 45px;
      font-size: 14px;
      line-height: 1;
      @include padding-x(20px);
    }

    &.button-lg {
      height: 60px;
      line-height: 1;
      @include padding-x(30px);
    }

    .loader {
      position: absolute;
      font-size: 20px;
    }

    &-loading {
      &, &:disabled, .icon {

      }

      .button-text, .icon {
        opacity: .5 !important;
      }
    }
  }
</style>

<template>
  <component
    :is="component"
    :to="to"
    :disabled="buttonDisabled"
    class="button"
    :class="classes"
    :type="buttonType"
    v-on="listeners">
    <transition name="fade">
      <loader v-if="isLoading"/>
    </transition>
    <icon
      v-if="iconLeft"
      class="icon-left"
      :class="iconClasses"
      :name="iconLeft"/>
    <div v-if="this.$slots.default" class="button-text">
      <slot/>
    </div>
    <icon
      v-if="iconRight"
      class="icon-right"
      :class="iconClasses"
      :name="iconRight"/>
  </component>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'md',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    component() {
      return this.$attrs.href ? 'a' : (this.to ? 'router-link' : 'button')
    },

    buttonType() {
      return this.to || this.$attrs.href ? null : this.type
    },

    buttonDisabled() {
      return this.disabled || this.isLoading
    },

    classes() {
      return {
        disabled: this.buttonDisabled,
        [`button-${this.variant}`]: !!this.variant,
        [`button-${this.size}`]: !!this.size,
        'button-block': this.block,
        'button-loading': this.isLoading,
      }
    },

    iconClasses() {
      return {
        mg: !!this.$slots.default,
      }
    },

    listeners() {
      return this.disabled || this.isLoading ? undefined : this.$listeners
    },
  },
}
</script>
