<style scoped lang="scss">
  .action {
    display: flex;

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 14px;
      height: 46px;
      border: 1px solid #E2E2E2;
      border-radius: 8px;
      width: 100%;
      font-size: 13px;
      cursor: pointer;
    }

    &-name {
      color: #191919;
      font-weight: 400;
      line-height: 16px;
    }

    &-cost {
      color: #151515;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
    }

    &-count {
      margin-left: 10px;

      &-select {
        border: 1px solid #E2E2E2;
        border-radius: 8px;
        height: 46px;
        width: 64px;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
      }
    }

    &.active {
      .action-info,
      .action-count-select {
        border-color: var(--theme-color);
        background: rgba(var(--theme-color-rgb), .05);
      }

      // .action-count-select {
      //   color: #F57D07;
      // }
    }
  }
</style>

<template>
  <div v-if="action" class="action" :class="{ active: selectedCount > 0 }">
    <div class="action-info" @click="increaseCount">
      <span class="action-name">{{ action.name }}</span>
      <span class="action-cost">{{ actionAmountText }}</span>
    </div>
    <div class="action-count">
      <dropdown-select
        v-model="selectedCount"
        :options="options"
        class="action-count-select"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ActionSelect',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('widgets/party', ['selectedActions']),
    ...mapGetters('widgets/party', ['getAction']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    action() {
      return this.getAction(this.id)
    },

    options() {
      return [...Array(this.action.maxCount + 1).keys()].map(i => ({
        label: `${i}`,
        value: i,
      }))
    },

    selectedCount: {
      get() {
        return this.selectedActions[this.id] ?? 0
      },
      set(count) {
        this.selectActionCount(({ id: this.id, count }))
      },
    },

    actionAmountText() {
      return this.getAmountWithCurrency(this.action.amount)
    },
  },
  methods: {
    ...mapMutations('widgets/party', {
      selectActionCount: 'SET_SELECTED_ACTION_COUNT',
    }),

    increaseCount() {
      this.selectedCount = this.selectedCount >= this.action.maxCount
        ? this.action.maxCount
        : this.selectedCount + 1
    },
  },
}
</script>
