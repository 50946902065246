<style lang="scss" scoped>
  .viewer-box {
    .viewer-alias-controls {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 8px;

      .viewer-alias-input {
        flex-grow: 1;
      }

      .viewer-alias-cancel-button {
        flex-shrink: 0;
      }
    }

    .viewer-info-wrapper {
      display: flex;
      align-items: center;

      .viewer-info {
        flex-shrink: 1;
        min-width: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        @include padding-y(5px);

        .viewer-avatar {
          flex-shrink: 0;
          width: 35px;
          height: 35px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .viewer-alias {
          margin-left: 10px;
          color: #191919;
          border-bottom: 1px dashed currentColor;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: normal;
          flex-shrink: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .alias-edit-button {
          margin-left: 6px;
          font-size: 14px;
          color: #838383;
        }
      }

      .account-controls {
        margin-left: auto;
        display: flex;
        align-items: center;

        .control-link {
          white-space: nowrap;
        }

        .control-link + .control-link {
          margin-left: 30px;
        }
      }
    }
  }
</style>

<template>
  <div class="viewer-box" v-click-outside="hideAliasInput">
    <div v-if="aliasInputMode" class="viewer-alias-controls">
      <viewer-alias-input/>
      <btn
        @click="cancelAliasInput"
        class="action viewer-alias-cancel-button">{{ $t('global.common.cancel') }}
      </btn>
    </div>
    <div v-else="!aliasInputMode" class="viewer-info-wrapper">
      <div class="viewer-info" @click="toggleAliasInputMode">
        <div class="viewer-avatar">
          <img :src="avatar" ref="avatar"/>
        </div>
        <div class="viewer-alias">
          {{ alias }}
        </div>
        <div class="alias-edit-button">
          <icon name="pencil"/>
        </div>
      </div>
      <div class="account-controls">
        <template v-if="authorized">
          <a
            href="/dashboard/sent-donations"
            target="_blank"
            class="control-link text-link helper">{{ $t('viewer.profile.donationHistory') }}</a>
          <div
            class="control-link text-link helper"
            @click="_logout">{{ $t('viewer.profile.logout') }}
          </div>
        </template>
        <template v-else>
          <div
            v-tooltip="$t('viewer.profile.authorization.helperTooltip')"
            class="control-link text-link dashed"
            @click="authorize">{{ $t('viewer.profile.login') }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import ViewerAliasInput from './ViewerAliasInput'

export default {
  name: 'ViewerBox',
  components: { ViewerAliasInput },
  data() {
    return {
      aliasInputMode: false,
      lastAlias: null,
      lastAnonymously: false,
    }
  },
  computed: {
    ...mapState('viewer', ['viewerInfo']),
    ...mapFields('viewer', ['viewerAlias']),
    ...mapFields('donation', ['donationIsAnonymous']),

    authorized() {
      return !!this.viewerInfo
    },

    alias() {
      return this.donationIsAnonymous ? this.$t('viewer.alias.anonymouslyLong') : this.viewerAlias
    },

    avatar() {
      return _.get(this.viewerInfo, 'avatar', this.$asAppAsset('/images/no-avatar.svg'))
    },
  },
  methods: {
    ...mapActions('viewer', ['showLoginModal', 'logout']),

    toggleAliasInputMode() {
      if (!this.aliasInputMode) {
        this.lastAlias = this.viewerAlias
        this.lastAnonymously = this.donationIsAnonymous
      }

      this.aliasInputMode = !this.aliasInputMode
    },

    hideAliasInput() {
      if (!this.viewerAlias || this.viewerAlias.length === 0) {
        this.donationIsAnonymous = true
      }

      this.aliasInputMode = false
    },

    cancelAliasInput() {
      this.hideAliasInput()

      this.viewerAlias = this.lastAlias
      this.donationIsAnonymous = this.lastAnonymously
    },

    authorize() {
      this.showLoginModal()
    },

    _logout() {
      this.$ga.action({
        category: 'Auth',
        action: 'btn_soc_exit',
      })

      this.logout()
    },
  },
  mounted() {
    if (!this.viewerAlias || this.viewerAlias.length === 0) {
      this.donationIsAnonymous = true
    }
  }
}
</script>
