<style scoped lang="scss">
  .terms-and-policy {
    font-size: 13px;
    line-height: 1.54;
    color: $warm-grey;

    .policy-link {
      color: $greyish-brown;

      &, &:hover {
        text-decoration: none;
      }
    }
  }
</style>

<script>

export default {
  name: 'TermsAndPolicy',
  render: function (createElement) {
    const regex = /(<terms>.*<\/terms>)|(<policy>.*<\/policy>)/gmu

    const elements = {
      terms: (contents) => createElement(
        'a',
        {
          class: 'policy-link',
          domProps: {
            href: '/terms-of-service',
            target: '_blank',
          },
        },
        contents
      ),
      policy: (contents) => createElement(
        'a',
        {
          class: 'policy-link',
          domProps: {
            href: '/privacy-policy',
            target: '_blank',
          },
        },
        contents
      ),
    }

    const contentString = this.$t('global.login.termsAndPolicy').replaceAll(regex, '%$&%')

    const finalContents = contentString.split('%')

    finalContents.forEach((part, index) => {
      Object.keys(elements).forEach(key => {
        if(part.includes(`<${key}>`) && part.includes(`</${key}>`)){
          const elementContents = part
            .replace(`<${key}>`, '')
            .replace(`</${key}>`, '')

          finalContents[index] = elements[key](elementContents)
        }
      })
    })

    return createElement(
      'div', {
        class: 'terms-and-policy',
      },
      finalContents,
    )
  },
}
</script>
