export const state = {
  pollId: null,
  pollTitle: null,
  pollOptions: {},

  selectedPollOptionId: null,

  customPollOptionAllowed: false,
  customPollOption: null,

  progressByAmount: false,

  currencyVoteAmounts: {},
}

export const getters = {
  getField,

  getPoll: state => ({
    pollId: state.pollId,
    pollTitle: state.pollTitle,
    pollOptions: state.pollOptions,
    customPollOptionAllowed: state.customPollOptionAllowed,
  }),

  pollOptionSelected: state => !!state.selectedPollOptionId || !!state.customPollOption,

  widgetPollEnabled: state => !!state.pollId,
}

export const mutations = {
  updateField,

  SET_POLL(state, {
    pollId,
    pollTitle,
    progressByAmount,
    customPollOptionAllowed,
  }) {
    state.pollId = pollId
    state.pollTitle = pollTitle
    state.progressByAmount = progressByAmount
    state.customPollOptionAllowed = customPollOptionAllowed
  },

  SET_CURRENCY_VOTE_AMOUNTS(state, amounts) {
    state.currencyVoteAmounts = amounts
  },

  SET_POLL_OPTIONS(state, options) {
    state.pollOptions = _.keyBy(options, 'id')
  },

  SET_SELECTED_POLL_OPTION_ID(state, id) {
    state.selectedPollOptionId = id
  },
}

export const actions = {
  setup({ commit }, { poll }) {
    commit('SET_CURRENCY_VOTE_AMOUNTS', poll.per_amount_votes)

    commit('SET_POLL', {
      pollId: poll.id,
      pollTitle: poll.title,
      progressByAmount: poll.type === 'sum',
      customPollOptionAllowed: !!poll.allow_user_options,
    })

    const options = poll.options.map(option => ({
      id: option.id,
      title: option.title,
      votes: Number(option.amount_value),
      progressPercentage: Number(option.amount_percent),
    }))

    commit('SET_POLL_OPTIONS', options)

    // DA-2501
    // if (options.length) {
    //   commit('SET_SELECTED_POLL_OPTION_ID', _.sample(_.map(options, 'id')))
    // }
  },
}
