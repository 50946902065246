import { render, staticRenderFns } from "./PageDescription.vue?vue&type=template&id=10cc0bee&scoped=true"
import script from "./PageDescription.vue?vue&type=script&lang=js"
export * from "./PageDescription.vue?vue&type=script&lang=js"
import style0 from "./PageDescription.vue?vue&type=style&index=0&id=10cc0bee&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10cc0bee",
  null
  
)

export default component.exports