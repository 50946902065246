<style scoped lang="scss">
  .validation-form {
    width: 620px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include padding-y(30px);
    text-align: center;

    @include media(max, md) {
      width: 100%;
      height: auto;
      @include padding-x(30px);
    }

    .validation-icon {
      .icon {
        width: 64px;
        height: 64px;
      }
    }

    .validation-errors {
      margin-top: 20px;

      .validation-error {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .validation-controls {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .continue-link {
        margin-top: 20px;
        font-size: 15px;
        font-weight: 500;
        color: var(--theme-color);
        cursor: pointer;
      }
    }
  }
</style>

<template>
  <modal v-model="validationModalOpened">
    <div slot="body" class="validation-form">
      <div class="validation-icon">
        <icon name="exclamation-circle" theme gradient :inline="false"/>
      </div>
      <div class="validation-errors">
        <div v-if="validationErrors.donation" class="validation-error">
          {{ $t('donation.validation.amountErrors.donation', { amount: validationErrors.donation }) }}
        </div>
        <div v-if="validationErrors.audioMessage" class="validation-error">
          {{ $t('donation.validation.amountErrors.audioMessage', { amount: validationErrors.audioMessage }) }}
        </div>
        <div v-if="validationErrors.media" class="validation-error">
          {{ $t('donation.validation.amountErrors.media', { amount: validationErrors.media }) }}
        </div>
        <div v-if="validationErrors.stickers" class="validation-error">
          {{ $t('donation.validation.amountErrors.stickers', { amount: validationErrors.stickers }) }}
        </div>
        <div v-if="validationErrors.bft" class="validation-error">
          {{ $t('donation.validation.amountErrors.bft', { amount: validationErrors.bft }) }}
        </div>
        <div v-if="validationErrors.party" class="validation-error">
          {{ $t('donation.validation.amountErrors.party', { amount: validationErrors.party, game: partyProviderName }) }}
        </div>
      </div>
      <div class="validation-controls">
        <btn
          variant="primary"
          size="md"
          @click="continueWithRequiredAmount">
          {{ $t('donation.validation.send', { amount: requiredDonationAmountText }) }}
        </btn>
        <div class="continue-link" @click="continueWithCurrentAmount">
          {{ $t('donation.validation.sendOnly', { amount: currentDonationAmountText }) }}
        </div>
      </div>
    </div>
  </modal>
</template>

<script>

export default {
  name: 'ValidationModal',
  data() {
    return {}
  },
  computed: {
    ...mapFields('donation', ['validationModalOpened', 'donationAmount']),
    ...mapFields('message/message', ['donationMessageType']),
    ...mapState('widgets/media', ['video']),
    ...mapState('widgets/party', ['partyProviderName']),
    ...mapGetters('donation', ['missingAmountsActual', 'minimalAmounts']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    validationErrors() {
      const {
        donation,
        audioMessage,
        media,
        stickers,
        bft,
        party,
      } = this.missingAmountsActual

      const {
        donation: minDonation,
        audioMessage: minAudioMessage,
        media: minMedia,
        stickers: minStickers,
        bft: minBft,
        party: minParty,
      } = this.minimalAmounts

      return {
        donation: donation ? this.getAmountWithCurrency(minDonation) : null,
        audioMessage: audioMessage ? this.getAmountWithCurrency(minAudioMessage) : null,
        media: media ? this.getAmountWithCurrency(minMedia) : null,
        stickers: stickers ? this.getAmountWithCurrency(minStickers) : null,
        bft: bft ? this.getAmountWithCurrency(minBft) : null,
        party: party ? this.getAmountWithCurrency(minParty) : null,
      }
    },

    requiredDonationAmount() {
      return _.max(Object.keys(this.missingAmountsActual).map(key => this.minimalAmounts[key]))
    },

    requiredDonationAmountText() {
      return this.getAmountWithCurrency(this.requiredDonationAmount)
    },

    currentDonationAmount() {
      return this.donationAmount > this.minimalAmounts.donation
        ? this.donationAmount
        : this.minimalAmounts.donation
    },

    currentDonationAmountText() {
      return this.getAmountWithCurrency(this.currentDonationAmount)
    },
  },
  methods: {
    ...mapActions('widgets/media', ['removeVideo']),
    ...mapActions('widgets/stickers', ['clearAddedStickers']),

    closeModal() {
      this.validationModalOpened = false
    },

    async continueWithCurrentAmount() {
      const {
        audioMessage,
        media,
        stickers,
      } = this.missingAmountsActual

      if (stickers) {
        await this.clearAddedStickers()
      }

      if (media) {
        await this.removeVideo()
      }

      if (audioMessage) {
        this.donationMessageType = 'text'
      }

      this.donationAmount = this.currentDonationAmount
      this.$emit('continue')
      this.closeModal()
    },

    continueWithRequiredAmount() {
      this.donationAmount = this.requiredDonationAmount
      this.$emit('continue')
      this.closeModal()
    },
  },
}
</script>
