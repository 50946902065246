<style lang="scss" scoped>
  .record-progress {
    display: flex;
    align-items: center;

    @include media(max, sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    .recorder-start-button {
      font-weight: 600;
      color: var(--theme-link-color);
      text-transform: uppercase;
      cursor: pointer;
    }

    .progress-text {
      width: 170px;
      font-weight: 600;
      color: var(--theme-link-color);
      text-transform: uppercase;
    }

    .frequency-visualiser {
      display: flex;
      align-items: flex-end;
      height: 20px;

      @include media(max, sm) {
        margin-top: 5px;
      }

      .frequency-bar + .frequency-bar {
        margin-left: 2px;
      }

      .frequency-bar {
        width: 3px;
        min-height: 2px;
        max-height: 100%;
        @include theme-gradient;
        transition: height 50ms;
      }
    }
  }
</style>

<template>
  <div class="record-progress">
    <template v-if="recorderState.recording">
      <div class="progress-text">
        {{ $t('message.recorder.progress', { recordDurationText }) }}
      </div>
      <div class="frequency-visualiser">
        <div
          v-for="(bar, index) in bars"
          :key="index"
          class="frequency-bar"
          :style="bar">
        </div>
      </div>
    </template>
    <div
      v-else-if="!recorderState.saving"
      class="recorder-start-button"
      @click="recorderStart">{{ $t('message.recorder.startRecording') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordProgress',
  computed: {
    ...mapGetters('message/recorder', ['recordDurationText']),
    ...mapState('message/recorder', ['audioFrequencyData', 'recorderState']),

    bars() {
      return this.audioFrequencyData.reduce((acc, n) => {
        const height = 32 / Math.abs(n) * 100 - 24
        acc.push({
          height: `${height < 0 ? 0 : height}%`,
        })
        return acc
      }, [])
    },
  },
  methods: {
    ...mapActions('message/recorder', ['recorderStart']),
  },
}
</script>
