import axios from 'axios'
import store from '@state/store'

const mergeOptions = (options) => {
  const token = _.get(store, 'getters.viewer/token', null)
  const locale = _.get(store, 'getters.application/appLocale', window.document_language)

  options = {
    crossdomain: true,
    baseURL: '/api/v2',
    withCredentials: true,
    ...options,
  }

  if (token) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
        'Accept-Language': locale,
      },
    }
  }

  return options
}

const transform = (response) => {
  const { code, message, errors } = response.data

  if (code && message) {
    console.log({
      code,
      message,
      errors,
    })


  }

  return response.data
}

const parseError = ({ response }) => {
  const { code, message, errors } = response.data

  console.error('[APIv2] Request error', { code, message, errors })

  return Promise.reject({
    code,
    message,
    errors,
  })
}

const boolify = (data) => {
  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'boolean') {
      data[key] = Number(data[key])
    }
  })
}

export default {
  get(url, options = {}) {
    boolify((options ?? {}).params ?? {})

    return axios.get(url, mergeOptions(options)).then(transform).catch(parseError)
  },

  post(url, data = {}, options = {}) {
    const clonedData = _.cloneDeep(data)

    boolify(clonedData)

    return axios.post(url, clonedData, mergeOptions(options)).then(transform).catch(parseError)
  },

  put(url, data = {}, options = {}) {
    const clonedData = _.cloneDeep(data)

    boolify(clonedData)

    return axios.put(url, clonedData, mergeOptions(options)).then(transform).catch(parseError)
  },

  delete(url, options = {}) {
    return axios.delete(url, mergeOptions(options)).then(transform).catch(parseError)
  },

  uploadFile(url, formData, progressHandler) {
    return axios
      .post(url, formData, mergeOptions({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress(progressEvent) {
          const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)

          if (progressHandler) {
            progressHandler(percent)
          }

          return percent
        },
      }))
      .then(transform).catch(parseError)
  },
}

