<style scoped lang="scss">
  .app-entry {
    height: 100%;
  }
</style>

<template>
  <div class="app-entry">
    <page-layout>
      <router-view/>
    </page-layout>
  </div>
</template>

<script>

import PageLayout from '@layouts/PageLayout'

export default {
  name: 'AppEntry',
  components: { PageLayout },
  props: {
    app: {
      type: [String, Number],
      required: true,
    },
  },
}
</script>
