export const state = {
  stickers: {},
  addedStickers: {},

  widgetStickersEnabled: false,
  widgetStickersPrices: {},
}

export const getters = {
  getSticker: state => id => state.stickers[id],

  stickersList: state => Object.values(state.stickers),
  addedStickersList: state => Object.values(state.addedStickers),

  pricePerSticker: (state, getters, rootState) => state.widgetStickersPrices[rootState['currencies'].currentCurrency],
  stickersCost: (state, getters) => getters.addedStickersList.length * getters.pricePerSticker,

  widgetStickersEnabled: state => state.widgetStickersEnabled,
}

export const mutations = {
  SET_WIDGET_SETTINGS(state, {
    widgetStickersEnabled,
    widgetStickersPrices,
  }) {
    state.widgetStickersEnabled = widgetStickersEnabled
    state.widgetStickersPrices = widgetStickersPrices
  },

  SET_STICKERS(state, stickers) {
    state.stickers = stickers
  },

  SET_ADDED_STICKERS(state, stickers) {
    state.addedStickers = stickers.reduce((acc, sticker, index) => {
      acc[index + 1] = {
        ...sticker,
        id: index + 1,
      }
      return acc
    }, {})
  },
}

export const actions = {
  setup({
    state,
    commit,
    dispatch,
  }, { stickers }) {
    commit('SET_WIDGET_SETTINGS', {
      widgetStickersEnabled: !!stickers.is_enabled,
      widgetStickersPrices: stickers.per_sticker_amounts,
    })

    if (state.widgetStickersEnabled) {
      dispatch('fetchCreatorStickers')
    }
  },

  clearAddedStickers({ commit }) {
    return commit('SET_ADDED_STICKERS', [])
  },

  fetchCreatorStickers({
    commit,
    rootGetters,
  }) {
    return api
      .get('/sticker/list', {
        user_id: rootGetters['creator/getCreatorId'],
      })
      .then(({ data }) => {
        return commit('SET_STICKERS', _.keyBy(data.map(sticker => ({
          id: sticker.id,
          image: sticker.url,
        })), 'id'))
      })
  },
}
