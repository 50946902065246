/* eslint-disable */

const getItem = key => JSON.parse(window.localStorage.getItem(key));

const setItem = (key, state) => {
  window.localStorage.setItem(key, JSON.stringify(state));
};

export default {
  getItem,
  setItem
}
