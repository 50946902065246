<style lang="scss" scoped>
  .google-pay-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    box-shadow: none;
    min-width: 0;
    border-radius: var(--control-border-radius);
    height: 60px;
    background-color: black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @include padding-x(40px);

    @include media(max, sm) {
      width: 100%;
    }

    img {
      max-width: 100%;
      max-height: 24px;
    }
  }
</style>

<template>
  <button class="google-pay-button" v-on="$listeners">
    <img :src="imgSrc"/>
  </button>
</template>

<script>
import { i18n } from '@src/localization/config'

export default {
  computed: {
    locale() {
      return i18n.locale.split('_')[0]
    },

    imgSrc() {
      return `https://www.gstatic.com/instantbuy/svg/dark/donate/${this.locale}.svg`
    },
  },
}
</script>
