<style lang="scss" scoped>
  .helper-box {
    display: flex;
    align-items: center;

    .box-icon {
      flex: 0 0 auto;
      line-height: 1;
      font-size: 15px;
      margin-right: 8px;
    }

    .box-content {
      flex: 0 1 auto;
      color: $warm-grey-two;
      font-size: 12px;
      line-height: 1;
    }

    @include media(max, sm) {
      .icon {
        align-self: flex-start;
      }

      .box-content {
        line-height: 1.5;
      }
    }
  }
</style>

<template>
  <div class="helper-box">
    <icon
      v-if="icon"
      class="box-icon"
      :name="icon"
      theme gradient/>
    <div class="box-content">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
  },
}
</script>
