const config = {
  environment: 'PRODUCTION',
}

const merchantInfo = {
  merchantId: 'BCR2DN6T7PHYDWZ2',
  merchantName: 'Donation Alerts',
}

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
}

const allowedCardNetworks = ['MASTERCARD', 'VISA']

const baseCardPaymentMethod = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    allowedCardNetworks: allowedCardNetworks,
  },
}

const getPaymentDataRequest = (currencyCode, amount) => {
  return {
    ...baseRequest,
    allowedPaymentMethods: [
      {
        ...baseCardPaymentMethod,
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            'gateway': 'moneymailru',
            'gatewayMerchantId': 'DonationAlertsRURGoogle',
          },
        },
      },
    ],
    merchantInfo,
    callbackIntents: ['PAYMENT_AUTHORIZATION'],
    transactionInfo: {
      countryCode: 'RU',
      currencyCode,
      totalPriceStatus: 'FINAL',
      totalPrice: String(amount),
      checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',
    },
  }
}

class GooglePay {
  pay(currencyCode, amount) {
    if (this.available) {
      const paymentDataRequest = getPaymentDataRequest(currencyCode, amount)

      const paymentsClient = new google.payments.api.PaymentsClient({
        environment: config.environment,
        paymentDataCallbacks: {
          onPaymentAuthorized: (paymentData) => {
            return new Promise(function (resolve, reject) {
              console.log('[Google Pay]', 'onPaymentAuthorized', paymentData)

              resolve({ transactionState: 'SUCCESS' })
            })
          },
        },
      })

      return new Promise((resolve, reject) => {
        paymentsClient.loadPaymentData(paymentDataRequest)
          .then(paymentData => {
            console.log('[Google Pay]', 'loadPaymentData', paymentData)
            resolve(window.btoa(paymentData.paymentMethodData.tokenizationData.token))
          })
          .catch(e => {
            console.log('[Google Pay]', 'loadPaymentData error', e)
            reject(e)
          })
      })
    }

    return Promise.reject()
  }

  init() {
    if (!google || !google.payments) {
      return Promise.reject('Google Pay API not found')
    }

    const paymentsClient = new google.payments.api.PaymentsClient({
      environment: config.environment,
    })

    return paymentsClient.isReadyToPay({
      ...baseRequest,
      allowedPaymentMethods: [baseCardPaymentMethod],
    })
      .then((response) => {
        if (response.result) {
          console.log('[Google Pay]', 'Can make payments')
          this.available = true
        } else {
          console.log('[Google Pay]', 'Can NOT make payments')
          this.available = false
        }
      })
      .catch(() => {
        console.log('[Google Pay]', 'Can NOT make payments')
        this.available = false
      })
  }

  constructor() {
    this.available = false

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.onload = () => {
      this.init()
    }
    script.src = 'https://pay.google.com/gp/p/js/pay.js'
    document.head.appendChild(script)
  }
}

export default new GooglePay()
