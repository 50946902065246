import cookies from '@utils/cookies'


export const state = {
  lastLogin: null,
}

export const getters = {
  lastLogin: state => state.lastLogin,
}

export const mutations = {
  SET_LAST_LOGIN_INFO(state, { avatar, name, platform }) {
    state.lastLogin = { avatar, name, platform }
  },

  CLEAR_LAST_LOGIN_INFO(state) {
    state.lastLogin = null
  },
}

export const actions = {
  getLastLoginInfo({ commit }) {
    try {
      const lastLogin = cookies.getItem('last_login')
      const lastLoginJson = JSON.parse(lastLogin)

      commit('SET_LAST_LOGIN_INFO', lastLoginJson)
    } catch (err) {}
  },

  clearLastLoginInfo({ commit }) {
    try {
      cookies.removeItem('last_login')

      commit('CLEAR_LAST_LOGIN_INFO')
    } catch (err) {}
  }
}
