<style scoped lang="scss">
  .terms-of-service {

  }
</style>

<script>

export default {
  name: 'TermsOfService',
  computed: {
    ...mapGetters('creator', ['getDonationButtonText']),
    ...mapState('creator', ['termsOfService']),
  },
  render: function (createElement) {
    const {
      streamersUrl,
      viewersUrl,
    } = this.termsOfService

    if (!streamersUrl && !viewersUrl) {
      return null
    }

    const regex = /(<terms>.*<\/terms>|<offer>.*<\/offer>)/gmu

    const elements = {
      terms: (contents) => createElement(
        'a',
        {
          domProps: {
            href: streamersUrl,
            target: '_blank',
          },
        },
        contents,
      ),
      offer: (contents) => createElement(
        'a',
        {
          domProps: {
            href: viewersUrl,
            target: '_blank',
          },
        },
        contents,
      ),
    }

    const contentString = this.$t('donation.termsOfService', { buttonText: this.getDonationButtonText })
      .replaceAll(regex, '%$&%')

    const finalContents = contentString.split('%')

    finalContents.forEach((part, index) => {
      Object.keys(elements).forEach(key => {
        if (part.includes(`<${key}>`) && part.includes(`</${key}>`)) {
          const elementContents = part
            .replace(`<${key}>`, '')
            .replace(`</${key}>`, '')

          finalContents[index] = elements[key](elementContents)
        }
      })
    })

    return createElement(
      'div', {
        class: 'terms-of-service',
      },
      finalContents,
    )
  },
}
</script>
