<style lang="scss" scoped>
  .recorder-button {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    padding: 5px;
    position: relative;
    transition: border-color 100ms;

    .progress-circle {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    .button-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-radius: 100%;
      background-color: $pinkish-grey;
      cursor: pointer;
      position: relative;
      z-index: 2;

      .icon {
        color: white;

        &.microphone {
          font-size: 30px;
        }

        &.stop, &.play, &.pause {
          font-size: 20px;
        }

        &.play {
          margin-left: 3px;
        }
      }

      .loader {
        position: absolute;
        color: white;
        font-size: 30px;
      }
    }

    .locker {
      display: flex;
      align-items: center;
      justify-content: center;
      @include position(absolute, 0, 0);
      z-index: 3;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      box-shadow: 0 5px 10px 0 rgba(black, 0.21);
      background-color: white;

      .icon {
        font-size: 15px;
        color: $black;
      }
    }

    &.granted {
      border-color: rgba($tangerine, .2);

      &:hover {
        border-color: rgba($tangerine, .3);
      }

      .button-icon {
        cursor: pointer;
        @include theme-gradient;

        &:hover {
          @include theme-gradient-hover;
        }
      }
    }
  }
</style>

<template>
  <div class="recorder-button" :class="buttonClasses">
    <progress-circle :percent="progressCircle.percent" :size="70" :stroke-width="progressCircle.strokeWidth"/>
    <div v-if="locked" class="locker">
      <icon name="lock"/>
    </div>
    <div v-if="loading" class="button-icon">
      <loader/>
    </div>
    <template v-else>
      <template v-if="permission.granted">
        <template v-if="recorderActive">
          <template v-if="recorderState.recording">
            <div class="button-icon" @click="recorderStop">
              <icon class="stop" name="stop" theme-text/>
            </div>
          </template>
          <div v-else class="button-icon" @click="recorderStart">
            <icon class="microphone" name="microphone" theme-text/>
          </div>
        </template>

        <template v-if="playerActive">
          <div v-if="playingAudio" class="button-icon" @click="playerPause">
            <icon class="pause" name="pause" theme-text/>
          </div>
          <div v-else class="button-icon" @click="playerPlay">
            <icon class="play" name="play" theme-text/>
          </div>
        </template>
      </template>
      <div v-else class="button-icon">
        <icon v-if="error.deviceNotFound || error.noBrowserSupport" class="microphone" name="no-microphone"/>
        <icon v-else class="microphone" name="microphone"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RecorderButton',
  props: {
    playingAudio: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('message/recorder', [
      'permission',
      'error',
      'recorderState',
      'maxAudioDuration',
    ]),
    ...mapGetters('message/recorder', [
      'recorderActive',
      'playerActive',
      'loading',
      'locked',
      'recordDuration',
    ]),

    progressCircle() {
      return {
        percent: this.recorderState.recording ? (this.recordDuration + 1) / this.maxAudioDuration * 100 : 0,
        strokeWidth: this.recorderState.recording ? 2 : 1,
      }
    },

    buttonClasses() {
      return {
        'rejected': this.permission.rejected,
        'granted': this.permission.granted,
      }
    },
  },
  methods: {
    ...mapActions('message/recorder', [
      'recorderStop',
      'recorderStart',
    ]),

    playerPlay() {
      this.$emit('player-play')
    },

    playerPause() {
      this.$emit('player-pause')
    },
  },
}
</script>
