import axios from 'axios'
import storage from '@utils/storage'

const STORAGE_USER_ALIAS = 'da.donation.viewerAlias'
const STORAGE_USER_PHONE = 'da.donation.viewerPhone'
const STORAGE_USER_QIWI_WALLET = 'da.donation.viewerQiwiWallet'
const STORAGE_USER_EMAIL = 'da.donation.viewerEmail'

export const state = {
  loginModalOpened: false,

  viewerInfo: null,

  viewerAlias: storage.getItem(STORAGE_USER_ALIAS),
  viewerQiwiWallet: storage.getItem(STORAGE_USER_QIWI_WALLET),
  viewerPhone: storage.getItem(STORAGE_USER_PHONE),
  viewerEmail: storage.getItem(STORAGE_USER_EMAIL),
}

export const getters = {
  getField,

  viewerAuthorized: state => !!state.viewerInfo,
}

export const mutations = {
  updateField,

  SET_VIEWER_ALIAS(state, alias) {
    state.viewerAlias = alias
  },

  SET_LOGIN_MODAL_OPENED(state, value) {
    state.loginModalOpened = value
  },

  SET_VIEWER_INFO(state, data) {
    state.viewerInfo = data

    if (data && data.id) {
      _tmr.push({ type: 'setUserID', userid: data.id })
    }
  },
}

export const actions = {
  showLoginModal({ commit }) {
    commit('SET_LOGIN_MODAL_OPENED', true)
  },

  hideLoginModal({ commit }) {
    commit('SET_LOGIN_MODAL_OPENED', false)
  },

  logout({ commit }) {
    return axios
      .get('/auth/logout')
      .then(() => {
        window.location.reload()
      })
  },

  fetchCurrentUser({ commit, state }) {
    return api
      .get('/user')
      .then(({ data }) => {
        if (!state.viewerAlias || !state.viewerAlias.length) {
          commit('SET_VIEWER_ALIAS', data.name)
        }

        return commit('SET_VIEWER_INFO', data)
      })
      .catch(() => {
      })
  },

  storeViewerFields({ state }) {
    storage.setItem(STORAGE_USER_ALIAS, state.viewerAlias)
    storage.setItem(STORAGE_USER_PHONE, state.viewerPhone)
    storage.setItem(STORAGE_USER_QIWI_WALLET, state.viewerQiwiWallet)
    storage.setItem(STORAGE_USER_EMAIL, state.viewerEmail)
  },
}
