<style scoped lang="scss">
  .phone-legal-documents {
    .legal-documents-title {
      display: flex;
      align-items: center;
      cursor: pointer;

      .title-text {
        font-weight: 600;
        line-height: 16px;
      }

      .title-icon {
        margin-left: 8px;
        font-size: 10px;
        transition: transform .3s;
      }

      &.opened {
        .title-icon {
          //transform: rotate(-180deg);
          transform: rotateX(-180deg);
        }
      }
    }

    .documents-list {
      margin-top: 17px;

      .document {
        display: flex;
        flex-direction: column;
        padding: 12px;
        border-radius: 9px;
        border: 1px solid #E2E2E2;
        text-decoration: none;
        cursor: pointer;

        .document-description {
          color: #838383;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.5px;
        }

        .document-link {
          margin-top: 9px;
          display: flex;
          align-items: center;
          color: #191919;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.5px;

          .link-icon {
            margin-left: auto;
          }
        }
      }

      .document + .document {
        margin-top: 15px;
      }
    }
  }
</style>

<template>
  <div class="phone-legal-documents">
    <div class="legal-documents-title" :class="{ opened }" @click="toggle">
      <div class="title-text">{{ $t('payment.phoneDocuments.title') }}</div>
      <icon class="title-icon" name="angle-solid-down" theme gradient/>
    </div>

    <div v-if="opened" class="documents-list">
      <a
        v-for="(document, documentIndex) in documents"
        :key="documentIndex"
        :href="document.url"
        target="_blank"
        class="document">
        <div class="document-description">{{ document.description }}</div>
        <div class="document-link">
          <div class="link-text">{{ $t('payment.phoneDocuments.link') }}</div>
          <icon class="link-icon" name="link-outer"/>
        </div>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PhoneLegalDocuments',
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    documents() {
      return [
        {
          description: this.$t('payment.phoneDocuments.mts'),
          url: 'https://static.mts.ru/uploadmsk/contents/1655/oferta_mts_pay.pdf',
        },
        {
          description: this.$t('payment.phoneDocuments.megafon'),
          url: 'https://moscow.megafon.ru/download/~federal/oferts/oferta_m_platezhi.pdf',
        },
        {
          description: this.$t('payment.phoneDocuments.beeline'),
          url: 'https://www.ruru.ru/storage/offers/OfferNSK.pdf',
        },
      ]
    },
  },
  methods: {
    toggle() {
      this.opened = !this.opened
    },
  },
}
</script>
