<style scoped lang="scss">
  .widget-stickers {
    .added-stickers {
      .added-stickers-title {
        font-size: 14px;
        font-weight: 500;
      }

      .stickers-grid {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(7, 75px);
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        .sticker {
          position: relative;

          img {
            width: 75px;
            height: 75px;
            object-fit: contain;
          }

          .sticker-count {
            @include position(absolute, 0, 0);
            width: 21px;
            height: 21px;
            border-radius: 100%;
            @include theme-gradient;
            font-size: 10px;
            font-weight: 600;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .added-stickers + .stickers-controls {
      margin-top: 20px;
    }

    .stickers-controls {
      .button + .button {
        margin-left: 10px;
      }
    }

    .stickers-cost {
      margin-top: 20px;
      font-size: 13px;
      color: $warm-grey;
    }
  }
</style>

<template>
  <div class="widget-stickers">
    <div class="added-stickers" v-if="addedStickersList.length">
      <div class="added-stickers-title">{{ $t('widgets.stickers.addedStickers') }}</div>
      <div class="stickers-grid">
        <div
          v-for="(sticker, index) in addedStickers"
          :key="index"
          class="sticker">
          <img :src="sticker.image"/>
          <div class="sticker-count">{{ sticker.count }}</div>
        </div>
      </div>
    </div>
    <div class="stickers-controls">
      <template v-if="addedStickersList.length">
        <btn icon-left="pencil" @click="openEditorModal">{{ $t('global.common.edit') }}</btn>
        <btn icon-left="cross" @click="resetStickers">{{ $t('global.common.reset') }}</btn>
      </template>
      <template v-else>
        <btn icon-left="plus" @click="openEditorModal">{{ $t('widgets.stickers.addStickers') }}
        </btn>
      </template>
    </div>
    <div v-if="missingAmounts.stickers" class="stickers-cost">
      <amount-link
        :template="$t('widgets.stickers.stickersAmountTemplate')"
        :amount="missingAmounts.stickers" additive/>
      {{ pricePerStickerText }}
    </div>
    <modal v-model="editorModalOpened">
      <sticker-editor slot="body" @close="closeEditorModal"/>
    </modal>
  </div>
</template>

<script>
import StickerEditor from '../StickersEditor/StickersEditor'

export default {
  name: 'WidgetStickers',
  components: { StickerEditor },
  data() {
    return {
      editorModalOpened: false,
    }
  },
  computed: {
    ...mapGetters('widgets/stickers', ['addedStickersList', 'stickersList', 'pricePerSticker']),
    ...mapGetters('donation', ['missingAmounts']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    addedStickers() {
      const counts = this.addedStickersList.reduce((acc, addedSticker) => {
        acc[addedSticker.stickerId] = acc[addedSticker.stickerId] ? acc[addedSticker.stickerId] + 1 : 1
        return acc
      }, {})

      return this.stickersList.filter(sticker => !!counts[sticker.id]).map(sticker => ({
        ...sticker,
        count: counts[sticker.id],
      }))
    },

    pricePerStickerText() {
      const price = this.getAmountWithCurrency(this.pricePerSticker)
      return `(${this.$t('widgets.stickers.stickerPrice', { price })})`
    },
  },
  methods: {
    ...mapMutations('widgets/stickers', {
      setAddedStickers: 'SET_ADDED_STICKERS',
    }),

    resetStickers() {
      this.setAddedStickers([])
    },

    openEditorModal() {
      this.editorModalOpened = true

      this.$tmr.goal('input_sticker_changed')
    },

    closeEditorModal() {
      this.editorModalOpened = false
    },
  },
  watch: {
    addedStickersList() {
      this.$tmr.goal('input_sticker_changed')
    },
  },
}
</script>
