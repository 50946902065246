<style lang="scss" scoped>
  $range-handle-size: 18px;
  $range-track-height: 4px;

  @mixin invisible() {
    border: 0;
    opacity: 0;
    background: transparent;
  }

  .base-range {
    display: flex;
    align-items: center;

    .range-box {
      flex: 0 1 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      user-select: none;

      .range-input {
        -webkit-appearance: none;
        width: 100%;
        height: $range-track-height;
        border-radius: 5px;
        outline: none;
        padding: 0;
        margin: 0;
        position: relative;
        z-index: 2;
        cursor: pointer;

        @include invisible;

        @mixin thumb() {
          border: 0;
          appearance: none;
          width: $range-handle-size;
          height: $range-handle-size;
          border-radius: 50%;
          cursor: pointer;

          @include invisible;
        }

        &::-webkit-slider-thumb {
          @include thumb;
        }

        &:active::-webkit-slider-thumb {
          @include invisible;
        }

        &::-moz-range-thumb {
          @include thumb;
        }

        &:active::-moz-range-thumb {
          @include invisible;
        }

        &::-moz-focus-inner,
        &::-moz-focus-outer,
        &::-moz-range-track {
          @include invisible;
        }
      }

      .range-background {
        margin-top: -$range-track-height;
        height: $range-track-height;
        border-radius: 10px;
        background-color: rgba($black, .1);
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;

        .range-filler {
          border-radius: 10px;
          height: 100%;
          @include theme-gradient;
        }

        .range-toggle {
          position: absolute;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: $range-handle-size;
          width: $range-handle-size;
          border-radius: 50%;
          background: white;

          &:after {
            display: block;
            content: '';
            width: $range-handle-size - 4px;
            height: $range-handle-size - 4px;
            background-color: var(--theme-color);
            border-radius: 50%;
          }

          .range-value {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -20px;
            font-size: 12px;
            color: #5a5a5a;
          }
        }
      }
    }

    .input-addon {
      margin-left: 20px;
      flex: 0 0 auto;
      width: 90px;
    }
  }
</style>

<template>
  <div class="base-range">
    <div class="range-box">
      <input
        ref="input"
        type="range"
        v-model="rangeValue"
        class="range-input"
        :min="min"
        :max="max"
        :step="step">
      <div class="range-background">
        <div class="range-filler" :style="fillerStyles"></div>
        <div class="range-toggle" :style="toggleStyles">
          <div v-if="withValue" class="range-value">{{rangeValue}}</div>
        </div>
      </div>
    </div>
    <input-addon
      v-if="input"
      :addon="inputAddon"
      :min="min"
      :max="max"
      v-model="rangeValue"/>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    input: {
      type: Boolean,
      default: false,
    },
    inputAddon: {
      type: String,
      default: null,
    },
    withValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rangeValue: null,
    }
  },
  computed: {
    progressPercentage() {
      const percentage = Math.ceil((this.rangeValue - this.min) / (this.max - this.min) * 100)

      return percentage >= 100 ? 100 : percentage
    },

    fillerStyles() {
      const width = this.progressPercentage + Math.cos(180 / 100 * this.progressPercentage * Math.PI / 180)

      return {
        width: `${width}%`,
      }
    },

    toggleStyles() {
      return {
        left: `${this.progressPercentage}%`,
        transform: `translateX(-${this.progressPercentage}%)`,
      }
    },
  },
  methods: {
    updateRangeValue: _.throttle(function() {
      const value = Number(this.rangeValue)

      if (value < this.min) {
        this.rangeValue = this.min
        return
      } else if (value > this.max) {
        this.rangeValue = this.max
        return
      }

      this.$emit('input', value)
    }, 30)
  },
  watch: {
    rangeValue: 'updateRangeValue',

    value: {
      handler(value) {
        this.rangeValue = value
      },
      immediate: true,
    },
  },
}
</script>
