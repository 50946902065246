<style scoped lang="scss">
  .stickers-list {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    position: relative;

    .stickers-title {
      flex: 0 0 auto;
      text-transform: uppercase;
      font-weight: 600;
    }

    .stickers-cost {
      flex: 0 0 auto;
      margin-top: 15px;
      display: flex;
      align-items: center;
      font-weight: 500;

      .icon {
        font-size: 20px;
        margin-right: 8px;
      }

      .amount {
        font-weight: 600;
      }
    }

    .stickers-grid {
      height: 0;
      flex-grow: 1;
      overflow-y: scroll;
      margin-top: 15px;
      margin-left: -10px;
      padding-right: 5px;
      display: grid;
      grid-template-columns: [start] repeat(3, 110px) [end];
      grid-auto-rows: 110px;

      &:after {
        content: '';
        height: 10px;
        grid-column-start: start;
        grid-column-end: end;
      }

      .sticker {
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;

        img {
          object-fit: contain;
          height: 90px;
          width: 90px;
        }

        &:hover {
          background-color: #e8e8e8;
        }
      }
    }
  }
</style>

<template>
  <div class="stickers-list">
    <div class="stickers-title">{{ $t('widgets.stickers.editor.streamerStickers') }}</div>
    <div class="stickers-cost">
      <icon name="coins" gradient/>
      <div class="cost-text">
        {{ $t('widgets.stickers.editor.stickerCost') }} <span class="amount">{{
          stickerCost
        }}</span>
      </div>
    </div>
    <div class="stickers-grid">
      <div
        v-for="(sticker, index) in stickersList"
        :key="index"
        class="sticker"
        @click="onStickerClick(sticker.id)">
        <img :src="sticker.image"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StickerList',
  computed: {
    ...mapGetters('widgets/stickers', ['stickersList', 'pricePerSticker']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    stickerCost() {
      return this.getAmountWithCurrency(this.pricePerSticker)
    },
  },
  methods: {
    onStickerClick(id) {
      this.$emit('add', id)
    },
  },
}
</script>
