export const state = {
  products: {},
}

export const getters = {
  getProductsList: state => Object.values(state.products),

  getProduct: state => id => state.products[id],
}

export const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = _.keyBy(products, 'id')
  },
}

export const actions = {
  fetchProducts({
    commit,
    rootGetters,
  }) {
    return api.get('/merchandise', {
      user_id: rootGetters['creator/getCreatorId'],
    })
      .then(({ data }) => {
        return commit('SET_PRODUCTS', data.map(product => ({
          id: product.id,
          name: product.localized_title,
          image: product.img_url,
          link: product.url,
        })))
      })
  },
}
