<style scoped lang="scss">
  .widgets-section {

  }
</style>

<template>
  <div class="widgets-section">
    <widgets/>
  </div>
</template>

<script>

import Widgets from './Widgets'

export default {
  name: 'WidgetsSection',
  components: { Widgets },
}
</script>
