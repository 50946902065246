<style lang="scss" scoped>
  .dropdown-select {
    position: relative;
    user-select: none;

    .select-control {
      // background-color: $control-background-color;
      outline: 0 none;
      transition: $control-transition;
      cursor: pointer;
      display: flex;
      align-items: center;

      .select-control-content {

      }

      .icon {
        margin-top: 2px;
        margin-left: 5px;
        font-size: 9px;
      }
    }

    &.focused .select-control {
      border-color: $orange;
    }

    $options-offset: calc(100% + 5px);

    .select-options {
      @include position(absolute, $options-offset, 0);
      z-index: 6;
      max-height: 300px;
      min-width: 50px;
      @include padding-y(5px);
      overflow: auto;
      background-color: $control-background-color;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      border-radius: var(--block-border-radius);

      .select-option {
        padding: 10px 15px;
        cursor: pointer;
        white-space: nowrap;

        &.selected {
          color: var(--theme-color);
          background-color: rgba(black, .02);
        }

        &:hover {
          background-color: rgba(black, .05);
        }
      }
    }

    &.left {
      .select-options {
        left: -15px;
        right: auto;
      }
    }

    &.right {
      .select-options {
        left: auto;
        right: -1px;
      }
    }

    .action-button {
      font-size: 26px;
      color: #6C6C6C;
    }

    &.top {
      .select-options {
        top: auto;
        bottom: $options-offset;
        z-index: 5;
      }
    }

    .option-content {
      display: flex;
      align-items: center;

      .option-icon + .option-text {
        margin-left: 10px;
      }

      .option-text {
        flex-grow: 1;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>

<template>
  <div
    ref="select"
    class="dropdown-select"
    :class="dropdownClasses"
    v-click-outside="blurDropdown"
    @click="toggleDropdown">
    <div class="select-control">
      <div class="select-control-content">
        <template v-if="selectedOption">
          <div :title="selectedOption.label" class="selected-option" :style="selectedOption.styles">
            <slot name="selected-option" :option="selectedOption">
              <div class="option-content">
                <icon class="option-icon" v-if="selectedOption.icon" :name="selectedOption.icon"/>
                <div class="option-text">{{ selectedOption.label }}</div>
              </div>
            </slot>
          </div>
        </template>
        <template v-else>{{ $t('global.common.choose') }}</template>
      </div>
      <icon name="angle-solid-down" theme gradient/>
    </div>
    <transition name="fade-up">
      <div
        v-if="dropdownVisible"
        ref="dropdown"
        class="select-options">
        <div
          v-for="(option, index) in selectOptions"
          ref="options"
          :key="index"
          class="select-option"
          :class="option.classes"
          :style="option.styles"
          :title="option.label"
          @click="selectOption(option.value)">
          <slot name="option" :option="option">
            <div class="option-content">
              <icon class="option-icon" v-if="option.icon" :name="option.icon"/>
              <div class="option-text">{{ option.label }}</div>
            </div>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    variant: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      dropdownFocused: false,

      dropdownPosition: 'bottom',
    }
  },
  watch: {
    $scrollTop: 'calculateDropdownPosition',
    dropdownVisible: 'calculateDropdownPosition',
  },
  methods: {
    calculateDropdownPosition() {
      this.$nextTick(() => {
        if (this.dropdownVisible && this.$refs.dropdown) {
          const dropdownRect = this.$refs.dropdown.getBoundingClientRect()
          const selectRect = this.$refs.select.getBoundingClientRect()

          if (this.dropdownPosition === 'bottom') {
            this.dropdownPosition = window.innerHeight - dropdownRect.bottom < 0 ? 'top' : 'bottom'
          } else {
            this.dropdownPosition = window.innerHeight - dropdownRect.bottom < dropdownRect.height + selectRect.height + 20 ? 'top' : 'bottom'
          }
        }
      })
    },

    toggleDropdown() {
      this.dropdownFocused = !this.dropdownFocused

      if (this.dropdownFocused) {
        this.$emit('open')
      }

      if (this.dropdownFocused && this.selectedOptionIndex >= 0) {
        this.$nextTick(() => {
          const selectedOptionElement = this.$refs.options[this.selectedOptionIndex]
          const dropdownElement = this.$refs.dropdown

          const { height: dropdownHeight } = dropdownElement.getBoundingClientRect()
          const { height: selectedOptionHeight } = selectedOptionElement.getBoundingClientRect()

          dropdownElement.scrollTo({
            top: selectedOptionElement.offsetTop
              - Math.round(dropdownHeight / 2)
              + Math.round(selectedOptionHeight / 2),
          })
        })
      }
    },

    blurDropdown() {
      this.dropdownFocused = false
    },

    selectOption(value) {
      this.$emit('input', value)
      this.$emit('select', value)
    },
  },
  computed: {
    dropdownVisible() {
      return this.dropdownFocused
    },

    dropdownClasses() {
      return {
        [this.dropdownPosition]: !!this.dropdownPosition,
        focused: this.dropdownFocused,
        action: this.action,
      }
    },

    selectedOptionIndex() {
      return this.selectOptions.findIndex(option => option.selected)
    },

    selectedOption() {
      return this.selectOptions[this.selectedOptionIndex]
    },

    selectOptions() {
      return this.options.map(option => {
        const value = _.isObject(option)
          ? option[this.valueKey]
          : option
        const label = _.isObject(option)
          ? option[this.labelKey] ?? this.$t('global.common.none')
          : option ?? this.$t('global.common.none')
        const selected = value === this.value

        return {
          ...option,
          value,
          label,
          selected,
          classes: {
            selected,
          },
        }
      })
    },
  },
}
</script>
