import storage from '@utils/storage'
import { i18n, localesData } from '@src/localization/config'

const STORAGE_SYNTHESIS_VOICE = 'da.donation.synthesisVoice'

export const state = {
  donationMessageType: 'text',
  donationMessageText: '',

  speechSynthesisVoice: null,

  // Text settings
  textMessageMaxLength: 200,
  speechSynthesisEnabled: false,
  speechSynthesisVoices: {},
  speechSynthesisRandomAllowed: false,

  // Audio settings
  audioMessageEnabled: false,
  audioMessageMaxDuration: 60,

  // Prices
  speechSynthesisPrices: {},
  audioMessagePrices: {},
}

export const getters = {
  getField,

  speechSynthesisVoicesList: (state, getters, rootState, rootGetters) => _.sortBy(Object
    .values(state.speechSynthesisVoices)
    .map(voice => {
      const localeData = localesData[voice.localeCode]
      const name = voice.isRandom ? 'Случайный' : voice.name

      return {
        ...voice,
        name,
        nameFormatted: voice.isSpecial ? name : `${localeData.name} - ${name}`,
      }
    }), 'order'),

  getVoice: (state, getters, rootState) => code => {
    const voice = state.speechSynthesisVoices[code]
    const currencyCode = rootState['currencies'].currentCurrency

    return {
      ...voice,
      price: voice.isRandom ? 0 : voice.prices[currencyCode],
    }
  },

  speechSynthesisCost: (state, getters, rootState) => state.speechSynthesisPrices[rootState['currencies'].currentCurrency],
  audioMessageCost: (state, getters, rootState) => state.audioMessagePrices[rootState['currencies'].currentCurrency],
}

export const mutations = {
  updateField,

  SET_SYNTHESIS_VOICE(state, voice) {
    state.speechSynthesisVoice = voice
  },

  SAVE_SYNTHESIS_VOICE(state) {
    storage.setItem(STORAGE_SYNTHESIS_VOICE, state.speechSynthesisVoice)
  },

  SET_MESSAGE_SETTINGS(state, {
    textMessageMaxLength,
    speechSynthesisEnabled,
    speechSynthesisVoices,
    speechSynthesisRandomAllowed,
    audioMessageEnabled,
    audioMessageMaxDuration,
    speechSynthesisPrices,
    audioMessagePrices,
  }) {
    state.textMessageMaxLength = textMessageMaxLength

    state.speechSynthesisEnabled = speechSynthesisEnabled
    state.speechSynthesisVoices = _.keyBy(speechSynthesisVoices, 'code')
    state.speechSynthesisRandomAllowed = speechSynthesisRandomAllowed

    state.audioMessageEnabled = audioMessageEnabled
    state.audioMessageMaxDuration = audioMessageMaxDuration

    state.speechSynthesisPrices = speechSynthesisPrices
    state.audioMessagePrices = audioMessagePrices
  },
}

export const actions = {
  setup({
    commit,
    state,
    rootGetters,
  }, {
    textDonations,
    textToSpeech,
    voiceDonations,
  }) {
    const voices = textToSpeech.synthesizer_voices.map(voice => {
      const name = voice.displayed_name
      const isSpecial = voice.group === 'special'

      return {
        id: voice.id,
        code: `${voice.locale}:${voice.id}`,
        isSpecial,
        name,
        order: -voice.priority * 100,
        prices: voice.prices,
        localeCode: voice.locale,
        audioUrl: voice.preview_url,
      }
    })

    if (textToSpeech.is_random_allowed) {
      const voicesGrouped = _.groupBy(voices, 'localeCode')

      Object.keys(voicesGrouped).forEach(localeCode => {
        const filteredVoices = voicesGrouped[localeCode].filter(voice => !voice.isSpecial)

        if (filteredVoices.length > 1) {
          const lastOrder = _.min(_.map(filteredVoices, 'order'))

          voices.push({
            code: `${localeCode}:random`,
            isRandom: true,
            localeCode,
            order: lastOrder + 1,
          })
        }
      })
    }

    commit('SET_MESSAGE_SETTINGS', {
      textMessageMaxLength: textDonations.max_length,

      speechSynthesisEnabled: textToSpeech.is_enabled && voices.length > 0,
      speechSynthesisVoices: _.sortBy(voices, 'order'),
      speechSynthesisRandomAllowed: textToSpeech.is_random_allowed,

      audioMessageEnabled: voiceDonations.is_enabled,
      audioMessageMaxDuration: voiceDonations.max_length,

      speechSynthesisPrices: textToSpeech.min_amounts,
      audioMessagePrices: voiceDonations.min_amounts,
    })

    if (voices.length) {
      let selectedVoiceCode = storage.getItem(STORAGE_SYNTHESIS_VOICE)

      if (!selectedVoiceCode || !_.map(voices, 'code').includes(selectedVoiceCode)) {
        let selectedVoice = Object.values(state.speechSynthesisVoices)
          .find(voice => voice.localeCode === i18n.locale)

        if (!selectedVoice) {
          selectedVoice = _.head(Object.values(state.speechSynthesisVoices))
        }

        if (selectedVoice) {
          selectedVoiceCode = selectedVoice.code
        }
      }

      if (selectedVoiceCode) {
        commit('SET_SYNTHESIS_VOICE', selectedVoiceCode)
      }
    }
  },
}
