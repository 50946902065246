export const state = {
  widgetsAvailable: {
    stickers: false,
    media: false,
    goals: false,
    poll: false,
    party: false,
    bft: false,
  },
}

export const getters = {
  displayWidgets: state => !!Object.values(state.widgetsAvailable).filter(value => value).length,
}

export const mutations = {
  SET_WIDGETS_AVAILABLE(state, widgetsAvailable) {
    state.widgetsAvailable = widgetsAvailable
  },
}

export const actions = {
  setup({
    dispatch,
    commit,
  }, {
    poll,
    donationGoals,
    media,
    stickers,
    party,
    bft,
  }) {
    const widgetsAvailable = {}

    // Widget stickers
    if (stickers && stickers.is_enabled) {
      dispatch('widgets/stickers/setup', { stickers }, { root: true })
      widgetsAvailable.stickers = true
    }

    // Widget media
    if (media && media.is_enabled) {
      dispatch('widgets/media/setup', { media }, { root: true })
      widgetsAvailable.media = true
    }

    // Widget goals
    if (donationGoals && donationGoals.length) {
      dispatch('widgets/goals/setup', { donationGoals }, { root: true })
      widgetsAvailable.goals = true
    }

    // Widget poll
    if (poll) {
      dispatch('widgets/poll/setup', { poll }, { root: true })
      widgetsAvailable.poll = true
    }

    // Widget party
    if (party && party.is_enabled) {
      dispatch('widgets/party/setup', { party }, { root: true })
      widgetsAvailable.party = true
    }

    // Widget BFT
    if (bft && bft.is_enabled) {
      dispatch('widgets/bft/setup', { bft }, { root: true })
      widgetsAvailable.bft = true
    }

    commit('SET_WIDGETS_AVAILABLE', widgetsAvailable)
  },
}
