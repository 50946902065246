<style lang="scss" scoped>
  .base-textarea {

  }
</style>

<template>
  <textarea
    v-bind="$attrs"
    class="form-control base-textarea"
    v-on="listeners"/>
</template>

<script>

export default {
  inheritAttrs: false,
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value)
        },
      }
    },
  },
}
</script>
