<style lang="scss" scoped>
  .text-editor {
    position: relative;

    .editor {
      min-height: 140px;
      padding-bottom: 55px;
      line-height: 1.7;
    }

    .message-length-count {
      position: absolute;
      right: 15px;
      bottom: 15px;
      font-size: 13px;
      line-height: 1.25;
      color: $warm-grey;
      pointer-events: none;
      text-align: right;

      @include media(max, sm) {
        bottom: 55px;
        right: auto;
        left: 15px;
      }
    }

    .speech-synthesis-menu {
      position: absolute;
      left: 0;
      bottom: 15px;
      width: 100%;
      padding-right: 90px;
      padding-left: 15px;

      @include media(max, sm) {
        padding-right: 15px;
      }
    }
  }
</style>

<template>
  <div class="text-editor">
    <base-textarea
      ref="editor"
      v-model="donationMessageText"
      @input="onEditorInput"
      class="editor"
      @change="onEditorChange"/>

    <div class="message-length-count">{{ messageLengthText }}</div>

    <speech-synthesis-menu
      v-if="speechSynthesisEnabled"
      class="editor-addon"/>
  </div>
</template>

<script>

import SpeechSynthesisMenu from './SpeechSynthesisMenu'

export default {
  name: 'TextEditor',
  components: {
    SpeechSynthesisMenu,
  },
  data() {
    return {
      editorInitialHeight: null,
      editorHeight: null,
    }
  },
  computed: {
    ...mapState('message/message', [
      'textMessageMaxLength',
      'speechSynthesisEnabled',
    ]),
    ...mapFields('message/message', [
      'donationMessageText',
    ]),

    textLength() {
      return this.donationMessageText.length
    },

    messageLengthText() {
      return `${this.textLength} / ${this.textMessageMaxLength}`
    },
  },
  methods: {
    onEditorInput() {
      this.$nextTick(this.calculateEditorHeight)
    },

    calculateEditorHeight() {
      this.$refs.editor.$el.style.height = '1px'

      const { scrollHeight } = this.$refs.editor.$el

      if (scrollHeight > this.editorInitialHeight) {
        this.$refs.editor.$el.style.height = `${scrollHeight + 20}px`
      } else {
        this.$refs.editor.$el.style.height = `${this.editorInitialHeight}px`
      }
    },

    onEditorChange() {
      this.$tmr.goal('input_message')
    },
  },
  mounted() {
    this.$nextTick(() => {
      const { height } = this.$refs.editor.$el.getBoundingClientRect()

      this.editorInitialHeight = height

      this.calculateEditorHeight()
    })
  },
  watch: {
    donationMessageText(value, oldValue) {
      if (value !== oldValue) {
        this.donationMessageText = value
          .replace(/\r?\n|\r/g, '')
          .slice(0, this.textMessageMaxLength)
      }
    },

    $appWidth: 'calculateEditorHeight',
  },
}
</script>
