/* eslint-disable */

import storage from '@utils/storage'

const appendGoogleAnalytics = () => {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date()
    a = s.createElement(o),
      m = s.getElementsByTagName(o)[0]
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

  ga('create', 'UA-30021773-2', 'auto')
  ga('send', 'pageview')
}

const appendTopMailRuAnalytics = () => {
  (function () {
    const _tmr = window._tmr || (window._tmr = [])
    _tmr.push({
      id: window._tmr_da_tracker_id,
      type: 'pageView',
      start: (new Date()).getTime(),
    })
    // Общепроектовый счетчик посещений - не удалять
    if (PRODUCTION_MODE) {
      _tmr.push({
        id: window._tmr_global_tracker_id,
        type: 'pageView',
        start: (new Date()).getTime(),
      })
    }
    (function (d, w, id) {
      if (d.getElementById(id)) {
        return
      }
      const ts = d.createElement('script')
      ts.type = 'text/javascript'
      ts.async = true
      ts.id = id
      ts.src = 'https://top-fwz1.mail.ru/js/code.js'
      const f = function () {
        const s = d.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(ts, s)
      }
      if (w.opera === '[object Opera]') {
        d.addEventListener('DOMContentLoaded', f, false)
      } else {
        f()
      }
    })(document, window, 'topmailru-code')
  })()
}

const append1LinkAnalytics = () => {
  (function () {
    const partnerScript = document.createElement('script')
    partnerScript.type = 'text/javascript'
    partnerScript.async = true
    partnerScript.src = '//1l-hit.my.games/v1/hit/101614.js?r=' + encodeURIComponent(document.referrer) + '&u=' + encodeURIComponent(0) + '&rnd=' + Math.random()
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(partnerScript, firstScript)
  })()
}

export const appendAnalytics = () => {
  if (PRODUCTION_MODE || storage.getItem('da.donation.debug.ga')) {
    appendGoogleAnalytics()
  }

  if (PRODUCTION_MODE || storage.getItem('da.donation.debug.1link')) {
    append1LinkAnalytics()
  }

  appendTopMailRuAnalytics()
}
