<style lang="scss" scoped>
  .base-select {
  }
</style>

<template>
  <select
    v-bind="$attrs"
    v-model="selected"
    required
    class="form-control base-select">
    <option
      v-if="placeholder"
      :value="null">{{placeholder}}</option>
    <template v-for="(option, index) in selectOptions">
      <option :key="index" :value="option.value">{{option.label}}</option>
    </template>
  </select>
</template>

<script>

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    selectOptions() {
      return this.options.map(option => (_.isObject(option) ? {
        value: option[this.valueKey],
        label: option[this.labelKey],
      } : {
        value: option,
        label: option,
      }));
    },
  },
  watch: {
    selected(value) {
      this.$emit('input', value);
    },

    value: {
      handler() {
        this.selected = this.value;
      },
      immediate: true,
    },
  },
};
</script>
