<style scoped lang="scss">
  .payment-page {
  }
</style>

<template>
  <div class="payment-page">
    <div v-if="payloadValidated" class="content-block">
      <content-block-controls @onReturn="onReturn"/>
      <div class="content-block-body">
        <payment-form/>
      </div>
      <content-block-footer/>
    </div>
  </div>
</template>

<script>

import PaymentForm from '@components/CreatorPage/PaymentPage/PaymentForm'
import ContentBlockControls from '@components/CreatorPage/ContentBlockControls'
import ContentBlockFooter from '@components/CreatorPage/ContentBlockFooter'

export default {
  name: 'PaymentPage',
  components: {
    ContentBlockFooter,
    ContentBlockControls,
    PaymentForm,
  },
  computed: {
    ...mapState('invoice', ['payloadValidated']),
  },
  created() {
    if (!this.payloadValidated) {
      this.$router.replace({
        name: 'creator-page',
      })
    }
  },
  methods: {
    onReturn() {
      this.$tmr.goal('checkout_back')
    },
  },
}
</script>
