import cookies from '@utils/cookies'

export const INVOICE_TYPE = {
  DONATION: 'donation',
  PARTNER: 'third_party_donation',
}

export const state = {
  invoiceType: INVOICE_TYPE.DONATION,

  invoiceId: null,
  invoiceHash: null,

  payloadValidated: false,

  partnerExternalData: null,
}

export const getters = {
  getField,
}

export const mutations = {
  updateField,

  SET_INVOICE_TYPE(state, type) {
    state.invoiceType = type
  },

  SET_INVOICE_ID(state, id) {
    state.invoiceId = id
  },

  SET_INVOICE_HASH(state, hash) {
    state.invoiceHash = hash
  },

  SET_PAYLOAD_VALIDATED(state, value) {
    state.payloadValidated = value
  },
}

export const actions = {
  prepareInvoicePayload({ rootState, rootGetters }) {
    const {
      donationAmount,
      donationIsAnonymous,
    } = rootState['donation']

    const {
      donationMessageType,
      donationMessageText,
      speechSynthesisVoice,
      speechSynthesisEnabled,
    } = rootState['message'].message

    const textMessage = donationMessageText && donationMessageText.length ? donationMessageText : undefined

    const payload = {
      type: 'donation',
      amount: donationAmount,
      currency: rootState['currencies'].currentCurrency,
      user_id: rootGetters['creator/getCreatorId'],
      message_type: donationMessageType,
    }

    const stickers = rootGetters['widgets/stickers/addedStickersList']

    if (stickers.length) {
      payload.donation_stickers = stickers.map(sticker => ({
        id: sticker.stickerId,
        left: sticker.left,
        top: sticker.top,
        scale: sticker.size * 10,
        angle: sticker.angle,
      }))
    }

    const partyActions = rootGetters['widgets/party/getSelectedActionsList']

    if (partyActions.length) {
      payload.third_party_actions = (partyActions.map(({ id, count }) => ({ id, count })))
    }

    const bftActions = rootGetters['widgets/bft/getSelectedActionsList']

    if (bftActions.length) {
      payload.donation_bft = (bftActions.map(bftAction => ({
        game_id: bftAction.gameId,
        category_id: bftAction.categoryId,
        action_id: bftAction.id,
        count: bftAction.count,
      })))
    }

    const {
      selectedGoalId,
    } = rootState['widgets'].goals

    if (selectedGoalId) {
      payload.donation_goal_id = selectedGoalId
    }

    const {
      pollId,
      selectedPollOptionId,
      customPollOption,
    } = rootState['widgets'].poll

    if (pollId) {
      if (selectedPollOptionId === 'custom') {
        payload.donation_user_poll_option = customPollOption
      } else if (selectedPollOptionId) {
        payload.donation_poll_id = selectedPollOptionId
      }
    }

    if (!donationIsAnonymous) {
      const alias = rootState['viewer'].viewerAlias

      if (alias && alias.length) {
        payload.name = alias
      }
    }

    if (donationMessageType === 'text') {
      payload.comment = textMessage

      if (speechSynthesisEnabled) {
        const [voiceLocale, voiceId] = speechSynthesisVoice.split(':')

        payload.donation_tts_voice_lang = voiceLocale
        payload.donation_tts_voice_id = voiceId === 'random' ? voiceId : Number(voiceId)
      }
    }

    const recordDuration = rootGetters['message/recorder/recordDuration']

    if (donationMessageType === 'audio' && recordDuration) {
      payload.donation_audio = rootState['message'].recorder.audioBlob
      payload.donation_audio_duration = recordDuration
    }

    const {
      videoId,
      videoStartTime,
    } = rootState['widgets'].media

    if (videoId) {
      payload.donation_video_id = videoId
      payload.donation_video_start_from = videoStartTime
    }

    return payload
  },

  async validateInvoicePayload({ dispatch, commit, rootState }) {
    const payload = await dispatch('prepareInvoicePayload')

    const method = rootState['message'].message.donationMessageType === 'audio' ? api.postFormData : api.post

    return method('/payin/validate_invoice_fields', payload)
      .then(res => {
        commit('SET_PAYLOAD_VALIDATED', true)

        return res
      })
  },

  preparePaymentPayload({ state, rootState, rootGetters }, { extra = {} } = {}) {
    const payload = {}

    const {
      paymentMethod,
      commissionCovered,
      paymentRegion,
    } = rootState['payment']

    if (['adyenSofortEur', 'trustly'].includes(paymentMethod)) {
      payload.country = paymentRegion
    }

    const {
      viewerEmail,
      viewerQiwiWallet,
      viewerPhone,
    } = rootState['viewer']

    payload.email = viewerEmail

    if (['fakeMobile', 'fakeMobileDobro', 'qiwiMyCom', 'qiwiDobro', 'sberPay'].includes(paymentMethod)) {
      payload.phone = ['qiwiMyCom', 'qiwiDobro'].includes(paymentMethod) ? viewerQiwiWallet : viewerPhone
    }

    payload.system = paymentMethod
    payload.commission_covered = commissionCovered

    if (extra && extra.paymentToken) {
      _.set(payload, 'extra.payment_token', extra.paymentToken)
    }

    _.set(payload, 'extra.apid', cookies.getItem('apid'))

    return payload
  },

  async createInvoice({ commit, dispatch, rootGetters, rootState }, extra) {
    dispatch('viewer/storeViewerFields', null, { root: true })

    const payload = {
      ...await dispatch('prepareInvoicePayload'),
      ...await dispatch('preparePaymentPayload', { extra }),
    }

    const url = rootGetters['viewer/viewerAuthorized'] ? '/payin/invoice/auth' : '/payin/invoice'

    const method = rootState['message'].message.donationMessageType === 'audio' ? api.postFormData : api.post

    commit('payment/SAVE_PAYMENT_SETTINGS', null, { root: true })
    commit('message/message/SAVE_SYNTHESIS_VOICE', null, { root: true })
    dispatch('donation/saveDonationAmount', null, { root: true })
    dispatch('currencies/saveCurrency', null, { root: true })

    return method(url, payload)
      .then(({ data }) => {
        commit('SET_INVOICE_ID', data.invoice_id)
        commit('SET_INVOICE_HASH', data.hash)

        return data
      })
  },

  async createPartnerInvoice({ commit, dispatch, rootGetters, rootState }, extra) {
    const { donationIsAnonymous } = rootState['donation']

    const payload = {
      user_id: rootGetters['creator/getCreatorId'],
      ...state['partnerExternalData'],
      ...await dispatch('preparePaymentPayload', { extra }),
    }

    if (!donationIsAnonymous) {
      const alias = rootState['viewer'].viewerAlias

      if (alias && alias.length) {
        payload.name = alias
      }
    }

    return api.post('/payin/invoice/partner', payload)
      .then(({ data }) => {
        commit('SET_INVOICE_ID', data.invoice_id)
        commit('SET_INVOICE_HASH', data.hash)

        return data
      })
  },

  checkInvoice({ state }, { id, hash }) {
    return api
      .get('/payin/invoice', {
        type: state.invoiceType,
        id: id,
        hash: hash,
      })
      .then(({ data }) => data)
  },
}
