<style scoped lang="scss">
  .service-donation-page {

  }
</style>

<template>
  <div class="service-donation-page">
    <donation-form/>
  </div>
</template>

<script>

import DonationForm from '@components/CreatorPage/DonationPage/DonationForm'

export default {
  name: 'ServiceDonationPage',
  components: {
    DonationForm,
  },
  computed: {
    ...mapFields('invoice', ['payloadValidated']),
  },
  created() {
    this.payloadValidated = false
  },
  mounted() {
    this.$ga.view({
      category: 'AB_DonationPage',
      action: 'page',
    })

    this.$tmr.event({
      category: 'AB_DonationPage',
      action: 'new_view_page',
      label: this.getCreatorId,
    })

    this.$tmr.goal('page_opened')
  },
}
</script>
