<style scoped lang="scss">
  .creator-badges {
    display: flex;
    align-items: center;

    .creator-badge + .creator-badge {
      margin-left: 8px;
    }
  }
</style>

<template>
  <div class="creator-badges">
    <creator-badge
      v-for="(award, index) in creatorAwards"
      :key="index"
      :image="award.image"
      :type="award.type"
      :title="award.title"
      :text="award.text"/>
  </div>
</template>

<script>

import CreatorBadge from './CreatorBadge'

export default {
  name: 'CreatorBadges',
  components: { CreatorBadge },
  computed: {
    ...mapState('creator', ['creatorInfo', 'creatorAwards']),
  },
}
</script>
