<style scoped lang="scss">
  .creator-badge {
    line-height: 1;

    &.verified {
      font-size: 24px;

      @include media(max, sm) {
        font-size: 18px;
      }

      ::v-deep svg {
        fill: url(#_system_gradient_1);
      }
    }

    &.awards_silver, &.awards_gold {
      img {
        height: 30px;
      }

      @include media(max, sm) {
        img {
          height: 24px;
        }
      }
    }
  }

  .badge-title {
    font-size: 13px;
    line-height: 1.54;
    color: rgba($black-five, .5);
  }

  .badge-text {
    line-height: 1.54;
    color: $black-five;
    font-size: 14px;
  }
</style>

<template>
  <div class="creator-badge" :class="badgeClasses">
    <v-popover placement="top" trigger="hover">
      <icon v-if="type === 'verified'" name="badge-verified"/>
      <img v-if="image" :src="image"/>
      <div slot="popover">
        <div v-if="title" class="badge-title">{{ title }}</div>
        <div v-if="text" class="badge-text">{{ text }}</div>
      </div>
    </v-popover>
  </div>
</template>

<script>

export default {
  name: 'CreatorBadge',
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
  },
  computed: {
    badgeClasses() {
      return [this.type]
    },
  },
}
</script>
