<style lang="scss" scoped>
  .currency-select {

  }
</style>

<template>
  <dropdown-select
    v-model="currentCurrency"
    :options="currencyOptions"
    @open="onCurrencySelectOpen"
    @select="onCurrencyOptionSelect"
    class="currency-select left"/>
</template>

<script>

export default {
  name: 'CurrencySelect',
  computed: {
    ...mapFields('currencies', ['currentCurrency']),
    ...mapGetters('currencies', ['payInCurrenciesList']),

    currencyOptions() {
      const longText = this.$appIsLG

      return this.payInCurrenciesList.map(({
        name,
        symbol,
        code,
      }) => ({
        label: longText ? `${name} - ${symbol} (${code})` : `${symbol} (${code})`,
        value: code,
      }))
    },
  },
  methods: {
    onCurrencySelectOpen() {
      this.$ga.action({
        category: 'DonationPage',
        action: 'btn_currency',
      })
    },

    onCurrencyOptionSelect(currency) {
      this.$ga.event({
        category: 'DonationPage',
        action: 'chng_currency',
        label: currency,
      })

      this.$tmr.goal('currency_changed', {
        currency,
      })
    },
  },
}
</script>
