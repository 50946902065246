<style lang="scss" scoped>
  .icon {
    &.inline {
      height: 1em;
      width: 1em;
    }

    fill: currentColor;

    &.system {
      fill: url(#_system_gradient_1);
    }

    &.theme {
      &.gradient {
        fill: url(#_theme_gradient);
      }

      &:not(.gradient) {
        fill: var(--theme-color);
      }
    }

    &.theme-text {
      fill: var(--theme-text-color);
    }
  }
</style>

<template>
  <svg class="icon" :class="classes">
    <use :xlink:href="iconPath"/>
  </svg>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    system: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: Boolean,
      default: false,
    },
    themeText: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconPath() {
      try {
        const icon = require(`@assets/svg/icons-sprite/${this.name}.svg`)
        return `#${icon.default.id}`
      } catch (e) {
        return null
      }
    },
    classes() {
      return {
        [`icon--${this.name}`]: true,
        inline: this.inline,
        system: this.system,
        theme: this.theme,
        'theme-text': this.themeText,
        gradient: this.gradient,
      }
    },
  },
}
</script>
