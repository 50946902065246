<style scoped lang="scss">
  .widget-poll {
    .poll-title {
      font-size: 18px;
      word-break: break-word;
    }

    .poll-options {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .poll-option + .poll-option {
        margin-top: 20px;
      }

      .poll-option {
        display: flex;
        flex-direction: column;

        .option-header {
          align-self: flex-start;
        }

        .option-body {
          margin-top: 10px;
          display: flex;
          align-items: center;

          .option-body-section {
            @include margin-y(auto);

            &.progress-bar {
              height: 7px;
              border-radius: 4px;
              overflow: hidden;
              background-color: $background-color;
              flex-shrink: 0;
              flex-grow: 1;

              @include media(max, sm) {
                max-width: 160px;
              }

              .progress-bar-filler {
                height: 100%;
                border-radius: 4px;
                @include theme-gradient;
              }
            }

            &.progress-percentage {
              margin-left: 10px;
              width: 50px;
              font-size: 13px;
              font-weight: 600;
              line-height: 1;
              color: $black;
              flex-shrink: 0;
            }

            &.progress-text {
              width: 140px;
              font-size: 13px;
              line-height: 1;
              color: $warm-grey;
              text-align: right;
            }
          }
        }
      }
    }

    .poll-custom-option {
      width: 300px;
      margin-top: 20px;

      @include media(max, sm) {
        width: 100%;
      }
    }

    .poll-helper {
      margin-top: 15px;
      font-size: 12px;
      color: $warm-grey-two;
    }
  }
</style>

<template>
  <div v-if="widgetPollEnabled" class="widget-poll">
    <div class="poll-title">{{ pollTitle }}</div>
    <div class="poll-options">
      <div class="poll-option">
        <div class="option-header">
          <base-radio
            :value="null"
            v-model="selectedPollOptionId">{{ $t('widgets.poll.skip') }}
          </base-radio>
        </div>
      </div>
      <div
        v-for="(option, index) in options"
        :key="index"
        class="poll-option">
        <div class="option-header">
          <base-radio
            :value="option.id"
            v-model="selectedPollOptionId">{{ option.title }}
          </base-radio>
        </div>
        <div class="option-body">
          <div class="option-body-section progress-bar">
            <div class="progress-bar-filler" :style="{width: `${option.progressPercentage}%`}"/>
          </div>
          <div class="option-body-section progress-percentage">
            {{ option.progressPercentage }}%
          </div>
          <div class="option-body-section progress-text">
            {{ option.progressText }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="customPollOptionAllowed" class="poll-custom-option">
      <advanced-input
        icon-left="plus"
        :placeholder="$t('widgets.poll.customOptionPlaceholder')"
        v-model="customPollOption"/>
    </div>
    <div class="poll-helper">{{ votesAmountText }}</div>
  </div>
</template>

<script>
import { declOfNum } from '@utils/utils'

export default {
  name: 'WidgetPoll',
  computed: {
    ...mapGetters('currencies', [
      'getCurrentCurrency',
    ]),
    ...mapGetters('widgets/poll', [
      'widgetPollEnabled',
    ]),
    ...mapState('widgets/poll', [
      'pollOptions',
      'pollTitle',
      'customPollOptionAllowed',
      'currencyVoteAmounts',
      'progressByAmount',
    ]),

    ...mapFields('widgets/poll', ['selectedPollOptionId', 'customPollOption']),

    options() {
      const options = Object.values(this.pollOptions)
      const votesCount = options.reduce((acc, option) => acc + option.votes, 0)

      return options.map(option => {
        const selected = this.selectedPollOptionId === option.id
        const percentage = (option.votes / votesCount * 100) || 0

        return {
          ...option,
          selected,
          progressPercentage: percentage < 1 ? (percentage > 0 ? 1 : 0) : Math.floor(percentage),
          progressText: this.$tc('widgets.poll.votes_plural', option.votes, { count: option.votes }),
        }
      })
    },

    votesAmountText() {
      const currencyCode = this.getCurrentCurrency.code
      const votesAmount = this.currencyVoteAmounts[currencyCode]

      return this.progressByAmount
        ? `1 ${currencyCode} = ${this.$tc('widgets.poll.votes_plural', votesAmount, { count: votesAmount })}`
        : this.$t('widgets.poll.votePrice')
    },
  },
  watch: {
    customPollOption(value) {
      if (value) {
        this.selectedPollOptionId = 'custom'
      } else {
        this.customPollOption = null
        this.selectedPollOptionId = null
      }
    },
    selectedPollOptionId(value) {
      if (value && value !== 'custom') {
        this.customPollOption = null
      }
    },
  },
}
</script>
