<style scoped lang="scss">
  .widget-goal {
    .goals-list {
      max-width: 400px;

      .goal + .goal {
        margin-top: 24px;
      }

      .goal {
        display: flex;
        flex-direction: column;

        .goal-header {
          align-self: flex-start;
        }

        .goal-body {
          margin-top: 10px;
          display: flex;
          align-items: center;

          .goal-body-section {
            @include margin-y(auto);

            &.progress-bar {
              height: 7px;
              border-radius: 4px;
              overflow: hidden;
              background-color: $background-color;
              flex-shrink: 0;
              flex-grow: 1;

              @include media(max, sm) {
                max-width: 160px;
              }

              .progress-bar-filler {
                height: 100%;
                border-radius: 4px;
                @include theme-gradient;
              }
            }

            &.progress-percentage {
              margin-left: 10px;
              width: 50px;
              font-size: 13px;
              font-weight: 600;
              line-height: 1;
              color: $black;
              flex-shrink: 0;
            }

            &.progress-text {
              width: 140px;
              font-size: 13px;
              line-height: 1;
              color: $warm-grey;
              text-align: right;
            }
          }
        }
      }
    }

    .radio-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      background-color: #e3e3e3;
      border-radius: 100%;

      &.checked {
        background-color: $tangerine;

        &:after {
          display: block;
          content: '';
          width: 9px;
          height: 9px;
          border-radius: 100%;
          background-color: white;
        }
      }
    }
  }
</style>

<template>
  <div class="widget-goal">
    <div class="goals-list">
      <div class="goal">
        <div class="goal-header">
          <base-radio :value="null" v-model="selectedGoalId">
            {{ $t('widgets.goals.skip') }}
          </base-radio>
        </div>
      </div>
      <div
        v-for="(goal, index) in goals"
        :key="index"
        class="goal">
        <div class="goal-header">
          <base-radio :value="goal.id" v-model="selectedGoalId">{{ goal.title }}</base-radio>
        </div>
        <div class="goal-body">
          <div class="goal-body-section progress-bar">
            <div class="progress-bar-filler" :style="{width: `${goal.progressPercentage}%`}"/>
          </div>
          <div class="goal-body-section progress-percentage">{{ goal.progressPercentage }}%</div>
          <div class="goal-body-section progress-text">{{ goal.progressText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WidgetGoal',
  computed: {
    ...mapGetters('currencies', ['getCurrency']),
    ...mapGetters('widgets/goals', ['goalsList']),
    ...mapFields('widgets/goals', ['selectedGoalId']),

    goals() {
      return this.goalsList.map(goal => {
        const selected = this.selectedGoalId === goal.id
        const percentage = goal.raisedAmount / goal.goalAmount * 100
        const currency = this.getCurrency(goal.currency)

        return {
          ...goal,
          selected,
          currencySymbol: currency.symbol,
          progressPercentage: percentage < 1 ? (percentage > 0 ? 1 : 0) : Math.floor(percentage),
          progressText: `${goal.raisedAmount} / ${goal.goalAmount} ${currency.symbol}`,
        }
      })
    },
  },
}
</script>
