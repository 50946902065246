import { i18n } from '@src/localization/config'

export const state = {
  creatorInfo: {},

  creatorDescription: null,

  creatorAwards: [],
  creatorLinks: [],

  isDobroCreator: false,

  isBlocked: false,
  blockReason: null,

  termsOfService: {
    streamersUrl: null,
    viewersUrl: null,
    showCompanyDisclaimer: false,
  },

  creatorPageSettings: {
    pageBackgroundColor: null,
    pageBackgroundImage: null,
    pageBackgroundRepeat: false,

    headerBackgroundImage: null,
    displayAvatar: true,

    donationButtonText: null,
  },
}

export const getters = {
  getCreatorId: state => state.creatorInfo.id,
  getCreatorName: state => state.creatorInfo.name,
  getDonationButtonText: state => state.creatorPageSettings.donationButtonText ?? i18n.t('donation.donationButtonText'),
}

export const mutations = {
  SET_CREATOR_INFO(state, { id, name, code, avatar, verified, online, isDobro, boosty }) {
    state.creatorInfo = { id, name, code, avatar, verified, online, isDobro, boosty }
  },

  SET_BLOCK_STATUS(state, { isBlocked, blockReason }) {
    state.isBlocked = isBlocked
    state.blockReason = blockReason
  },

  SET_CREATOR_DESCRIPTION(state, description) {
    state.creatorDescription = description
  },

  SET_CREATOR_AWARDS(state, awards) {
    state.creatorAwards = awards
  },

  SET_CREATOR_LINKS(state, links) {
    state.creatorLinks = links
  },

  SET_CREATOR_PAGE_SETTINGS(state, settings) {
    state.creatorPageSettings = settings
  },

  SET_TERMS_OF_SERVICE(state, termsOfService) {
    state.termsOfService = termsOfService
  },
}

export const actions = {
  fetchCreatorPageSettings({
    commit,
    dispatch,
    rootGetters,
  }, slug) {
    return api.get(`/user/${slug}/donationpagesettings`)
      .then(async ({ data }) => {
        commit('SET_CREATOR_PAGE_SETTINGS', {
          pageBackgroundColor: data.background_color,
          pageBackgroundImage: data.background_image_url,
          pageBackgroundRepeat: !!data.is_background_repeat_enabled,

          headerBackgroundImage: data.header_image_url,
          displayAvatar: data.is_avatar_enabled,

          donationButtonText: data.donation_send_button_text,
        })

        commit('SET_CREATOR_INFO', {
          id: data.id,
          name: data.name,
          code: data.code,
          avatar: data.avatar,
          verified: !!data.is_verified,
          online: !!data.is_online,
          isDobro: !!data.is_dobro,
          boosty: data.boosty,
        })

        commit('SET_BLOCK_STATUS', {
          isBlocked: !!data.unavailable_due_to,
          blockReason: data.unavailable_due_to,
        })

        commit('SET_CREATOR_DESCRIPTION', data.note)

        if (data.terms_of_service) {
          const {
            streamers_url,
            viewers_url,
            show_company_disclaimer,
          } = data.terms_of_service

          commit('SET_TERMS_OF_SERVICE', {
            streamersUrl: streamers_url,
            viewersUrl: viewers_url,
            showCompanyDisclaimer: !!show_company_disclaimer,
          })
        }

        commit('payment/SET_COMMISSION_COVERING_ALLOWED', !!data.allow_commission_covering, { root: true })

        commit('SET_CREATOR_AWARDS', data.badges.map(badge => ({
          title: badge.text,
          text: badge.sub_text,
          type: badge.type,
          image: badge.url,
        })))

        commit('SET_CREATOR_LINKS', data.user_links)

        commit('application/SET_THEME_COLORS', {
          themeColor: data.highlight_color,
          themeTextColor: data.highlight_text_color,
        }, { root: true })

        commit('donation/SET_DONATION_SETTINGS', {
          minimalAmount: data.text_donations.min_amounts,
        }, { root: true })

        // commit('donation/SET_AMOUNTS', {
        //   DONATION_AMOUNTS data.text_donations.min_amounts
        //   SYNTHESIS_AMOUNTS data.text_to_speech.min_amounts
        //   AUDIO_AMOUNTS data.voice_donations.min_amounts
        // }, { root: true })

        commit('message/recorder/SET_RECORDER_SETTINGS', {
          maxAudioDuration: data.voice_donations.max_length,
        }, { root: true })

        // Refactored
        dispatch('message/message/setup', {
          textDonations: data.text_donations,
          textToSpeech: data.text_to_speech,
          voiceDonations: data.voice_donations,
        }, { root: true })

        // Widgets
        dispatch('widgets/widgets/setup', {
          stickers: data.stickers,
          media: data.media,
          donationGoals: data.donation_goals,
          poll: data.poll,
          bft: data.bft_settings,
          party: data.third_party_actions,
        }, { root: true })

        dispatch('donation/initDonationAmount', null, { root: true })

        dispatch('currencies/setup', {
          preferredCurrency: data.preferred_currency,
        }, { root: true })
      })
  },
}
