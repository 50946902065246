import axios from 'axios'

const merchantInfo = {
  domainName: 'www.donationalerts.com',
  displayName: 'DonationAlerts',
  merchantIdentifier: 'merchant.om.my.donationalerts',
}

const getPaymentDataRequest = (currencyCode, amount) => {
  return {
    supportedNetworks: ['masterCard', 'visa'],
    merchantCapabilities: ['supports3DS'],
    countryCode: 'RU',
    total: {
      label: merchantInfo.domainName,
      amount,
    },
    currencyCode,
  }
}

class ApplePay {
  validateMerchant(validationURL, isDobro = false) {
    return axios
      .post('/api/v1/payin/validate_apay_session', {
        validation_url: validationURL,
        apay_payload: merchantInfo,
        isDobro: Number(isDobro),
      })
      .then(({ data }) => data)
  }

  pay(currencyCode, amount, isDobro = false) {
    if (this.available) {
      const session = new window.ApplePaySession(1, getPaymentDataRequest(currencyCode, amount))

      session.begin()

      session.onvalidatemerchant = ({ validationURL }) => {
        this.validateMerchant(validationURL, isDobro)
          .then(merchantSession => {
            console.log('[Apple Pay]', 'Merchant is valid', merchantSession)
            session.completeMerchantValidation(merchantSession)
          })
          .catch(e => {
            console.log('[Apple Pay]', 'Merchant is NOT valid', e)
            session.abort()
          })
      }

      return new Promise((resolve, reject) => {
        session.onpaymentauthorized = ({ payment }) => {
          console.log('[Apple Pay]', 'onpaymentauthorized', payment)

          session.completePayment(session.STATUS_SUCCESS)

          resolve(window.btoa(JSON.stringify(payment.token.paymentData)))
        }

        session.oncancel = () => {
          console.log('[Apple Pay]', 'oncancel')

          reject()
        }
      })
    }

    return Promise.reject()
  }

  init() {
    if (window.ApplePaySession) {
      console.log('[Apple Pay]', 'Session available')

      if (ApplePaySession.canMakePaymentsWithActiveCard(merchantInfo.merchantIdentifier)) {
        this.available = true
        console.log('[Apple Pay]', 'Can make payments')
      } else {
        this.available = false
        console.log('[Apple Pay]', 'Can NOT make payments')
      }
    } else {
      this.available = false
    }
  }

  constructor() {
    this.available = false

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.onload = () => {
      this.init()
    }
    script.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'
    document.head.appendChild(script)
  }
}

export default new ApplePay()
