<style lang="scss" scoped>
  .base-radio {
    position: relative;
    display: flex;
    min-height: 1.375rem;
    padding-left: 1.75rem;
    margin-bottom: 0;
    vertical-align: top;
    cursor: pointer;

    input {
      opacity: 0;
      position: fixed;
      top: -100px;
      left: -100px;
      z-index: -5;
    }

    &:hover .radio-check {
      &:before {
        background-color: #b9b9b9;
      }
    }

    &.checked, &:hover {
      color: var(--theme-link-color);
    }

    &.checked .radio-check {
      &:after {
        background-color: white;
      }

      &:before {
        @include theme-gradient;
        border-color: transparent;
      }
    }

    .radio-check {
      &:after, &:before {
        content: "";
        position: absolute;
        display: block;
        border-radius: 100%;
      }

      &:before {
        top: 3px;
        left: 0;
        width: 16px;
        height: 16px;
        background-color: #e3e3e3;
      }

      &:after {
        top: 8px;
        left: 5px;
        width: 6px;
        height: 6px;
      }
    }

    .radio-content {
      word-break: break-word;
    }
  }
</style>

<template>
  <label class="base-radio radio" :class="{checked}">
    <input
      type="radio"
      :checked="checked"
      @change="onChange">
    <span class="radio-check"/>
    <span class="radio-content"><slot/></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    value: {
      default: null,
    },
    modelValue: {
      default: null,
    },
  },
  computed: {
    checked() {
      return this.value === this.modelValue
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.value)
    },
  },
}
</script>
