<style lang="scss" scoped>
  .speech-synthesis-menu {
    user-select: none;
    display: flex;

    &:hover {
      border-color: $line-hover-color;
    }

    .menu-button {
      height: 30px;
      display: flex;
      align-items: center;
      line-height: 20px;
      padding-left: 10px;
      padding-right: 5px;
      border-radius: var(--control-border-radius);
      border: 1px solid $line-color;
      cursor: pointer;
      min-width: 0;

      .icon {
        flex-shrink: 0;
        font-size: 18px;
      }

      .button-text {
        flex-shrink: 0;
        margin-left: 5px;
        color: $warm-grey;
      }

      .synthesis-blocked {
        @include margin-x(5px);
      }

      .synthesis-name {
        min-width: 0;
        flex-shrink: 1;
        @include margin-x(5px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .synthesis-cost {
        flex-shrink: 0;
        border-radius: calc(var(--control-border-radius) - 2px);
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--theme-color);
        background-color: rgba(var(--theme-color-rgb), .2);
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
      }
    }

    &.unavailable {
      .menu-button {
        cursor: not-allowed;
        opacity: .7;
      }
    }

    .speech-synthesis-popover {
      position: absolute;
      left: 15px;
      bottom: calc(100% + 5px);
      z-index: 10;
      width: 320px;
      background-color: white;
      border: 1px solid $line-color;
      border-radius: var(--control-border-radius);
      box-shadow: 0 5px 20px rgba(black, .05);
      display: flex;
      flex-direction: column;

      @include media(max, sm) {
        left: -20px;
        //right: -15px;
        //width: auto;
        //font-size: 13px;
      }

      &:hover {
        border-color: $line-hover-color;
      }

      .languages-list {
        flex-shrink: 0;
        overflow: hidden;
        position: relative;


        &:after {
          content: '';
          display: block;
          border-bottom: 1px solid $line-color;
          @include position(absolute, auto, 0, 0, 0);
          z-index: 1;
        }

        .languages-list-wrapper {
          position: relative;
          margin-bottom: -20px;
          display: flex;
          white-space: nowrap;
          overflow: auto;
          cursor: grab;
          padding-bottom: 20px;
          padding-left: 15px;
          z-index: 2;

          &:after {
            content: '';
            display: block;
            width: 10px;
            flex: 0 0 auto;
          }

          .language-list-item {
            border-bottom: 3px solid transparent;
            cursor: pointer;
            text-align: center;
            white-space: nowrap;
            @include padding-y(8px);

            &.selected {
              border-color: var(--theme-color);
            }

            &:hover:not(.selected) {
              border-color: rgba(var(--theme-color-rgb), .2)
            }
          }

          .language-list-item + .language-list-item {
            margin-left: 10px;
          }
        }
      }

      .synthesis-list {
        max-height: 150px;
        flex-grow: 1;
        overflow-y: scroll;
        margin-right: 3px;
        @include padding-y(5px);

        .synthesis-list-item {
          border-left: 3px solid transparent;
          padding-left: 12px;
          padding-right: 5px;
          cursor: pointer;
          display: flex;
          align-items: center;
          @include padding-y(3px);

          &.selected {
            border-color: var(--theme-color);
          }

          &:hover:not(.selected) {
            border-color: rgba(var(--theme-color-rgb), .2)
          }

          &.selected {
            color: var(--theme-color);
          }

          .synthesis-play-button {
            border-radius: 100%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(var(--theme-color-rgb), .2);
            font-size: 10px;
            padding-left: 2px;
            flex-shrink: 0;
          }

          .synthesis-play-button + .synthesis-name {
            margin-left: 8px;
          }

          .synthesis-cost {
            margin-left: auto;
            border-radius: calc(var(--control-border-radius) - 2px);
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--theme-color);
            background-color: rgba(var(--theme-color-rgb), .2);
            padding: 2px 5px;
            font-size: 12px;
            font-weight: 600;
            flex-shrink: 0;
            white-space: nowrap;
          }

          &.ny {
            background-color: #fffde0;

            .synthesis-cost {
              background-color: #73bb62;
              color: white;
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div
    class="speech-synthesis-menu"
    :class="speechSynthesisMenuClasses"
    v-click-outside="hideSpeechSynthesis">
    <div
      class="menu-button"
      @click="toggleSpeechSynthesis">
      <icon name="speech-synthesis" theme gradient/>
      <template v-if="speechSynthesisAvailable">
        <div class="button-text">{{ $t('message.speechSynthesis') }}</div>
        <div class="synthesis-name">{{ currentVoice.nameFormatted }}</div>
        <div v-if="currentVoice.cost" class="synthesis-cost">
          {{ currentVoice.cost }}
        </div>
      </template>
      <template v-else>
        <div class="synthesis-blocked">
          {{ $t('message.speechSynthesisUnavailable') }}
        </div>
      </template>
    </div>
    <transition name="fade-up">
      <div v-show="speechSynthesisOpened" class="speech-synthesis-popover">
        <div class="languages-list">
          <div ref="slider" class="languages-list-wrapper hidden-scroll">
            <div
              v-for="voiceLocale in voicesLocales"
              :key="voiceLocale.code"
              class="language-list-item"
              :class="{selected: voiceLocale.code === synthesisVoiceLocale}"
              @click="synthesisVoiceLocale = voiceLocale.code">{{ voiceLocale.name }}
            </div>
          </div>
        </div>
        <div class="synthesis-list">
          <div
            v-for="voice in currentLocaleVoices"
            class="synthesis-list-item"
            :class="{
              selected: voice.code === speechSynthesisVoice,
              ny: voice.name === 'Дед Мороз',
            }"
            @click="selectVoice(voice.code)">
            <div
              v-if="voice.sound"
              class="synthesis-play-button"
              @mouseenter="playSound(voice.sound)"
              @mouseleave="stopSound(voice.sound)">
              <icon name="play" theme/>
            </div>
            <div class="synthesis-name">
              <template v-if="voice.name === 'Дед Мороз'">🎄&nbsp;</template>
              {{ voice.name }}
            </div>
            <div v-if="voice.cost" class="synthesis-cost">{{ voice.cost }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Hammer from 'hammerjs'
import { createAudio, playAudio, stopAudio } from '@utils/audio'
import { localesDataList } from '@src/localization/config'

export default {
  name: 'SpeechSynthesisMenu',
  data() {
    return {
      speechSynthesisOpened: false,

      synthesisVoiceLocale: null,

      hammer: null,
    }
  },
  computed: {
    ...mapGetters('currencies', [
      'getCurrentCurrency',
      'getAmountWithCurrency',
    ]),
    ...mapGetters('message/message', [
      'speechSynthesisVoicesList',
    ]),
    ...mapState('message/message', [
      'speechSynthesisRandomAllowed',
    ]),
    ...mapFields('message/message', [
      'speechSynthesisVoice',
    ]),
    ...mapGetters('donation', [
      'missingAmounts',
    ]),

    speechSynthesisAvailable() {
      return !this.missingAmounts.speechSynthesis
    },

    speechSynthesisMenuClasses() {
      return {
        unavailable: !this.speechSynthesisAvailable,
      }
    },

    voices() {
      return this.speechSynthesisVoicesList
        .map(voice => {
          const price = voice.prices ? voice.prices[this.getCurrentCurrency.code] : null

          return {
            ...voice,
            cost: price ? this.getAmountWithCurrency(price) : null,
            sound: voice.audioUrl ? createAudio(voice.audioUrl) : null,
          }
        })
    },

    voicesLocales() {
      const availableLocales = _.uniq(_.map(this.voices, 'localeCode'))

      return localesDataList.filter(locale => availableLocales.includes(locale.code))
    },

    indexedVoices() {
      return _.keyBy(this.voices, 'code')
    },

    voicesGroupedByLocale() {
      return _.groupBy(this.voices, 'localeCode')
    },

    currentLocaleVoices() {
      return this.voicesGroupedByLocale[this.synthesisVoiceLocale]
    },

    currentVoice() {
      return this.indexedVoices[this.speechSynthesisVoice]
    },
  },
  methods: {
    toggleSpeechSynthesis() {
      if (!this.speechSynthesisAvailable) {
        this.speechSynthesisOpened = false

        return
      }

      this.speechSynthesisOpened = !this.speechSynthesisOpened
    },

    hideSpeechSynthesis() {
      this.speechSynthesisOpened = false
    },

    selectVoice(code) {
      this.speechSynthesisVoice = code
      this.hideSpeechSynthesis()
    },

    playSound(sound) {
      playAudio(sound)
    },

    stopSound(sound) {
      stopAudio(sound)
    },
  },
  mounted() {
    const { slider } = this.$refs
    this.hammer = new Hammer(slider)
    this.hammer.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL })

    let pos = 0
    this.hammer.on('panstart', () => {
      pos = slider.scrollLeft
    })
    this.hammer.on('panleft panright', ev => {
      slider.scrollLeft = pos - ev.deltaX
    })
  },
  beforeDestroy() {
    this.hammer.destroy()
  },
  watch: {
    speechSynthesisVoice: {
      handler(newVoice, oldVoice) {
        const [voiceLocale] = newVoice.split(':')

        this.synthesisVoiceLocale = voiceLocale

        if (oldVoice && newVoice !== oldVoice) {
          this.$tmr.goal('input_voiceActor_changed')

          const [oldVoiceLocale] = oldVoice.split(':')

          if (oldVoiceLocale !== voiceLocale) {
            this.$tmr.goal('input_voiceLanguage_changed')
          }
        }
      },
      immediate: true,
    },
  },
}
</script>
