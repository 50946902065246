import Vue from 'vue'

export const state = {
  widgetBftEnabled: false,
  bftIsActive: false,

  /// NEW
  game: {},
  categories: {},
  actions: {},

  selectedActions: {},
}

export const getters = {
  widgetBftEnabled: ({ widgetBftEnabled }) => widgetBftEnabled,

  /// NEW
  gameStarted: ({ actions }) => !!Object.keys(actions).length,

  getAction: ({ actions }, getters, rootState) => id => {
    const action = actions[id]

    return {
      ...action,
      amount: action.amounts[rootState['currencies'].currentCurrency],
    }
  },

  getCategory: ({ categories }, getters) => id => {
    const category = categories[id]

    return {
      ...category,
      actions: category.actions.map(actionId => getters.getAction(actionId)),
    }
  },

  getCategoriesList: ({ categories }, getters) =>
    Object.keys(categories).map(categoryId => getters.getCategory(categoryId)),

  getSelectedActionsList: ({ selectedActions, game }, getters) =>
    Object.keys(selectedActions)
      .filter(actionId => !!selectedActions[actionId])
      .map(actionId => ({
        ...getters.getAction(actionId),
        count: selectedActions[actionId],
        gameId: game.id,
      })),

  selectedActionsCost: ({ selectedActions }, getters) =>
    _.round(
      getters.getSelectedActionsList
        .reduce((acc, action) => acc + (action.amount * selectedActions[action.id]), 0),
      2),
}

export const mutations = {
  SET_WIDGET_SETTINGS(state, { widgetBftEnabled }) {
    state.widgetBftEnabled = widgetBftEnabled
  },

  SET_GAME(state, game) {
    state.game = game
  },

  SET_IS_ACTIVE(state, isActive) {
    state.bftIsActive = isActive
  },

  SET_ACTIONS(state, actions) {
    state.actions = _.keyBy(actions, 'id')
  },

  SET_CATEGORIES(state, categories) {
    state.categories = _.keyBy(categories, 'id')
  },

  SET_SELECTED_ACTION_COUNT(state, { id, count }) {
    Vue.set(state.selectedActions, id, count)
  },
}

export const actions = {
  setup({ state, commit, dispatch }, { bft }) {
    commit('SET_WIDGET_SETTINGS', {
      widgetBftEnabled: !!bft.is_enabled,
    })

    if (state.widgetBftEnabled) {
      dispatch('fetchCreatorActions')
    }
  },

  fetchCreatorActions({ commit, rootGetters }) {
    return api
      .get('/bft', {
        user_id: rootGetters['creator/getCreatorId'],
      })
      .then(({ data }) => {
        if (!!data.is_active) {
          commit('SET_IS_ACTIVE', true)

          commit('SET_GAME', data.game)

          const categories = []
          const actions = []

          data.actions.forEach(category => {
            const categoryActions = []

            category.actions.forEach(action => {
              actions.push({
                id: action.id,
                categoryId: category.id,
                title: action.title,
                amounts: action.amounts,
              })

              categoryActions.push(action.id)
            })

            categories.push({
              id: category.id,
              title: category.title,
              actions: categoryActions,
            })
          })

          commit('SET_CATEGORIES', categories)
          commit('SET_ACTIONS', actions)
        }
      })
  },
}
