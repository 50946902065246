<style scoped lang="scss">
  .service-tabs {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;

    .service-tab {
      display: flex;
      padding: 10px 14px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid #E2E2E2;
      background: #fff;
      cursor: pointer;
      text-decoration: none;
      transition: $control-transition;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: #838383;
      text-transform: uppercase;
      white-space: nowrap;

      .service-tab-image {
        img {
          width: 20px;
          height: 20px;
        }
      }

      &.router-link-active {
        text-decoration: none;
        border-radius: 8px;
        border-color: var(--theme-color);
        background: rgba(var(--theme-color-rgb), .05);
        color: #191919;
      }

      &:hover {
        text-decoration: none;
        border-color: var(--theme-color);
      }
    }
  }
</style>

<template>
  <div class="service-tabs">
    <router-link
      v-for="(service, serviceIndex) in services"
      :key="serviceIndex"
      :to="service.route"
      exact
      class="service-tab">
      <div class="service-tab-image">
        <img :src="service.imageUrl"/>
      </div>
      <div class="service-tab-name">
        {{ service.name }}
      </div>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'ServiceTabs',
  computed: {
    ...mapGetters('partners', ['getPartnersList']),

    services() {
      const logos = {
        'chaos_tricks': '/images/partners/chaos-tricks-logo.png',
        'meme_alerts': '/images/partners/meme-alerts-logo.png',
        'test_partner': '/images/partners/test-partner-logo.png',
        'stream_games': '/images/partners/stream-games.svg',
        'nevilly': '/images/partners/nevilly.svg',
        'facemorph': '/images/partners/facemorph.svg',
      }

      return [
        {
          image: '/images/services/donationalerts.svg',
          name: 'Донат',
          route: {
            name: 'service-donation-page',
          },
        },
        ...this.getPartnersList.map(partner => {
          return {
            image: logos[partner.code],
            name: partner.title,
            route: {
              name: 'service-partner-page',
              params: {
                partnerCode: partner.code
              }
            },
          }
        }),
        // {
        //   image: '/images/services/donationalerts.svg',
        //   name: 'Кейсы',
        //   route: {
        //     name: 'service-products-page',
        //   },
        // },
      ].map(service => ({
        ...service,
        imageUrl: this.$asAppAsset(service.image),
      }))
    },
  },
}
</script>
