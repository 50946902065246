<style scoped lang="scss">
  .creator-page {
    flex: 1 0 auto;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--theme-background-color);
    background-repeat: no-repeat;
    background-position: center top;

    .page-wrapper {
      @include margin-x(auto);
    }

    @include media(max, sm) {
      padding-top: 0;
    }

    .page-description {
      margin-top: 20px;

      @include media(max, sm) {
        margin-top: 10px;
      }
    }

    .page-content {
      margin-top: 20px;

      @include media(max, sm) {
        margin-top: 10px;
      }
    }

    .sub-page {
      margin-top: 25px;
    }

    .loading-container {
      @include position-all(absolute, 0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;

      .icon {
        position: absolute;
        font-size: 50px;
      }

      .loader {
        position: absolute;
      }
    }

    .page-footer {
      margin-top: 30px;

      @include media(max, md) {
        margin-top: 20px;
      }
    }
  }
</style>

<template>
  <div class="creator-page" :style="pageStyles">
    <div v-if="!pageReady" class="loading-container">
      <loader size="xl" system gradient/>
      <icon name="social-donationalerts" system gradient class="animated pulse infinite"/>
    </div>
    <container v-else>
      <sub-page-layout v-if="isBlocked" :viewer="false">
        <block-reason/>
      </sub-page-layout>
      <div v-else class="page-wrapper">
        <page-header/>
        <page-description v-if="displayDescription"/>
        <router-view class="sub-page"/>
      </div>
      <page-footer/>
    </container>

    <login-modal/>
  </div>
</template>

<script>

import PageLayout from '@layouts/PageLayout'
import PageHeader from '@components/CreatorPage/PageHeader'
import PageDescription from '@components/CreatorPage/PageDescription'
import LoginModal from '@components/CreatorPage/LoginModal'
import BlockReason from '@components/CreatorPage/BlockReason'
import SubPageLayout from '@layouts/SubPageLayout'
import PageFooter from '@components/CreatorPage/PageFooter'

export default {
  name: 'CreatorPage',
  components: {
    PageFooter,
    SubPageLayout,
    BlockReason,
    PageDescription,
    PageHeader,
    LoginModal,
    PageLayout,
  },
  props: {
    slug: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      pageReady: false,
    }
  },
  computed: {
    ...mapGetters('creator', ['getCreatorId']),
    ...mapState('creator', ['creatorPageSettings', 'isBlocked']),

    pageStyles() {
      if (this.isBlocked) {
        return {}
      }

      const styles = {}

      const {
        pageBackgroundColor,
        pageBackgroundImage,
        pageBackgroundRepeat,
      } = this.creatorPageSettings

      if (pageBackgroundColor) {
        styles.backgroundColor = pageBackgroundColor
      }

      if (pageBackgroundImage) {
        styles.backgroundImage = `url("${pageBackgroundImage}")`

        if (pageBackgroundRepeat) {
          styles.backgroundRepeat = 'repeat'
        }
      }

      return styles
    },

    displayDescription() {
      return !this.$route.meta.hideDescription
    },
  },
  methods: {
    ...mapActions('creator', ['fetchCreatorPageSettings']),
    ...mapActions('partners', ['fetchCreatorPartners']),
    ...mapActions('currencies', ['fetchCurrencies']),
    ...mapActions('viewer', ['fetchCurrentUser']),
    ...mapActions('products', ['fetchProducts']),
  },
  async created() {
    await this.fetchCurrentUser()

    await Promise.all([
      this.fetchCreatorPageSettings(this.slug),
      this.fetchCreatorPartners(this.slug),
    ])

    if (this.isBlocked) {
      if (this.$route.name !== 'service-donation-page') {
        return this.$router.replace({ name: 'service-donation-page' })
      }
    } else {
      await Promise.all([
        this.fetchCurrencies(),
        this.fetchProducts(),
      ])

      this.$tmr.setGlobalLabel(this.getCreatorId)
    }

    this.pageReady = true
  },
}
</script>
