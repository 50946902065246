import Vue from 'vue'
import VueI18n from 'vue-i18n'
import languages from './languages'
import axios from 'axios'
import storage from '@utils/storage'

const STORAGE_SAVED_LOCALE = 'da2.donations.locale'
const STORAGE_LOCALE_MESSAGE = 'da2.donations.localeMessage'

Vue.use(VueI18n)

const locales = Object.keys(languages)

const localesData = locales.reduce((acc, localeCode, index) => {
  acc[localeCode] = {
    name: languages[localeCode],
    code: localeCode,
    order: index,
  }

  return acc
}, {})
const localesDataList = _.sortBy(Object.values(localesData), 'order')
const defaultLocale = 'en_US'
const fallbackLocales = ['en_US', 'ru_RU']

const _cachedLocales = {}

const fetchLocale = locale => {
  if (_cachedLocales[locale]) {
    return Promise.resolve()
  }

  _cachedLocales[locale] = true

  return axios.get(`${process.env.VUE_APP_LOCALES_PATH}/${locale}.json?t=${+Date.now()}`)
    .then(({ data }) => {
      storage.setItem(`${STORAGE_LOCALE_MESSAGE}.${locale}`, data)
      i18n.setLocaleMessage(locale, data)
    })
}

const setLocale = (locale, withFetch = true) => {
  if (withFetch) {
    fetchLocale(locale)
  }

  i18n.locale = locale

  const lang = locale.split('_')[0]
  document.documentElement.setAttribute('lang', lang)
}

const saveLocale = locale => {
  storage.setItem(STORAGE_SAVED_LOCALE, locale)
}

const detectUserLocale = () => {
  let userLocale = window.navigator.language.replace('-', '_')

  if (userLocale && !languages[userLocale]) {
    const [userLanguage] = userLocale.split('_')

    Object.keys(languages).forEach(language => {
      if (language.split('_')[0] === userLanguage) {
        userLocale = language
      }
    })
  }

  return userLocale
}

const i18n = new VueI18n({
  fallbackLocale: fallbackLocales,
  locale: defaultLocale,
  silentFallbackWarn: PRODUCTION_MODE,
  pluralizationRules: {
    'ru_RU': function (n) {
      return (n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
    },
  },
})

const savedLocale = storage.getItem(STORAGE_SAVED_LOCALE)

if (savedLocale) {
  if (!fallbackLocales.includes(savedLocale)) {
    fetchLocale(savedLocale)
  }

  setLocale(savedLocale, false)
} else {
  const userLocale = detectUserLocale()

  if (userLocale) {
    setLocale(userLocale)
  }
}

Object.keys(languages).forEach(locale => {
  i18n.setLocaleMessage(locale, storage.getItem(`${STORAGE_LOCALE_MESSAGE}.${locale}`) ?? {})
})

fallbackLocales.forEach(fetchLocale)

// TODO: перевод на реальный SPA
// session.onReady()
//   .then(() => {
//     setLocale(session.getUserLanguage())
//   })

export { i18n, locales, localesData, localesDataList, defaultLocale, setLocale, detectUserLocale, saveLocale, savedLocale }
