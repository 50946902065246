<style scoped lang="scss">
  .widget-media {
    .media-cost {
      font-size: 13px;
      color: $warm-grey;
    }

    .media-cost + .form-group {
      margin-top: 20px;
    }

    .media-helper {
      margin-top: 12px;
      font-size: 12px;
      color: $warm-grey-two;
    }

    .media-video {
      margin-top: 20px;
      display: flex;

      .video-image {
        width: 180px;
        position: relative;

        .video-duration-text {
          @include position(absolute, auto, 5px, 5px);
          background: rgba(black, .8);
          @include padding-x(5px);
          @include padding-y(2px);
          border-radius: 3px;
          font-size: 12px;
          color: white;
        }

        .video-delete-button {
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include position(absolute, 5px, 5px);
          width: 2em;
          height: 2em;
          border-radius: 100%;
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
          background-color: white;
          color: rgba(black, .5);
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 3px;
        }
      }

      .video-description {
        padding-top: 7px;
        margin-left: 16px;
        display: flex;
        flex-direction: column;

        .video-title {
          font-size: 14px;
          font-weight: 600;
          color: $black-five;
        }

        .video-controls {
          margin-top: auto;
          display: flex;
          align-items: center;
          white-space: nowrap;

          .base-input {
            width: 100px;
            margin-left: 10px;
          }
        }
      }
    }

    .media-video + .media-cost {
      margin-top: 20px;
    }
  }
</style>

<template>
  <div class="widget-media">
    <div v-if="missingAmounts.media" class="media-cost">
      <amount-link
        :template="$t('widgets.media.mediaAmountTemplate')"
        :amount="missingAmounts.media" additive/>
    </div>
    <form-group
      v-show="!video"
      :state="!videoError"
      :invalid-feedback="videoError">
      <advanced-input
        icon-left="link"
        v-model="url"
        :placeholder="$t('widgets.media.mediaLinkPlaceholder')"
        :disabled="missingAmounts.media > 0"/>
    </form-group>
    <div v-if="video" class="media-video">
      <div class="video-image">
        <img :src="video.thumbnail"/>
        <div class="video-duration-text">{{ durationText }}</div>
        <div class="video-delete-button" @click="deleteVideo">
          <icon name="cross"/>
        </div>
      </div>
      <div class="video-description">
        <div class="video-title">{{ video.title }}</div>
        <div class="video-controls">
          {{ $t('widgets.media.startFrom') }}
          <base-input
            v-model="startWith"
            @change="onStartWithChange"
            placeholder="00:00"/>
        </div>
      </div>
    </div>
    <div v-else class="media-helper">
      {{ $t('widgets.media.mediaExample', { example: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ' }) }}
    </div>
  </div>
</template>

<script>

import { leadingZero } from '@utils/utils'

export default {
  name: 'WidgetMedia',
  data() {
    return {
      url: null,

      startWith: '00:00',
    }
  },
  computed: {
    ...mapGetters('donation', ['missingAmounts']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    ...mapGetters('widgets/media', ['videoDuration']),
    ...mapState('widgets/media', ['video', 'videoStartTime', 'videoError']),

    startTime: {
      get() {
        return this.videoStartTime
      },
      set(value) {
        this.setVideoStartTime(value)
      },
    },

    durationText() {
      const {
        h,
        m,
        s,
      } = this.video.duration

      const text = [
        leadingZero(s),
        leadingZero(m),
      ]

      if (h) {
        text.push(leadingZero(h))
      }

      return text.reverse().join(':')
    },
  },
  methods: {
    ...mapMutations('widgets/media', {
      setVideoData: 'SET_VIDEO_DATA',
      setVideoStartTime: 'SET_VIDEO_START_TIME',
    }),
    ...mapActions('widgets/media', ['fetchVideoData']),

    deleteVideo() {
      this.url = null
      this.setVideoData(null)
    },

    onStartWithChange() {
      const startWith = (this.startWith).toString()

      if (!startWith.includes(':')) {
        return this.startWith = this.calculateTime(Number(startWith))
      } else {
        const startWith = this.startWith.split(':')

        const s = Number(startWith.pop() ?? 0)
        const m = Number(startWith.pop() ?? 0)
        const h = Number(startWith.pop() ?? 0)

        let start = s + m * 60 + h * 60 * 60

        return this.startWith = this.calculateTime(start)
      }
    },

    calculateTime(time) {
      if (time >= this.videoDuration) {
        time = this.videoDuration - 1
      }

      this.startTime = time

      const s = time % 60
      let m = (time - s) / 60
      let h = 0

      if (m > 59) {
        h = (m - (m % 60)) / 60
        m = m - h * 60
      }

      const startWith = [
        leadingZero(m),
        leadingZero(s),
      ]

      if (h) {
        startWith.unshift(leadingZero(h))
      }

      return startWith.join(':')
    },
  },
  watch: {
    url(value) {
      if (value) {
        this.fetchVideoData(value)
      }
    },

    startTime(value) {
      this.startWith = this.calculateTime(Number(value))
    },

    startWith(newValue, oldValue) {
      if (newValue === oldValue) {
        return
      }

      const strValue = (newValue).toString()

      const defaultRegExp = /[^\d:]/g

      if (defaultRegExp.test(strValue)) {
        return this.startWith = oldValue
      }

      const numericRegExp = /[^\d]/g

      if (numericRegExp.test(strValue)) {
        const timeRegExp = /^[\d]{1,2}:?$|^[\d]{1,2}:[\d]{1,2}:?$|^[\d]{1,2}:[\d]{1,2}:[\d]{1,2}$/g

        if (!timeRegExp.test(strValue)) {
          return this.startWith = oldValue
        }
      } else {
        return this.startWith = Number(newValue)
      }
    },
  },
}
</script>
