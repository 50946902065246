export const state = {
  goals: {},

  selectedGoalId: null,
}

export const getters = {
  getField,

  goalsList: state => Object.values(state.goals),

  goalSelected: state => !!state.selectedGoalId,

  widgetGoalsEnabled: (state, getters) => !!getters.goalsList.length,
}

export const mutations = {
  updateField,

  SET_GOALS(state, goals) {
    state.goals = _.keyBy(goals, 'id')
  },

  SET_SELECTED_GOAL_ID(state, id) {
    state.selectedGoalId = id
  },
}

export const actions = {
  setup({ commit }, { donationGoals = [] } = {}) {
    const goals = donationGoals.map(goal => {
      if (goal.is_default) {
        commit('SET_SELECTED_GOAL_ID', goal.id)
      }

      return {
        id: goal.id,
        title: goal.title,
        raisedAmount: Number(goal.raised_amount),
        goalAmount: Number(goal.goal_amount),
        currency: goal.currency,
      }
    })

    commit('SET_GOALS', goals)
  },
}
