<style scoped lang="scss">
  .donation-form {
    .message-section {
      margin-top: 30px;

      @include media(max, sm) {
        margin-top: 20px;
      }
    }

    .widgets-section {
      margin-top: 30px;
    }

    .controls-group {
      margin-top: 30px;

      .helper-box {
        margin-top: 15px;
      }
    }
  }

  #da-partner-frame {
    width: 100%;
    border: none;
  }
</style>

<template>
  <div class="donation-form">
    <amount-section/>
    <message-section/>
    <widgets-section v-if="displayWidgets"/>
    <div class="group controls-group">
      <btn
        icon-left="gift"
        variant="primary"
        size="lg"
        :is-loading="loadingState.send"
        @click="sendDonation">{{ getDonationButtonText }}
      </btn>
      <helper-box>
        <terms-of-service/>
      </helper-box>
    </div>
    <validation-modal @continue="sendDonation"/>
  </div>
</template>

<script>

import AmountSection from './AmountSection/AmountSection'
import MessageSection from './MessageSection/MessageSection'
import WidgetsSection from './WidgetsSection/WidgetsSection'
import ValidationModal from './ValidationModal'
import TermsOfService from './TermsOfService'

import { INVOICE_TYPE } from '@state/modules/invoice'

export default {
  name: 'DonationForm',
  components: {
    TermsOfService,
    ValidationModal,
    AmountSection,
    MessageSection,
    WidgetsSection,
  },
  data() {
    return {
      loadingState: {
        send: false,
      },
    }
  },
  mounted() {
    window.addEventListener('message', this.receivePartnerMessage)

    this.SET_INVOICE_TYPE(INVOICE_TYPE.DONATION)
  },
  computed: {
    ...mapGetters('widgets/widgets', ['displayWidgets']),
    ...mapGetters('donation', ['missingAmountsActual']),
    ...mapGetters('creator', ['getDonationButtonText', 'getCreatorId']),
    ...mapFields('donation', ['validationModalOpened']),

    donationIsValid() {
      let isValid = true

      Object.keys(this.missingAmountsActual).forEach(key => {
        if (this.missingAmountsActual[key]) {
          isValid = false
        }
      })

      return isValid
    },
  },
  methods: {
    ...mapActions('invoice', ['validateInvoicePayload']),
    ...mapActions('payment', ['fetchPaymentMethods']),
    ...mapMutations('invoice', ['SET_INVOICE_TYPE']),

    sendDonation() {
      this.$ga.action({
        category: 'DonationPage',
        action: 'btn_donate_send',
      })

      this.$ga.action({
        category: 'AB_DonationPage',
        action: 'btn_donate_send',
      })

      this.$tmr.event({
        category: 'AB_DonationPage',
        action: 'new_action_btn_donate_send',
        label: this.getCreatorId,
      })

      if (!this.donationIsValid) {
        return this.validationModalOpened = true
      }

      this.loadingState.send = true

      this.SET_INVOICE_TYPE(INVOICE_TYPE.DONATION)

      this.validateInvoicePayload()
        .then(() => {
          return this.fetchPaymentMethods()
            .then(() => {
              this.loadingState.send = false

              this.$router.push({
                name: 'creator-payment-page',
              })
            })
        })
        .finally(() => {
          this.loadingState.send = false
        })
    },
  },

}
</script>
