import Vue from 'vue'
import App from './App'

import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

// Meta-tags
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  keyName: 'page',
})

// Analytics
import { appendAnalytics } from '@src/metrics'
import GAPlugin from './plugins/ga'
import TMRPlugin from './plugins/tmr'

Vue.use(GAPlugin)
Vue.use(TMRPlugin)

// Global components & directive
import './registerComponents'
import './directives'

// Setup
Vue.config.productionTip = false

Vue.mixin({
  data() {
    return {
      loadingState: {
        initial: true,
      },
      componentMounted: false,
    }
  },
  computed: {
    ...mapState('application', {
      $timestamp: 'timestamp',
      $appWidth: 'appWidth',
      $scrollTop: 'scrollTop',
    }),
    ...mapGetters('application', {
      $appSize: 'appSize',
      $appIsSM: 'appIsSM',
      $appIsMD: 'appIsMD',
      $appIsLG: 'appIsLG',
    }),
  },
  methods: {
    $asAppAsset(url) {
      return `${process.env.VUE_APP_ASSETS_PATH}${url}`
    },
    $tooltip(content, placement = 'top', show = false, trigger) {
      return {
        content,
        placement,
        show,
        trigger,
      }
    },
  },
  // mounted() {
  //   this.componentMounted = true
  // },
})

import router from '@router'
import store from '@state/store'
import { i18n } from '@src/localization/config'

// Front ENV & Sentry setup
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import frontConfig from '@services/frontConfig'

frontConfig
  .fetchConfig()
  .then(() => {
    appendAnalytics()

    const dsn = frontConfig.getSentryDsn(process.env.VUE_APP_NAME)

    if (PRODUCTION_MODE) {
      if (dsn) {
        Sentry.init({
          integrations: [new Integrations.Vue({ Vue, attachProps: true })],
          release: `vue-${process.env.VUE_APP_NAME}@${process.env.VUE_APP_PACKAGE_VERSION}`,
          environment: process.env.NODE_ENV,
          dsn,
          autoSessionTracking: false,
        })
      }
    }

    new Vue({
      router,
      store,
      i18n,
      pinia,
      render: h => h(App),
    }).$mount('#donation-app')
  })


