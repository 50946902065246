import AppEntry from '@pages/AppEntry'

import CreatorPage from '@pages/CreatorPage'

import PaymentPage from '@pages/PaymentPage.vue'
import SuccessPage from '@pages/SuccessPage.vue'

import ServicePage from '@pages/ServicePage.vue'
import ServiceDonationPage from '@pages/Services/ServiceDonationPage.vue'
import ServicePartnerPage from '@pages/Services/ServicePartnerPage.vue'
// import ServiceProductsPage from '@pages/Services/ServiceProductsPage.vue'

export default [
  {
    path: '/:app([cr]{1})',
    props: true,
    component: AppEntry,
    redirect: { name: 'creator-page' },
    children: [
      {
        path: ':slug',
        props: true,
        name: 'creator-page',
        component: CreatorPage,
        redirect: { name: 'service-donation-page' },
        children: [
          {
            path: '',
            component: ServicePage,
            children: [
              {
                path: '/:app([cr]{1})/:slug',
                name: 'service-donation-page',
                component: ServiceDonationPage,
              },
              {
                path: '/:app([cr]{1})/:slug/p/:partnerCode',
                props: true,
                name: 'service-partner-page',
                component: ServicePartnerPage,
              },
              // {
              //   path: '/:app([cr]{1})/:slug/products',
              //   name: 'service-products-page',
              //   component: ServiceProductsPage,
              // },
              // {
              //   path: '/:app([cr]{1})/:slug/products/:productId',
              //   props: true,
              //   name: 'service-products-product-page',
              //   component: ServiceProductsPage,
              // },
            ]
          },
          {
            path: 'payment',
            name: 'creator-payment-page',
            component: PaymentPage,
            props: true,
            meta: {
              hideDescription: true,
            },
          },
          {
            path: 'success',
            name: 'payment-success-page',
            component: SuccessPage,
            props: true,
            meta: {
              hideDescription: true,
            },
          },
          {
            name: 'donations-404',
            path: '*',
            redirect: { name: 'creator-page' },
          },
        ],
      },
    ],
  },
]
