import api2 from '@src/services/api2'

export const state = {
  partners: {},
}

export const getters = {
  getPartnersList: state => Object.values(state.partners),

  getPartner: state => code => state.partners[code],
}

export const mutations = {
  SET_PARTNERS(state, partners) {
    state.partners = _.keyBy(partners, 'code')
  },
}

export const actions = {
  fetchCreatorPartners({ commit, dispatch, rootGetters }, slug) {
    return api2.get('/user/partners/payment-page', {
      params: {
        code: slug,
      },
    }).then(({ data }) => {
      return commit('SET_PARTNERS', data.map(partner => ({
        id: partner.id,
        widgetUrl: partner.payment_page_widget_url,
        code: partner.code,
        title: partner.title,
      })))
    })
  },
}
