<style scoped lang="scss">
  .page-layout {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    ::v-deep {
      hr {
        border-style: solid;
        margin-block-start: 20px;
        margin-block-end: 20px;
        border-width: 0;
        border-top: 1px solid $line-color;
      }

      .content-block + .content-block {
        margin-top: 20px;
      }

      .content-block {
        background-color: white;
        border-radius: var(--block-border-radius);
        box-shadow: 0 5px 20px 0 rgba(black, .05);

        .content-block-body {
          padding: 20px 30px 15px 30px;

          @include media(max, sm) {
            padding: 20px 15px 15px 15px;
          }
        }

        .content-block-controls + .content-block-body {
          padding-top: 0;
        }

        @include media(max, sm) {
          border-radius: 0;
        }
      }
    }
  }
</style>

<template>
  <div class="page-layout">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'PageLayout',
}
</script>
