import { getInRange } from '@utils/utils'

export const getVolume = (volume = 100, masterVolume = 100) => {
  let finalVolume = Number(volume)

  finalVolume = _.isNumber(finalVolume) ? getInRange(finalVolume, [0, 100]) : 0

  return _.round(finalVolume * (masterVolume / 100) / 100, 2)
}

export const stopAudio = audio => {
  if (audio) {
    audio.pause()
    audio.currentTime = 0
  }
}

export const playAudio = (audio, volume = null, masterVolume = 100) => {
  if (audio) {
    audio.pause()
    audio.currentTime = 0
    if (volume !== null) {
      audio.volume = getVolume(volume, masterVolume)
    }
    audio.play()
  }
}

export const createAudio = (source, volume = 100, masterVolume = 100) => {
  if (source) {
    const audio = new Audio(source)
    audio.volume = getVolume(volume, masterVolume)
    return audio
  }
  return null
}

// export class AudioManager {
//   constructor(masterVolume) {
//     this.setMasterVolume(masterVolume)
//     this.audio = {}
//   }
//
//   setMasterVolume(masterVolume) {
//     this.masterVolume = getInRange(masterVolume, [0, 100])
//   }
//
//   _getMasterVolumeK() {
//     return _.round(this.masterVolume / 100, 2)
//   }
//
//   _getVolumeK(volume) {
//     return _.round(((getInRange(volume, [0, 100]) / 100) * this._getMasterVolumeK / 100), 2)
//   }
//
//   createAudio(source) {
//     this.audio[source] = new Audio(source)
//
//     return this.audio[source]
//   }
//
//   stopAudio(source) {
//     const audio = this.audio[source]
//
//     if (audio) {
//       audio.pause()
//       audio.currentTime = 0
//     }
//   }
//
//   playAudio(source, stop = false, volume = 100) {
//     const audio = this.audio[source] ?? this.createAudio(source)
//
//     if (audio) {
//       audio.volume = this._getVolumeK(volume)
//
//       if (stop) {
//         this.stopAudio(stopAudio)
//       }
//     }
//   }
// }
