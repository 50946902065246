<style lang="scss" scoped>
  .progress-circle {
    border-radius: 100%;
    position: relative;

    svg {
      transform: rotate(-90deg);
      transform-origin: center center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      circle {
        stroke-dashoffset: 0;
        transition: stroke-dasharray 1s linear, stroke-width .3s;
        fill: none;

        &.filler {
          stroke: url(#_theme_gradient);
          stroke-linecap: round;
        }

        &.background {
          stroke: rgba(black, .05);
        }
      }
    }
  }
</style>

<template>
  <div class="progress-circle" :style="wrapperStyles">
    <svg id="svg" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <circle
        :r="radius"
        :cx="circleCoords.cx"
        :cy="circleCoords.cy"
        class="background"
        :style="backgroundStyles"/>
      <circle
        :r="radius"
        :cx="circleCoords.cx"
        :cy="circleCoords.cy"
        class="filler"
        :style="fillerStyles"/>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'ProgressCircle',
  props: {
    percent: {
      type: Number,
      required: true,
    },
    strokeWidth: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    radius() {
      return (this.size - this.strokeWidth) / 2
    },

    diameter() {
      return this.radius * 2
    },

    circleCoords() {
      const center = this.radius + this.strokeWidth / 2

      return {
        cy: center,
        cx: center,
      }
    },

    wrapperStyles() {
      const size = this.diameter + this.strokeWidth

      return {
        width: `${size}px`,
        height: `${size}px`,
      }
    },

    circleStyles() {
      return {
        strokeWidth: this.strokeWidth,
      }
    },

    backgroundStyles() {
      return {
        ...this.circleStyles,
      }
    },

    fillerStyles() {
      const draw = this.percent * (this.diameter * Math.PI) / 100

      const styles = {
        strokeDasharray: `${draw}, 999`,
      }

      if (!draw) {
        styles.stroke = 'none'
      }

      return {
        ...this.circleStyles,
        ...styles,
      }
    },
  },
}
</script>
