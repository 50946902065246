import { leadingZero } from '@utils/utils'

import AudioRecorder from 'audio-recorder-polyfill'

window.MediaRecorder = AudioRecorder

const FFT_SIZE = 64

let mediaRecorder,
  audioProcessor,
  audioAnalyser

const initRecorder = (stream) => {
  const AudioContext = window.AudioContext // Default
    || window.webkitAudioContext // Safari and old versions of Chrome
    || false

  if (!AudioContext) {
    throw new Error('No audio context found')
  }

  const audioCtx = new AudioContext
  const streamSource = audioCtx.createMediaStreamSource(stream)
  audioAnalyser = audioCtx.createAnalyser()
  audioProcessor = audioCtx.createScriptProcessor(2048, 1, 1)
  streamSource.connect(audioAnalyser)
  streamSource.connect(audioProcessor)
  audioAnalyser.fftSize = FFT_SIZE
  audioProcessor.connect(audioCtx.destination)

  mediaRecorder = new MediaRecorder(stream)
}

let recordUpdateInterval

const defaultPermissionState = {
  requesting: false,
  granted: false,
  rejected: false,
}
const defaultErrorState = {
  deviceNotFound: false,
  noBrowserSupport: false,
}

export const state = {
  maxAudioDuration: 120,

  permission: defaultPermissionState,
  error: defaultErrorState,

  recorderState: {
    recording: false,
    saving: false,
    startedAt: null,
  },

  audioChunks: [],
  audioBlob: null,
  audioFrequencyData: [],

  currentTime: null,
}

export const getters = {
  audioUrl: state => state.audioBlob ? URL.createObjectURL(state.audioBlob) : null,

  recordDuration: state => Math.floor(state.currentTime - state.recorderState.startedAt),

  parsedMaxDuration: state => dates.getTimeLeft(dates.timestamp(), dates.timestamp() + state.maxAudioDuration),
  recordDurationText: (state, getters) => {
    const {
      minutes: minutesLeft,
      seconds: secondsLeft,
    } = dates.getTimeLeft(state.recorderState.startedAt, state.currentTime)
    const {
      minutes: minutesMax,
      seconds: secondsMax,
    } = getters.parsedMaxDuration

    return [
      [minutesLeft, secondsLeft],
      [minutesMax, secondsMax],
    ].map(row => row.map(leadingZero).join(':')).join(' / ')
  },

  recorderBusy: state => state.recorderState.recording || state.recorderState.saving,
  recorderActive: (state, getters) => state.permission.granted && (!getters.audioUrl || getters.recorderBusy),

  playerActive: (state, getters) => state.permission.granted && !getters.recorderBusy && getters.audioUrl,

  loading: state => state.permission.requesting || state.recorderState.saving,
  locked: state => !state.permission.requesting && !state.permission.granted && !state.error.deviceNotFound,
}

export const mutations = {
  SET_PERMISSION_STATE(state, data) {
    state.permission = {
      ...state.permission,
      ...data,
    }
  },
  SET_ERROR_STATE(state, data) {
    state.error = {
      ...state.error,
      ...data,
    }
  },

  UPDATE_AUDIO_FREQUENCY_DATA(state) {
    const data = new Float32Array(audioAnalyser.frequencyBinCount)
    audioAnalyser.getFloatFrequencyData(data)
    // console.log(data)
    state.audioFrequencyData = data
  },
  RESET_AUDIO_DATA(state) {
    state.audioChunks = []
    state.audioBlob = null
    state.currentTime = null

    state.recorderState = {
      recording: false,
      saving: false,
      startedAt: null,
    }
  },
  ADD_AUDIO_CHUNK(state, chunk) {
    state.audioChunks.push(chunk)
  },
  PROCESS_BLOB(state) {
    state.audioBlob = new Blob(state.audioChunks, { 'type': 'audio/wav; codecs=0' })
  },

  SET_RECORDER_STATE(state, data) {
    state.recorderState = {
      ...state.recorderState,
      ...data,
    }
  },

  UPDATE_CURRENT_TIME(state) {
    state.currentTime = state.currentTime + 1
  },

  SET_RECORDER_SETTINGS(state, { maxAudioDuration }) {
    state.maxAudioDuration = maxAudioDuration
  },
}

export const actions = {
  recorderStop() {
    mediaRecorder.stop()
  },
  recorderStart() {
    mediaRecorder.start()
  },
  updateRecorderTime({
    state,
    commit,
    getters,
    dispatch,
  }) {
    commit('UPDATE_CURRENT_TIME')
    if (getters.recordDuration >= state.maxAudioDuration) {
      dispatch('recorderStop')
    }
  },
  onRecorderStart({
    state,
    commit,
    dispatch,
  }) {
    clearInterval(recordUpdateInterval)
    commit('RESET_AUDIO_DATA')
    commit('UPDATE_CURRENT_TIME')

    commit('SET_RECORDER_STATE', {
      startedAt: state.currentTime,
      recording: true,
    })
    recordUpdateInterval = setInterval(() => {
      dispatch('updateRecorderTime')
    }, 1000)

    console.info('[Recorder] Recording voice...')
  },
  onRecorderStop({
    commit,
    getters,
  }) {
    clearInterval(recordUpdateInterval)

    commit('SET_RECORDER_STATE', {
      recording: false,
    })

    if (getters.recordDuration) {
      commit('PROCESS_BLOB')
    } else {
      commit('RESET_AUDIO_DATA')
    }

    console.info('[Recorder] Recording stopped')
  },

  checkPermission({ commit }) {
    if (!navigator.permissions) {
      return false
    }

    return navigator.permissions
      .query({ name: 'microphone' })
      .then(({ state }) => {
        if (state === 'granted') {
          console.info('[Recorder] Permission check: granted')
          commit('SET_PERMISSION_STATE', { granted: true })
        }
      })
  },
  renewPermission({
    commit,
    dispatch,
  }) {
    commit('SET_PERMISSION_STATE', { requesting: true })
    setTimeout(() => dispatch('requestPermission'), 1000)
  },
  requestPermission({ commit }) {
    commit('SET_PERMISSION_STATE', { requesting: true })
    commit('SET_ERROR_STATE', defaultErrorState)

    return navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        commit('SET_PERMISSION_STATE', { granted: true })
        console.info('[Recorder] Permission granted')
      })
      .catch(err => {
        console.warn(`[Recorder] Permission rejected: ${err.name}`)

        switch (err.name) {
          case 'NotFoundError': {
            commit('SET_ERROR_STATE', { deviceNotFound: true })
            break
          }
          case 'NotAllowedError': {
            commit('SET_PERMISSION_STATE', { rejected: true })
            break
          }
          case 'ReferenceError': {
            commit('SET_ERROR_STATE', { noBrowserSupport: true })
            break
          }
          default: {
            console.error(`[Recorder] Message: ${err.message}`)
          }
        }
      })
      .finally(() => {
        commit('SET_PERMISSION_STATE', { requesting: false })
      })
  },
  initRecorder({
    commit,
    dispatch,
  }) {
    return navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        if (!mediaRecorder) {
          initRecorder(stream)

          const updateFrequencyData = () => commit('UPDATE_AUDIO_FREQUENCY_DATA')

          mediaRecorder.addEventListener('start', () => {
            audioProcessor.addEventListener('audioprocess', updateFrequencyData)
            dispatch('onRecorderStart')
          })
          mediaRecorder.addEventListener('dataavailable', ({ data }) => {
            commit('ADD_AUDIO_CHUNK', data)
          })
          mediaRecorder.addEventListener('stop', () => {
            audioProcessor.removeEventListener('audioprocess', updateFrequencyData)
            dispatch('onRecorderStop')
          })
        }
      })
  },
}
