<style scoped lang="scss">
  .boosty-banner {
    background: #FEFCF8;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 110px;
    @include padding-y(20px);
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    @include media(max, lg) {
      padding-right: 50px;
    }

    @include media(max, md) {
      padding-left: 40px;
      padding-right: 25px;
    }

    @include media(max, sm) {
      border-radius: 0;
    }

    .banner-image {
      position: absolute;

      &.banner-frame-left {
        left: 0;
        bottom: 0;
      }

      &.banner-frame-right {
        right: 0;
        top: 0;
      }

      &.banner-rocket-man {
        width: 127px;
        height: 122px;
        right: 10px;
        top: 8px;

        @include media(max, lg) {
          display: none;
        }
      }

      &.banner-star-1 {
        right: 191px;
        top: 14px;

        @include media(max, lg) {
          right: 107px;
        }
      }

      &.banner-star-2 {
        right: 132px;
        top: 87px;
        height: 17px;
        width: 17px;

        @include media(max, lg) {
          right: 11px;
        }
      }
    }

    .banner-content {
      display: flex;
      align-items: center;

      @include media(max, lg) {
        flex-direction: column;
        align-items: flex-start;
      }

      .banner-content-logo {
        width: 135px;
        height: 37px;
      }

      .banner-content-right {
        margin-left: 20px;

        @include media(max, lg) {
          margin-left: 0;
          margin-top: 20px;
        }

        .banner-content-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #191919;
        }

        .banner-content-text {
          margin-top: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.5px;
          color: #191919;
        }
      }
    }
  }
</style>

<template>
  <a :href="creatorBoostyLink" target="_blank" v-if="creatorBoostyLink" class="boosty-banner">
    <img class="banner-image banner-frame-left" :src="$asAppAsset('/images/boosty/banner-frame-left.png')"/>
    <img class="banner-image banner-frame-right" :src="$asAppAsset('/images/boosty/banner-frame-right.png')"/>

    <img class="banner-image banner-rocket-man" :src="$asAppAsset('/images/boosty/rocket-man.svg')"/>
    <img class="banner-image banner-star-1" :src="$asAppAsset('/images/boosty/star.svg')"/>
    <img class="banner-image banner-star-2" :src="$asAppAsset('/images/boosty/star.svg')"/>

    <div class="banner-content">
      <div class="banner-content-left">
        <img class="banner-content-logo" :src="$asAppAsset('/images/boosty/logo.svg')"/>
      </div>
      <div class="banner-content-right">
        <div class="banner-content-title">
          {{ $t('boosty.banner.title') }}
        </div>
        <div class="banner-content-text">
          {{ $t('boosty.banner.text', { username: creatorInfo.name }) }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>

export default {
  name: 'BoostyBanner',
  computed: {
    ...mapState('creator', ['creatorInfo']),

    creatorBoostyLink() {
      const { boosty } = this.creatorInfo

      if (!boosty) {
        return null
      }

      return `https://boosty.to/${boosty.blog_url}?from=${this.creatorInfo.code}_da_successpage`
    },
  },
}
</script>
