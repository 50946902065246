import storage from '@utils/storage'
import cookies from '@utils/cookies'

const debug = storage.getItem('da.donation.debug.tmr')

window._tmr = window._tmr || []

window._tmr.push({
  type: 'onready',
  callback: function () {
    const tmrLvid = _tmr.getClientID()
    cookies.setItem('my_tracker_user_id', tmrLvid, Infinity, '/')
  },
})

let tmrGlobalLabel

export default {
  install: Vue => {
    Vue.tmr = {
      setGlobalLabel(label) {
        tmrGlobalLabel = label
      },
      send(type = 'event', {
        category = undefined,
        action = undefined,
        label = undefined,
      } = {}) {
        const payload = {
          id: window._tmr_da_tracker_id,
          type,
          category,
          action,
          label: label ?? tmrGlobalLabel,
        }

        window._tmr.push({
          event: payload,
        })

        if (debug) {
          console.log('[TMR EVENT]', payload)
        }
      },
      event(data) {
        this.send('sendEvent', data)
      },
      goal(goal, params) {
        const payload = {
          id: window._tmr_da_tracker_id,
          type: 'reachGoal',
          goal,
          params: {
            ...params,
            newPage: true,
          },
          label: tmrGlobalLabel,
        }

        window._tmr.push(payload)

        if (debug) {
          console.log('[TMR GOAL]', payload)
        }
      },
    }
    Vue.prototype.$tmr = Vue.tmr
  },
}
