<style scoped lang="scss">
  .widget-bft {
    .action-list {
      .action-category {
        .action-category-title {
          font-weight: 600;
          font-size: 14px;
          text-transform: uppercase;
        }

        .action-category-list {
          margin-top: 10px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 10px;
          column-gap: 16px;

          @include media(max, md) {
            grid-template-columns: 1fr;
          }
        }
      }

      .action-category + .action-category {
        margin-top: 20px;
      }
    }

    .bft-stub {
      text-align: center;
      padding: 160px 0;

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #191919;
        margin-bottom: 15px;
      }

      a {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .game-stub {
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 14px 14px 14px 18px;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    font-size: 14px;
    display: inline-flex;
    align-items: center;

    svg {
      font-size: 20px;
      margin-right: 10px;
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      background: #F57D07;
    }
  }
</style>

<template>
  <div class="widget-bft">
    <div class="game-stub" v-if="!gameStarted">
      <icon name="info-circle" gradient system/>
      <span>{{ $t('widgets.bft.gameUnavailable') }}</span>
    </div>
    <div class="action-list" v-else>
      <div
        class="action-category"
        v-for="category in getCategoriesList"
        :key="category.id">
        <div class="action-category-title">{{ category.title }}</div>
        <div class="action-category-list">
          <action-select
            v-for="action in category.actions"
            :key="action.id"
            :id="action.id"/>
        </div>
      </div>
      <div v-if="missingAmounts.bft">
        <amount-link
          :template="$t('widgets.media.mediaAmountTemplate')"
          :amount="missingAmounts.bft" additive
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActionSelect from '../Bft/ActionSelect'

export default {
  name: 'WidgetBft',
  components: { ActionSelect },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('widgets/bft', ['getCategoriesList', 'gameStarted']),
    ...mapGetters('donation', ['missingAmounts']),
  },
}
</script>
