import chroma from 'chroma-js'
import { useCssVar } from '@vueuse/core'
import { i18n } from '@src/localization/config'
import cookies from '@utils/cookies'

const calculateColors = (color) => {
  const newColor = chroma(color)
  const hue = newColor.get('hsl.h')
  const linkColor = newColor.set('hsl.h', hue - 2)
  const gradientFromColor = newColor
  const gradientToColor = newColor.set('hsl.h', hue + 10)

  return {
    '--theme-color': newColor.hex(),
    '--theme-link-color': linkColor.hex(),
    '--theme-color-rgb': newColor.rgb(),
    '--theme-gradient-color-from': gradientFromColor.hex(),
    '--theme-gradient-color-to': gradientToColor.hex(),
    '--theme-gradient-hover-color-from': gradientFromColor.brighten(.15).hex(),
    '--theme-gradient-hover-color-to': gradientToColor.brighten(.15).hex(),
  }
}

export const state = {
  // Application
  appWidth: window.innerWidth,
  scrollTop: window.pageYOffset || document.documentElement.scrollTop,

  themeColor: null,
  themeColors: {},
  themeTextColor: null,
  themeBackgroundColor: null,

  cookiesAcceptable: false,
}

export const getters = {
  getField,

  // App size
  appSize: (state) => {
    const sizes = ['sm', 'md', 'lg']

    const containers = sizes.reduce((acc, size) => {
      const sizeWidth = useCssVar(`--grid-${size}-breakpoint`).value ?? '0'

      acc[size] = sizeWidth.replace(/[^\d.-]/g, '')

      return acc
    }, {})

    let appSize = 'lg'

    sizes.reverse().forEach(size => {
      if (state.appWidth < containers[size]) {
        appSize = size
      }
    })

    return appSize
  },
  appIsSM: (state, getters) => getters.appSize === 'sm',
  appIsMD: (state, getters) => getters.appSize === 'md',
  appIsLG: (state, getters) => getters.appSize === 'lg',
  appIsXL: (state, getters) => getters.appSize === 'xl',
}

export const mutations = {
  updateField,

  SET_APP_WIDTH(state) {
    state.appWidth = window.innerWidth
  },

  SET_SCROLL_TOP(state) {
    state.scrollTop = window.pageYOffset || document.documentElement.scrollTop
  },

  SET_THEME_COLORS(state, { themeColor, themeTextColor }) {
    const colors = calculateColors(themeColor)

    state.themeColor = themeColor
    state.themeColors = colors

    Object.keys(colors).forEach(key => {
      document.documentElement.style.setProperty(key, colors[key])
    })

    state.themeTextColor = themeTextColor

    document.documentElement.style.setProperty('--theme-text-color', themeTextColor)
  },

  SET_COOKIES_ACCEPTABLE(state, value) {
    state.cookiesAcceptable = value
  },
}

export const actions = {
  init({ commit, dispatch }) {
    const debouncedWidthUpdate = () => {
      commit('SET_APP_WIDTH')
    }

    const debouncedScrollUpdate = () => {
      commit('SET_SCROLL_TOP')
    }

    window.addEventListener('resize', debouncedWidthUpdate)
    window.addEventListener('scroll', debouncedScrollUpdate)

    const style = getComputedStyle(document.body)

    const themeColor = style.getPropertyValue('--theme-color')
    const themeTextColor = style.getPropertyValue('--theme-text-color')

    if (themeColor && themeTextColor) {
      dispatch('setThemeColors', {
        themeColor: style.getPropertyValue('--theme-color').trim(),
        themeTextColor: style.getPropertyValue('--theme-text-color').trim(),
      })
    }

    if (i18n.locale !== 'ru_RU' && !cookies.getItem('cookies_warning')) {
      commit('SET_COOKIES_ACCEPTABLE', true)
    }
  },

  acceptCookies({ commit }) {
    cookies.setItem('cookies_warning', 1, Infinity)
    commit('SET_COOKIES_ACCEPTABLE', false)
  },

  setThemeColors({ commit }, { themeColor, themeTextColor }) {
    return commit('SET_THEME_COLORS', { themeColor, themeTextColor })
  },
}
