<style scoped lang="scss">
.service-partner-page {
  position: relative;

  #da-partner-frame {
    width: 100%;
    margin-top: 25px;
    border: none;
    outline: none;
  }

  .loading-container {
    @include position-all(absolute, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    .loader {
      position: absolute;
    }
  }
}
</style>

<template>
  <div v-if="partner" class="service-partner-page">
    <div v-if="loadingState.partner" class="loading-container">
      <loader size="md" system gradient />
    </div>
    <iframe ref="da-partner-frame" id="da-partner-frame" :src="partner.widgetUrl" :style="frameStyles" />
  </div>
</template>

<script>
import { i18n } from '@src/localization/config'
import { INVOICE_TYPE } from '@state/modules/invoice'
import storage from '@utils/storage'

const _da_sdk_debug = !!storage.getItem('da.partner_sdk.debug')
const _da_sdk_log = (...args) => !!_da_sdk_debug && console.log(`[DA Front]`, ...args)

export default {
  name: 'ServicePartnerPage',
  props: {
    partnerCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        partner: true,
      },

      frameHeight: 100,
      frameHeightUpdateInterval: null,
    }
  },
  computed: {
    ...mapFields('invoice', ['payloadValidated', 'partnerExternalData']),
    ...mapFields('donation', ['donationAmount']),
    ...mapFields('currencies', ['currentCurrency']),

    ...mapGetters('partners', ['getPartner']),

    ...mapState('application', ['themeColors']),
    ...mapGetters('currencies', ['payInCurrenciesList']),

    ...mapState('viewer', ['viewerAlias']),

    ...mapState('donation', ['donationIsAnonymous']),

    ...mapGetters('creator', ['getDonationButtonText', 'getCreatorId']),

    partner() {
      return this.getPartner(this.partnerCode)
    },

    frameStyles() {
      return {
        height: `${this.frameHeight}px`,
      }
    },

    viewerAliasPartner() {
      return this.donationIsAnonymous ? this.$t('viewer.alias.anonymouslyShort') : this.viewerAlias
    },
  },
  methods: {
    ...mapActions('payment', ['fetchPaymentMethods']),
    ...mapMutations('invoice', ['SET_INVOICE_TYPE']),

    receivePartnerMessage({ data }) {
      const { event, eventData } = data

      if (event) {
        _da_sdk_log(`Receive`, {
          event,
          eventData,
        })

        switch (event) {
          case 'partnerReady':
            api2
              .get('/currencies/rates', {
                params: {
                  currency: 'RUB',
                },
              })
              .then(({ data }) => {
                this.sendPartnerEvent('init', {
                  locale: i18n.locale,
                  name: this.viewerAlias,
                  currencyCode: this.currentCurrency,
                  currencies: this.payInCurrenciesList,
                  mainCurrencyRates: data,
                  colors: this.themeColors,
                  userId: this.getCreatorId,
                })

                this.loadingState.partner = false
              })

            break
          case 'changeCurrency':
            this.currentCurrency = eventData.currencyCode
            break
          case 'resizeFrame':
            this.frameHeight = Math.min(eventData.height, 1000)
            break
          case 'processPurchase':
            this.loadingState.partner = true

            const { userId, orderId, type, currency, amount, signature } = eventData

            this.donationAmount = _.round(amount, 2)
            this.currentCurrency = currency
            this.payloadValidated = true

            this.partnerExternalData = {
              data: {
                order_id: orderId,
                type,
                amount,
                currency,
                user_id: userId,
              },
              signature,
              client_id: this.partner.id,
            }

            this.SET_INVOICE_TYPE(INVOICE_TYPE.PARTNER)

            this.fetchPaymentMethods().then(() => {
              this.loadingState.partner = false

              this.$router.push({
                name: 'creator-payment-page',
              })
            })

            break
          default:
            break
        }
      }
    },

    sendPartnerEvent(event, eventData) {
      _da_sdk_log(`Send`, {
        event,
        eventData,
      })

      this.$refs['da-partner-frame'].contentWindow.postMessage({ event, eventData }, '*')
    },
  },
  mounted() {
    window.addEventListener('message', this.receivePartnerMessage, false)

    this.frameHeightUpdateInterval = setInterval(() => {
      this.sendPartnerEvent('frameSizeAsked')
    }, 200)

    this.SET_INVOICE_TYPE(INVOICE_TYPE.PARTNER)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receivePartnerMessage, false)
    clearInterval(this.frameHeightUpdateInterval)
  },
  watch: {
    currentCurrency(value) {
      this.sendPartnerEvent('currencyChanged', {
        currencyCode: value,
      })
    },
    viewerAliasPartner(value) {
      this.sendPartnerEvent('nameChanged', {
        name: value,
      })
    },
  },
}
</script>
