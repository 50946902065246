import Vue from 'vue'

export const state = {
  widgetPartyEnabled: false,
  partyProviderId: null,
  partyProviderName: null,

  actions: {},
  selectedActions: {},
}

export const getters = {
  getAction: ({ actions }, getters, rootState) => id => {
    const action = actions[id]

    return {
      ...action,
      amount: action.costs[rootState['currencies'].currentCurrency],
    }
  },

  getActionsList: ({ actions }, getters) =>
    Object.keys(actions).map(actionId => getters.getAction(actionId)),

  getSelectedActionsList: ({ selectedActions }, getters) =>
    Object.keys(selectedActions)
      .filter(actionId => !!selectedActions[actionId])
      .map(actionId => ({
        ...getters.getAction(actionId),
        count: selectedActions[actionId],
      })),

  selectedActionsCost: ({ selectedActions }, getters) =>
    _.round(
      getters.getSelectedActionsList
        .reduce((acc, action) => acc + (action.amount * selectedActions[action.id]), 0),
      2),
}

export const mutations = {
  SET_WIDGET_SETTINGS(state, { widgetPartyEnabled, partyProviderId, partyProviderName }) {
    state.widgetPartyEnabled = widgetPartyEnabled
    state.partyProviderId = partyProviderId
    state.partyProviderName = partyProviderName
  },

  ///
  SET_ACTIONS(state, actions) {
    state.actions = _.keyBy(actions, 'id')
  },

  SET_SELECTED_ACTION_COUNT(state, { id, count }) {
    Vue.set(state.selectedActions, id, count)
  },
}

export const actions = {
  setup({ state, commit, dispatch }, { party }) {
    commit('SET_WIDGET_SETTINGS', {
      widgetPartyEnabled: !!party.is_enabled,
      partyProviderId: party.provider_id,
      partyProviderName: party.provider_name,
    })

    if (state.widgetPartyEnabled) {
      dispatch('fetchCreatorActions')
    }
  },

  fetchCreatorActions({ state, commit }) {
    return api
      .get('/third_party_action', {
        provider_id: state.partyProviderId,
      })
      .then(({ data }) => {
        commit('SET_ACTIONS', data.map(action => ({
          id: action.id,
          name: action.name,
          maxCount: action.max_count,
          providerId: action.provider_id,
          costs: action.costs,
        })))
      })
  },
}

