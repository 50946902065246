<style scoped lang="scss">
  .success-sub-page {
    .message-section {
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .message-image {
        img {
          max-width: 250px;
          max-height: 250px;
        }
      }

      .message-icon {
        display: none;

        .icon {
          height: 60px;
          width: 60px;
        }
      }

      .message-text {
        margin-top: 30px;
        text-align: center;

        .title-text {
          font-weight: 500;
          font-size: 16px;
        }

        .info-text {
          margin-top: 10px;
        }
      }

      .message-links {
        margin-top: 30px;
        display: flex;
        align-items: center;

        .button + .button {
          margin-left: 15px;
        }
      }
    }

    .viewer-section {
      @include padding-y(30px);
      display: flex;
      flex-direction: column;
      align-items: center;

      .viewer-avatar {
        img {
          width: 60px;
          height: 60px;
          border-radius: 100%;
        }
      }

      .viewer-name {
        margin-top: 15px;
      }

      .viewer-text {
        margin-top: 10px;
        text-align: center;
        @include padding-x(50px);
      }

      .viewer-controls {
        margin-top: 30px;
      }
    }

    .registration-section {
      @include padding-y(30px);
      display: flex;
      flex-direction: column;
      align-items: center;

      .registration-icon {
        .icon {
          width: 130px;
          height: 94px;
        }
      }

      .registration-text {
        margin-top: 10px;
        text-align: center;
        @include padding-x(50px);
      }

      .registration-controls {
        margin-top: 30px;
      }
    }

    .content-block-body.mobile-banner {
      padding-bottom: 0;

      .mobile-banner-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .banner-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }

        .banner-text {
          margin-top: 10px;
          text-align: center;
          @include padding-x(40px);
        }

        .banner-controls {
          margin-top: 30px;
        }

        .banner-image {
          margin-top: 25px;
          position: relative;

          img {
            width: 220px;
          }

          .banner-image-text {
            position: absolute;
            top: 67px;
            left: 35px;
            width: 147px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            font-size: 10px;
            line-height: 21px;
            color: #F1F1F1;
          }
        }
      }

      .analytics-pixel {
        width: 0;
        height: 0;
        opacity: 0;
        position: fixed;
        top: -100px;
        left: -100px;
      }
    }

    .boosty-banner {
      @include margin-y(20px);
    }
  }
</style>

<template>
  <div class="success-sub-page">
    <div class="content-block">
      <content-block-controls/>
      <div class="content-block-body">
        <div class="message-section">
          <div class="message-image">
            <img :src="successMessage.image"/>
          </div>
          <div class="message-icon">
            <icon name="check-circle" theme gradient :inline="false"/>
          </div>
          <div class="message-text">
            <div class="title-text">{{ $t('success.thanks') }}</div>
            <div class="info-text">{{ $t('success.donationComplete') }}</div>
          </div>
          <div class="message-links">
            <btn variant="primary-action" icon-left="social-donationalerts" :to="{name: 'creator-page'}">
              {{ $t('success.supportAgain') }}
            </btn>
          </div>
        </div>
      </div>
    </div>

    <boosty-banner/>

    <div class="content-block">
      <div class="content-block-body">
        <div v-if="viewerInfo" class="viewer-section">
          <div class="viewer-avatar">
            <img :src="viewerInfo.avatar"/>
          </div>
          <div class="viewer-name">
            {{ viewerInfo.name }}
          </div>
          <div class="viewer-text">
            {{ $t('success.donationHistory') }}
          </div>
          <div class="viewer-controls">
            <btn :to="'/dashboard/sent-donations'" target="_blank">Перейти на страницу</btn>
          </div>
        </div>
        <div v-else class="registration-section">
          <div class="registration-icon">
            <icon name="card-success" :inline="false"/>
          </div>
          <div class="registration-text">
            {{ $t('success.registrationText') }}
          </div>
          <div class="registration-controls">
            <btn variant="primary" @click="authorize">{{ $t('global.common.registration') }}</btn>
          </div>
        </div>
      </div>
      <content-block-footer/>
    </div>
  </div>
</template>

<script>

import ContentBlockControls from '@components/CreatorPage/ContentBlockControls'
import ContentBlockFooter from '@components/CreatorPage/ContentBlockFooter'
import BoostyBanner from '@components/CreatorPage/BoostyBanner'

export default {
  name: 'SuccessSubPage',
  components: {
    BoostyBanner,
    ContentBlockFooter,
    ContentBlockControls,
  },
  data() {
    return {
      appBannerLink: 'https://trk.mail.ru/c/kh21b7',
      appAnalyticsPixelLink: 'https://trk.mail.ru/i/kh21b7',
    }
  },
  computed: {
    ...mapState('viewer', ['viewerInfo']),
    ...mapGetters('viewer', ['viewerAuthorized']),
    ...mapState('payment', ['paymentMethod']),
    ...mapState('creator', ['creatorInfo']),

    creatorBoostyLink() {
      return true
    },

    successMessage() {
      const randomImage = _.sample(['success-da-love.png', 'success-da-thanks.png'])

      return {
        image: this.$asAppAsset(`/images/${randomImage}`),
      }
    },
  },
  methods: {
    ...mapActions('viewer', ['showLoginModal']),

    authorize() {
      this.showLoginModal()
    },
  },
  mounted() {
    this.$tmr.goal('payment_success', {
      paymentSystem: this.paymentMethod,
    })

    this.$ga.view({
      category: 'DonationPage',
      action: 'success_page_view',
    })
  },
}
</script>
