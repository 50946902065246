<style lang="scss" scoped>
  apple-pay-button {
    --apple-pay-button-width: auto;
    --apple-pay-button-height: 60px;
    --apple-pay-button-border-radius: var(--control-border-radius);
    --apple-pay-button-padding: 12px 30px;
    --apple-pay-button-box-sizing: border-box;
  }
</style>

<template>
  <apple-pay-button
    buttonstyle="black"
    type="donate"
    :locale="locale"
    v-on="$listeners"/>
</template>

<script>
import { i18n } from '@src/localization/config'

export default {
  computed: {
    locale() {
      return i18n.locale.split('_').join('-')
    },
  },
}
</script>
