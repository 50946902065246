<style lang="scss" scoped>
  .message-box {
    .message-controls {
    }

    .message-input {
      //height: 170px;
      margin-top: 15px;

      @include media(max, sm) {
        height: auto;
      }

      .text-message-wrapper {
      }

      .voice-input {
        padding-top: 10px;

      }
    }

    .voice-recorder + .helper-box {
      margin-top: 20px;
    }

    .helper-box + .helper-box {
      margin-top: 8px;
    }

    .synthesis-helper {
      margin-top: 10px;
    }
  }
</style>

<template>
  <div class="message-box">
    <div
      v-if="audioMessageEnabled"
      class="message-controls">
      <base-switch v-model="donationMessageType" :options="donationMessageTypeOptions" small colored/>
    </div>
    <div class="message-input">
      <transition name="fade-up">
        <div v-show="donationMessageType === 'text'" class="text-message-wrapper">
          <text-editor/>
          <template v-if="speechSynthesisEnabled">
            <helper-box variant="helper" icon="speech-synthesis" class="synthesis-helper">
              <template v-if="missingAmounts.speechSynthesis">
                <amount-link
                  :template="$t('message.helpers.speechSynthesisAmountTemplate')"
                  :amount="missingAmounts.speechSynthesis"
                  inline additive/>
              </template>
              <template v-else>
                <template v-if="currentVoice && currentVoice.isSpecial">
                  {{
                    $t('message.helpers.speechSynthesisSpecial', { amount: currentVoicePriceText })
                  }}
                </template>
                <template v-else>
                  {{ $t('message.helpers.speechSynthesisAvailable') }}
                </template>
              </template>
            </helper-box>
          </template>
        </div>
      </transition>
      <transition name="fade-up">
        <div v-if="audioMessageEnabled" v-show="donationMessageType === 'audio'" class="voice-input">
          <voice-recorder/>
          <helper-box v-if="!audioUrl">
            {{ $t('message.helpers.audioMaxDuration') }}
            <span class="text-black">{{ maxAudioDurationText }}</span>
          </helper-box>
          <helper-box v-if="missingAmounts.audioMessage">
            <amount-link
              :template="$t('message.helpers.audioAmountTemplate')"
              :amount="missingAmounts.audioMessage"
              inline additive/>
          </helper-box>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import VoiceRecorder from './VoiceRecorder/VoiceRecorder'
import TextEditor from './TextEditor/TextEditor'

export default {
  name: 'MessageBox',
  components: {
    TextEditor,
    VoiceRecorder,
  },
  inheritAttrs: false,
  computed: {
    ...mapState('message/message', [
      'audioMessageMaxDuration',
      'audioMessageEnabled',
      'speechSynthesisEnabled',
      'speechSynthesisVoice',
    ]),
    ...mapGetters('message/message', [
      'getVoice',
    ]),
    ...mapFields('message/message', [
      'donationMessageType',
    ]),
    ...mapGetters('donation', [
      'missingAmounts',
    ]),
    ...mapGetters('currencies', [
      'getAmountWithCurrency',
    ]),
    ...mapGetters('message/recorder', ['audioUrl']),
    ...mapState('message/recorder', ['recorderState']),

    donationMessageTypeOptions() {
      return [
        {
          value: 'text',
          icon: 'message-bubble',
        },
        {
          value: 'audio',
          icon: 'microphone',
        },
      ].map(option => ({
        ...option,
        label: this.$t(`message.messageTypes.${option.value}`),
      }))
    },

    maxAudioDurationText() {
      return this.$tc('message.maxAudioDuration_plural', this.audioMessageMaxDuration, {
        seconds: this.audioMessageMaxDuration,
      })
    },

    currentVoice() {
      return this.getVoice(this.speechSynthesisVoice)
    },

    currentVoicePriceText() {
      if (this.currentVoice && this.currentVoice.isSpecial) {
        return this.getAmountWithCurrency(this.currentVoice.price)
      }

      return null
    },
  },
  watch: {
    donationMessageType(type) {
      this.$tmr.goal('message_type_selected', {
        type,
      })
    },
  },
}
</script>
