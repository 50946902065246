<style scoped lang="scss">
  .widget-party {
    .action-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;

      @include media(max, md) {
        grid-template-columns: 1fr;
      }
    }

    .party-controls {
      margin-top: 15px;
    }
  }
</style>

<template>
  <div class="widget-party">
    <div class="action-list">
      <action-select
        v-for="action in getActionsList"
        :key="action.id"
        :id="action.id"
      />
    </div>
    <div class="party-controls" v-if="missingAmounts.party">
      <amount-link
        :template="$t('widgets.party.partyAmountTemplate')"
        :amount="missingAmounts.party" additive
      />
    </div>
  </div>
</template>

<script>
import ActionSelect from '../Party/ActionSelect'

export default {
  name: 'WidgetParty',
  components: {
    ActionSelect,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('widgets/party', ['getActionsList']),
    ...mapGetters('donation', ['missingAmounts']),
  },
}
</script>
