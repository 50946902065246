<style lang="scss" scoped>
  .viewer-alias-input {
    position: relative;
    display: flex;
    align-items: center;

    .icon {
      position: absolute;
      left: 15px;
      font-size: 15px;
      pointer-events: none;
    }

    .advanced-input {
      width: 100%;
    }

    .anonymous-switch-wrapper {
      position: absolute;
      right: 15px;
      z-index: 2;
      display: flex;
      align-items: center;

      @include media(max, sm) {
        position: relative;
      }

      .anonymous-switch-label {
        pointer-events: none;
        color: rgba($dark, .5);
      }

      .anonymous-switch {
        margin-left: 10px;
      }
    }

    @include media(max, sm) {
      flex-direction: column;
      align-items: flex-start;

      .anonymous-switch-wrapper {
        margin-top: 10px;
        right: auto;

        .anonymous-switch-label {
          order: 1;
          margin-left: 10px;
        }

        .base-switch {
          order: 0;
          margin-left: 0;
        }
      }
    }
  }
</style>

<template>
  <div class="viewer-alias-input">
    <advanced-input
      icon-left="user"
      v-model="input"
      :placeholder="inputPlaceholder"
      :disabled="donationIsAnonymous"
      @change="onAliasInputChange"
      focused/>
    <div class="anonymous-switch-wrapper" :class="switchClasses">
      <div class="anonymous-switch-label">
        {{ anonymousSwitchText }}
      </div>
      <base-switch v-model="donationIsAnonymous" small class="anonymous-switch"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ViewerAliasInput',
  computed: {
    ...mapFields('viewer', ['viewerAlias']),
    ...mapFields('donation', ['donationIsAnonymous']),

    input: {
      get() {
        return this.donationIsAnonymous ? '' : this.viewerAlias
      },
      set(value) {
        if (!this.donationIsAnonymous) {
          this.viewerAlias = value
        }
      },
    },

    inputPlaceholder() {
      return this.donationIsAnonymous
        ? this.$t('viewer.alias.anonymouslyShort')
        : this.$t('viewer.alias.placeholder')
    },

    switchClasses() {
      return {
        bordered: this.donationIsAnonymous,
      }
    },

    anonymousSwitchText() {
      return this.$appIsMD
        ? this.$t('viewer.alias.anonymouslyShort')
        : this.$t('viewer.alias.anonymouslyLong')
    },
  },
  methods: {
    onAliasInputChange() {
      this.$tmr.goal('input_username', {
        is_anonymous: this.donationIsAnonymous,
      })
    },
  },
  watch: {
    viewerAlias(value, oldValue) {
      if (value !== oldValue) {
        this.viewerAlias = value.slice(0, 40)
      }
    },
    donationIsAnonymous: 'onAliasInputChange',
  },
}
</script>
