import Vue from 'vue'
import VueRouter from 'vue-router'
import session from '@services/session'

import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ?? { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  await session.onReady()

  if (to.meta.profileRequired) {
    next()
  } else {
    next()
  }
})

export default router
