<style scoped lang="scss">
  .creator-page-header {
    display: flex;
    flex-direction: column;

    .header-wallpaper {
      height: 200px;
      background-position: center;
      background-size: cover;
      border-radius: var(--block-border-radius) var(--block-border-radius) 0 0;

      @include media(max, sm) {
        height: 90px;
        border-radius: 0;
      }
    }
  }
</style>

<template>
  <div class="creator-page-header">
    <div
      v-if="backgroundEnabled"
      class="header-wallpaper"
      :style="headerWallpaperStyles"/>
    <creator-info/>
  </div>
</template>

<script>

import CreatorInfo from '@components/CreatorPage/CreatorInfo/CreatorInfo'

export default {
  name: 'CreatorPageHeader',
  components: { CreatorInfo },
  computed: {
    ...mapState('creator', ['creatorPageSettings']),

    headerWallpaperStyles() {
      return {
        backgroundImage: `url("${this.creatorPageSettings.headerBackgroundImage}")`,
      }
    },

    backgroundEnabled() {
      return !!this.creatorPageSettings.headerBackgroundImage
    },
  },
}
</script>
