<style scoped lang="scss">
  .message-section {

  }
</style>

<template>
  <div class="message-section">
    <message-box/>
  </div>
</template>

<script>

import MessageBox from './MessageBox'

export default {
  name: 'MessageSection',
  components: { MessageBox },
}
</script>
